import React,{memo} from 'react';
import { Modal, Typography, Button, Box } from '@mui/material';
import { DarkJungleGreen,GuardsmanRed,Iron} from '../../styles/Colors';
import styled from '@emotion/styled';

export const StyleButtonClose=styled(Button)`
background-color: ${GuardsmanRed};
color: white;
&:hover {
    background-color: ${GuardsmanRed};
    color: white;
  }
`
export const StyleButtonSubmit=styled(Button)`
background-color: ${Iron};
color: black;
&:hover {
    background-color: ${Iron};
    color: black;
  }
`
const ModalWrapper = ({ heading, width, children, open, onClose, loading, handleSave, button1Text = "Cancel", button2Text = "Save", isbutton = true }) => {

    const handleClose = () => onClose();

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width || 600,
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto',
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" color={DarkJungleGreen} variant="h6" component="h2">
                        {heading || 'Default Heading'}
                    </Typography>
                    <form onSubmit={handleSave}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {children}
                        </Typography>

                        {isbutton && <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <StyleButtonClose onClick={handleClose} sx={{ mr: 2 }}>
                                    {button1Text}
                                </StyleButtonClose>
                                <StyleButtonSubmit disabled={loading} variant="contained" color="primary" type="submit">
                                    {loading ? "Loading..." : button2Text}
                                </StyleButtonSubmit>
                            </Box>
                        </>}
                    </form>

                </Box>
            </Modal>
        </div>
    );
};

export default memo(ModalWrapper);