import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { DustyOrange, GreenHaze, Iron } from '../../styles/Colors';

const BorderLinearProgress = styled(LinearProgress)(({ completed }) => ({
  height: 20,
  borderRadius: 20,
  border: `1px solid ${Iron}`,
  width: '100%',
  padding:"12px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: DustyOrange,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: GreenHaze,
    width: `${completed}%`, 
  },
}));

const ProgressBarContainer = styled(Box)`
  margin: 20px 0;
  flex-grow: 1;
  width: 100%;
  position: relative;
`;

const ProgressText = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff; 
  font-weight: 500;
  text-wrap:nowrap;
  font-size:14px;
`;

export default function ProgressBars({ completedProgress }) {
  return (
    <ProgressBarContainer>
      <BorderLinearProgress 
        variant="determinate" 
        value={completedProgress} 
      />
      <ProgressText>
      Progress Bar {completedProgress?.toFixed()}%
      </ProgressText>
    </ProgressBarContainer>
  );
}
