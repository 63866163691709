import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { errorToast, successToast } from '../../Toast/Toaster';
import Input from '../../Inputs/Input';
import FileInput from '../../Inputs/FileInput';
import { useDispatch } from "react-redux";
import { LinkDocPolicy, LinkPolicyCheckList, addDocument, addPostlist, checklistActions } from '../../../store/checklist-slice';
import mammoth from 'mammoth';

const ImportPolicy = ({ open, onClose, checklistId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ name: "", policyDescription: "" });
    const [inputErrors, setInputErrors] = useState({ name: "", policyDescription: "" });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        try {
            const addPolicyRes = await dispatch(addPostlist(formData));
            const policyId = addPolicyRes.payload.id;

            await dispatch(LinkPolicyCheckList({ checkListId: checklistId, policyId, policyNumber: "12" }));

            const docData = {
                name: `Doc-${formData.name.replace(/\s+/g, "")}`,
                path: `user/document/${formData.name.replace(/\s+/g, "")}/test.pdf`,
                content: ""
            };

            const responseDoc = await dispatch(addDocument(docData));
            const docId = responseDoc.payload.id;

            await dispatch(LinkDocPolicy({ policyId, docId }));
            dispatch(checklistActions.setSelectedLinkedPolicy(policyId));

            successToast("Policy added successfully...");
            modalClose();
        } catch (error) {
            errorToast("Failed to add new Policy, please try again!");
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (fieldName) => (event) => {
        const { value } = event.target;
        setFormData(prevData => ({ ...prevData, [fieldName]: value }));
        setInputErrors(prevErrors => ({ ...prevErrors, [fieldName]: "" }));
    };

    const handleFileChange = async (file) => {
        if (!file) return;

        if (!file.name.endsWith('.docx')) {
            setInputErrors(prevErrors => ({ ...prevErrors, policyDescription: 'Please upload a .docx file.' }));
            return;
        }

        try {
            setFormData(prevData => ({ ...prevData, name: file.name }));
            const arrayBuffer = await file.arrayBuffer();
            const result = await mammoth.convertToHtml({ arrayBuffer });
            setFormData(prevData => ({ ...prevData, policyDescription: result.value }));
            setInputErrors(prevErrors => ({ ...prevErrors, policyDescription: "" }));
        } catch (err) {
            console.error('Error processing file:', err);
            setInputErrors(prevErrors => ({ ...prevErrors, policyDescription: 'Error processing file. Please try again.' }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!formData.name.trim()) {
            errors.name = "This field is required";
            isValid = false;
        } else if (formData.name.length > 260) {
            errors.name = `Policy Name must be 260 characters or fewer. Currently ${formData.name.length} characters.`;
            isValid = false;
        }

        if (!formData.policyDescription.trim()) {
            errors.policyDescription = "File upload is required";
            isValid = false;
        }

        setInputErrors(errors);
        return isValid;
    };

    const modalClose = () => {
        setFormData({ name: "", policyDescription: "" });
        setInputErrors({ name: "", policyDescription: "" });
        onClose();
    };

    return (
        <ModalWrapper
            open={open}
            onClose={modalClose}
            loading={loading}
            heading="Import Policy"
            handleSave={handleSubmit}
        >
            <Input
                label="New Policy Name"
                type="text"
                value={formData.name}
                onChange={handleChange("name")}
                error={inputErrors.name}
            />
            <FileInput
                label="Upload .docx file"
                onChange={handleFileChange}
                name="file"
                accept=".docx"
                error={inputErrors.policyDescription}
            />
        </ModalWrapper>
    );
};

export default ImportPolicy;
