import { addOnActions } from "./addon-slice";
import { GetAddOns } from "../services/addOnService";

export const fetchAddOns = () => {
  return async (dispatch) => {
    try {
      const addOnsResponse = await GetAddOns();
      const addOns = addOnsResponse.data;
      dispatch(
        addOnActions.setAddOns({
          addOns: addOns,
        })
      );
    } catch (error) {
      //Handle later
      console.log(error);
    }
  };
};
