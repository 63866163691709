import axios from "axios";

export const postLinkUserGroup = async (groupId,userId) => {
    const options = {
        method: "POST",
        url: `Group/Group/${groupId}/Members/${userId}`,
        baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
    };
    return axios.request(options);
  };
  