// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainNavigation_headerLink__4p\\+8Q {
  text-decoration: none;
}

.MainNavigation_logo__7oxkl {
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/MainNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".headerLink {\n  text-decoration: none;\n}\n\n.logo {\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLink": `MainNavigation_headerLink__4p+8Q`,
	"logo": `MainNavigation_logo__7oxkl`
};
export default ___CSS_LOADER_EXPORT___;
