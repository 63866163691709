import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addChecklistCategory,
  editChecklistCategory,
  fetchChecklistCategories,
} from "../../../store/checklist-slice";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { errorToast, successToast } from "../../Toast/Toaster";
import {ReusableTypeReturn } from "../../../utils/helperUtils";

const AddChecklistCategoryModal = ({ onClose, isEdit, initialData, checklistCategoryId, open }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: "", });
  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({ name: "" });

  useEffect(() => {
    if (isEdit && initialData) {
      setFormData({
        name: initialData.name ?? "",
      });
    } else {
      setFormData({
        name: "",
      });
    }
  }, [isEdit, initialData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);

      const action = ReusableTypeReturn(isEdit, editChecklistCategory, addChecklistCategory);
      const successMessage = ReusableTypeReturn(isEdit,"Checklist category edited successfully!","Checklist category added successfully!");
      const updatedChecklistCategory = isEdit ? { id: checklistCategoryId, data: formData } : formData;
      await dispatch(action(updatedChecklistCategory));
      successToast(successMessage);
      onClose();
      setFormData(prev => ({ ...prev, name: "" }));
      dispatch(fetchChecklistCategories());
    } catch (error) {
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    // Remove error for the specific input field
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    }
    else if (formData.name?.length > 260) {
      errors.name = `Checklist Category Name must be 260 characters or fewer. Currently ${formData.name?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };
  const handleClose = () => {
    if (isEdit && initialData) {
      setFormData({
        name: initialData.name ?? "",
      });
    } else {
      setFormData({
        name: "",
      });
    }
    setInputErrors({ name: "" })
    onClose();
  };

  return (
    <>
      <ModalWrapper
        open={open}
        onClose={handleClose}
        loading={loading}
        heading={ReusableTypeReturn(isEdit,"Edit Checklist Category","Add New Checklist Category")}
        handleSave={handleSubmit}
      >
        <Input
          label={ReusableTypeReturn(isEdit, "Edit Checklist Category Name", "New Checklist Category Name")}
          type="text"
          value={formData.name}
          name="name"
          onChange={handleChange("name")}
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddChecklistCategoryModal;
