import React from 'react'
import ModalWrapper from '../ModalWrapper'

const AreYouSure = ({open,loading,onClose,submitClicked,heading,button2Text="Delete"}) => {
  return (
    <>
      <ModalWrapper
        open={open} onClose={onClose}
        loading={loading}
        heading={heading}
        handleSave={submitClicked}
        button1Text="Cancel"
        button2Text={button2Text}
      >
    
      </ModalWrapper>
    </>
  )
}

export default AreYouSure