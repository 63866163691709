import { Card, CardHeader, CardContent } from "@mui/material";
import React, { memo } from "react";
import styled from "@emotion/styled";

const StyledCard = styled(Card)`
  background-color: #f1f1f1 !important;
  min-height: 100vh !important;
  height: 100%;
  border-radius: 0 !important;
  position: relative; 
  top: 70px; 
`;

const StyledCardHeader = styled(CardHeader)`
  color: black;
  text-align: center;
  border-bottom: 1px solid #d4d7d9;
  padding: 8px !important;
  margin: 0;
  text-transform: uppercase;
  && .MuiCardHeader-title {
    font-size: 14px;
  }
`;

const StyledCardContent = styled(CardContent)`
  text-align: center;
`;

const GridColumn = (props) => {
  const { children, columnTitle } = props;
  const isChecklistCategoryColumn =
    children?.type?.name === "ChecklistCategoryColumn";
  return (
    <div>
      {!isChecklistCategoryColumn && (
        <StyledCard>
          <StyledCardHeader
            title={columnTitle} 
          />
          <StyledCardContent>
            {children}
          </StyledCardContent>
        </StyledCard>
      )}
      {isChecklistCategoryColumn && children}
    </div>
  );
};

export default memo(GridColumn);
