import React, { memo,useState} from 'react';
import {ReusableTypeReturn, formatDate } from "../../utils/helperUtils";
import classes from '../../components/signatureview/SignatureView.module.css';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BadgeIcon from '@mui/icons-material/Badge';
import CustomButton from '../common/CustomButton';
import ViewSignatureEditModal from '../Modals/ViewSignatureEditModal/ViewSignatureEditModal';

const SignatureView = memo(({ policy, type,mainModalClose}) => {
  const [open, setOpen] = useState(false);
  const {name, image, policyDescription, complianceDescription, submittedDate, updatedDate, submitByName } = policy;
  const handleModalClose = () => setOpen(false);
  const editHandler = () => setOpen(true);

  return (
    <>
    <ViewSignatureEditModal 
    open={open} 
    onClose={handleModalClose}
    policy={policy} 
    type={type}
    mainModalClose={mainModalClose}
    />
      
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: type === "policy" ? policyDescription : complianceDescription }} />
            <div className={classes.flex}>
              <div>
                <p className={classes.updated}><BadgeIcon fontSize='small'/><strong>Name:</strong> &nbsp;{submitByName}</p>
                <p className={classes.updated}><EventAvailableIcon fontSize='small'/><strong>Last updated on:</strong> &nbsp;{formatDate(updatedDate)}&nbsp;{formatDate(updatedDate)}</p>
                <p className={classes.updated}><ScheduleIcon fontSize='small'/><strong>Submitted on:</strong> &nbsp;{formatDate(submittedDate)}&nbsp;{formatDate(submittedDate)}</p>
              </div>
              <div>
                <a href={image} download={name} title="click to download">
                  <img src={image} alt={name} className={classes.image} />
                </a>
              </div>
            </div>
          </div>
          <CustomButton onClick={editHandler} content={ReusableTypeReturn(type === "policy","Edit Policy","Edit Compliance")} />
        </div>
      </div>
    </>
  );
});

export default SignatureView;