import axios from "axios";

export const getUserById = async (id) => {
    const options = {
      method: "GET",
      url: `User/UserWithGroupMembers/${id}`,
      baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
    };
    return axios.request(options);
};
