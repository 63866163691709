import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReusableTypeReturn, formatDate } from "../../../utils/helperUtils";
import SignaturePreview from "../../signaturePreview/SignaturePreview";
import { errorToast, successToast } from "../../Toast/Toaster";
import { PutPolicy } from "../../../services/checklistService";
import FullScreenModal from "../FullScreenModal";
import SignatureView from "../../signatureview/SignatureView";
import DatePickerComponent from "../../Inputs/DatePickerComponent";
import dayjs from 'dayjs';
import styled from "@emotion/styled";
import { FreshGreen, GuardsmanRed, Iron } from "../../../styles/Colors";
import { useSelector } from "react-redux";
import TinyMCEEditor from "../../Inputs/TinyMCEEditor";

const BaseButton = styled(Button)`
  color: white;
  margin-right: 16px;
  &:hover {
    color: white;
  }
`;

const StyleButtonClose = styled(BaseButton)`
  background-color: ${GuardsmanRed};
  &:hover {
    background-color: ${GuardsmanRed};
  }
`;

const StyleButtonSave = styled(BaseButton)`
  background-color: ${FreshGreen};
  &:hover {
    background-color: ${FreshGreen};
  }
`;

const StyleButtonSubmit = styled(BaseButton)`
  background-color: ${Iron};
  color: black;
  &:hover {
    background-color: ${Iron};
    color: black;
  }
`;
const ViewPolicyModal = ({ open, onClose, policyData, updateData }) => {
  const sigCanvas = useRef({});
  const [docContent, setDocContent] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [inputErrors, setInputErrors] = useState({ name: "", sign: "", upload: "" })
  const [signatureType, setSignatureType] = useState('sign');
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialUpdatedDate, setInitialUpdatedDate] = useState(undefined);
  const [initialSubmittedDate, setInitialSubmittedDate] = useState(undefined);
  const userResponse = useSelector((state) => state.UIUser.userResponse);
  const userRole = userResponse?.groupMembers ? userResponse?.groupMembers[0].groupName : "user"

  useEffect(() => {
    if (policyData?.id) {
      const { policyDescription, updatedDate, submittedDate } = policyData
      setDocContent(policyDescription)
      setInitialUpdatedDate(updatedDate ? dayjs(formatDate(updatedDate), 'MM/DD/YYYY') : undefined);
      setInitialSubmittedDate(submittedDate ? dayjs(formatDate(submittedDate), 'MM/DD/YYYY') : undefined);
    }
  }, [policyData]);

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!docContent?.trim()) {
      setError('This field is required');
      setLoading(false);
      return;
    }

    const data = {
      "policyDescription": docContent,
      "isDraft": 1,
      "UpdatedDate": initialUpdatedDate?.format("MM/DD/YYYY")
    };

    try {
      await PutPolicy(policyData?.id, data);
      successToast("Policy description saved successfully...")
      modalClose()
      if (updateData) {
        await updateData()
      }
    } catch (err) {
      errorToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const save = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      let imageData = signatureType === "upload" ? selectedFile : sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      if (userRole !== "Administrators") {
        errorToast("Only admin can update the submit date")
      } else {
        const submitData = {
          "policyDescription": docContent,
          "image": imageData,
          "isDraft": 0,
          "SubmittedDate": initialSubmittedDate?.format("MM/DD/YYYY"),
          "submitByName": formData.name,
        };

        await PutPolicy(policyData?.id, submitData);
        successToast("Policy updated successfully...");
        modalClose();
        setShowSignature(false);
        if (updateData) {
          await updateData()
        }
      }

    } catch (err) {
      errorToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  const render = (isSign) => {
    return isSign ? <SignaturePreview
      sigCanvas={sigCanvas}
      error={error}
      clear={clear}
      save={save}
      formData={formData}
      handleChange={handleChange}
      inputErrors={inputErrors}
      handleSignatureChange={handleSignatureChange}
      signatureType={signatureType}
      handleFileChange={handleFileChange}
      submittedDate={initialSubmittedDate}
      setSubmittedDate={setInitialSubmittedDate}
    />
      :
      <>
        <TinyMCEEditor
          DocContent={docContent}
          onChange={handleEditorChange}
          error={error}
        />
        <DatePickerComponent
          selectedDate={initialUpdatedDate}
          setSelectedDate={setInitialUpdatedDate}
          label={"Update on"}
        />
        <Box sx={{ display: 'flex', justifyContent: "space-between", mt: 2 }}>
          <Typography variant="subtitle1" component="div">
            Last updated on:&nbsp;{formatDate(policyData?.updatedDate)}
          </Typography>
          <Box>
            <StyleButtonClose onClick={modalClose} >
              Cancel
            </StyleButtonClose>
            <StyleButtonSave disabled={loading} onClick={handleSave}  >
              {ReusableTypeReturn(loading,"Loading...","Save")}
            </StyleButtonSave>
            <StyleButtonSubmit onClick={handleSubmit} >
              Submit
            </StyleButtonSubmit>
          </Box>
        </Box>
      </>
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!docContent?.trim()) {
      setError('This field is required');
    } else {
      setShowSignature(true);
    }
  };


  const handleEditorChange = useCallback((newContent) => {
    setDocContent(newContent);
    setError('');
  }, []);
  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const handleSignatureChange = (value) => {
    setSignatureType(value);
  };
  const handleFileChange = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
    setInputErrors(prev => ({ ...prev, upload: "" }))
  };
  const clear = useCallback(() => {
    if (sigCanvas.current && sigCanvas.current.clear) {
      sigCanvas.current.clear();
    }
    setError('');
    setFormData({ name: "" });
    setInputErrors({ name: "", sign: "", upload: "" })
    setSelectedFile(null)
    setInitialSubmittedDate(undefined)
  }, []);

  const modalClose = useCallback(() => {
    setDocContent("");
    onClose();
    setError("");
    setFormData({ name: "" });
    setSelectedFile(null)
    setInputErrors({ name: "", sign: "", upload: "" })
    setSignatureType("sign")
    setInitialSubmittedDate(undefined)
    setInitialUpdatedDate(undefined)
  }, [onClose]);

  const validateForm = () => {
    const canvasRef = sigCanvas.current || {};
    const isEmpty = canvasRef.isEmpty ? canvasRef.isEmpty() : true;
    const errors = {};
    let isValid = true;

    if (!formData?.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    }

    if (signatureType === "sign" && isEmpty) {
      errors.sign = "E-signature is required";
      isValid = false;
    }
    if (signatureType === "upload" && !selectedFile) {
      errors.upload = "Image is required";
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };


  const isPolicyDetail = policyData?.policyDescription?.length && policyData?.image?.length
  return (
    <FullScreenModal
      open={open}
      onClose={modalClose}
      heading={`${policyData?.name}`}
    >
      <Box sx={{ margin: "40px" }}>
        {isPolicyDetail ? <SignatureView type="policy" policy={policyData} mainModalClose={modalClose}/> :
          <>
            {render(showSignature)}
          </>
        }
      </Box>
    </FullScreenModal>
  );
};

export default memo(ViewPolicyModal);
