import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetChecklistCategories,
  GetSubChecklists,
  DeleteChecklist,
  PostChecklistCategory,
  PutChecklistCategory,
  DeleteChecklistCategory,
  PostChecklist,
  PutChecklist,
  PostPolicylist,
  GetPolicylist,
  PostCompliance,
  LinkedPolicysList,
  getPolicyLinkedComplianceList,
  AddDocumentAPI,
  DocPolicyLinkingAPI,
  CheckListPolicyLink,
  CompliancePolicyLink,
  DocComplianceLinkingAPI,
  GetCompliancelist,
  CheckListColorPut,
  PolicyColorPut,
  deletePolicyId,
  deleteComplianceId,
  DeleteRetired,
  PostFolder,
  MoveToRetiredCompliance,
  isPolicydraft,
  GetChecklistsNewAPI,
} from "../services/checklistService";
import { getActionList } from "../services/actionCenterList";
import { getNotificationList } from "../services/notificationList";
import { getSearchList } from "../services/searchList";
import { getAdvanceSearchList } from "../services/advanceSearchList";

const initialChecklistState = {
  checklistCategories: [],
  checklists: [],
  showPoliciesColumn: [],
  subChecklists: [],
  selectedDocumentData: [],
  setSelectLinkPolicyList: [],
  selectedChecklistCategoryId: null,
  requestStatus: {
    fetchChecklistCategories: "idle",
    fetchChecklists: "idle",
    fetchSubChecklists: "idle",
    addChecklist: "idle",
    deleteChecklist: "idle",
    addChecklistCategory: "idle",
    editChecklistCategory: "idle",
    deleteChecklistCategory: "idle",
    editChecklist: "idle",
    addChecklist: "idle",
    addPostlist: "idle",
    LinkPolicyCheckList: "idle",
    LinkPolicyCompliance: "idle",
    LinkDocCompliance:"idle",
    addDocument: "idle",
    LinkDocPolicy: "idle",
    addCompliance: "idle",
    LinkedPolicy: "idle",
    getPolicyLists: "idle",
    getComlianceLists:"idle",
    getPolicyLinkedCompliance: "idle",
    getLinkedPolicyList: "idle",
    selectedLinkPolicy: "idle",
    checkListColor:"idle",
    policyListColor:"idle",
    NotificationGet:"idle",
    ActionCenterGet:"idle"
  },
  selectedChecklistId: null,
  selectedSubChecklistId: null,
  documentId: null,
  associatedEntityId: null,
  deletePolicy: null,
  deleteCompliance:null,
  deleteRetired:null,
  addFolder:null,
  editFolder:null,
  moveToRetired:null,
  linkingCompliancFolder:null,
  policyDraft:null,
  SearchGet:null,
  AdvanceSearchGet:null,
};

export const fetchChecklistCategories = createAsyncThunk(
  "fetchChecklistsCategories",
  async () => {
    try {
      const response = await GetChecklistCategories();
      const checklistCategories = response.data.sort(
        (a, b) => a.checklistCategoryIndex - b.checklistCategoryIndex
      );
      return checklistCategories;
    } catch (error) {
      console.error("Error fetching checklist categories:", error);
      throw error;
    }
  }
);

export const fetchChecklists = createAsyncThunk(
  "checklist/fetchChecklists",
  async (id) => {
    try {
      const response = await GetChecklistsNewAPI(id);
      const checklists = response.data.sort(
        (a, b) => a.checklistIndex - b.checklistIndex
      );
      return checklists;
    } catch (error) {
      console.error("Error fetching checklists:", error);
      throw error;
    }
  }
);

export const addChecklist = createAsyncThunk("addChecklist", async (params) => {
  const response = await PostChecklist(params);
  return response;
});

// // Add Linked Policy with checklist
// export const LinkedPolicy = createAsyncThunk(
//   "checklist/postPolicy",
//   async ({ checklistId, policyId, data }) => {
//     try {
//       const response = await PostLinkedPolicylist(
//         `/api/Checklist/Checklist/${checklistId}/Policies/${policyId}`,
//         data
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error posting policy:", error);
//       throw error;
//     }
//   }
// );

// Add Policy List
export const addPostlist = createAsyncThunk("addPostlist", async (params) => {
  const response = await PostPolicylist(params);
  return response;
});

// Link checklist policy
export const LinkPolicyCheckList = createAsyncThunk("LinkPolicyCheckList", async ({ checkListId, policyId,policyNumber}) => {
  return CheckListPolicyLink(checkListId, policyId,policyNumber);
});

// Link compliance policy
export const LinkPolicyCompliance = createAsyncThunk("LinkPolicyCompliance", async ({  policyId, complianceId}) => {
  return CompliancePolicyLink( policyId,complianceId);
});
// Add Document
export const addDocument = createAsyncThunk("addDocument", (params) => {
  return AddDocumentAPI(params);
});
// Checklist Color
export const checkListColor = createAsyncThunk("checkListColor", ({params,color}) => {
  return CheckListColorPut(params,color);
});
//policy Color
export const policyListColor = createAsyncThunk("policyListColor", ({params,color}) => {
  return PolicyColorPut(params,color);
});
// Add Policy Doc Linking

export const LinkDocPolicy = createAsyncThunk("LinkDocPolicy", async ({ policyId, docId}) => {
  return DocPolicyLinkingAPI(policyId, docId);
});
// Add Compliance Doc Linking

export const LinkDocCompliance = createAsyncThunk("LinkDocCompliance", async ({ complianceId, docId}) => {
  return DocComplianceLinkingAPI(complianceId, docId);
});
// Get Policy
export const getPolicyLists = createAsyncThunk("getPolicyLists", async () => {
  return GetPolicylist();
});
// Get Compliance
export const getComlianceLists = createAsyncThunk("getComlianceLists", async () => {
  return GetCompliancelist();
});
//Get Policy Linkedin Compliance List
export const getPolicyLinkedCompliance = createAsyncThunk("getPolicyLinkedCompliance", async (policyId) => {
  return  getPolicyLinkedComplianceList(policyId);
});
// Get LinkedPolicy
export const getLinkedPolicyList = createAsyncThunk(
  "getLinkedPolicyList",
  async (selectedChecklistId) => {
    const response = await LinkedPolicysList(selectedChecklistId);
    return response;
  }
);
// Action center Get 
export const ActionCenterGet = createAsyncThunk("ActionCenterGet", () => {
  return getActionList();
});
// Notification Get 
export const NotificationGet = createAsyncThunk("NotificationGet", () => {
  return getNotificationList();
});

export const SearchGet = createAsyncThunk("SearchGet", () => {
  return getSearchList();
});
export const AdvanceSearchGet = createAsyncThunk("AdvanceSearchGet", () => {
  return getAdvanceSearchList();
});

//policy Draft
export const policyDraft = createAsyncThunk("policyDraft", async (formData) => {
  return  isPolicydraft(formData);
});
export const fetchSubChecklists = createAsyncThunk(
  "checklist/fetchSubChecklists",
  async (checklistId) => {
    try {
      const response = await GetSubChecklists(checklistId);
      const subChecklists = response.data.sort(
        (a, b) => a.checklistIndex - b.checklistIndex
      );
      return subChecklists;
    } catch (error) {
      console.error("Error fetching subchecklists:", error);
      throw error;
    }
  }
);

export const addChecklistCategory = createAsyncThunk(
  "addChecklistCategory",
  async (params) => {
    const response = await PostChecklistCategory(params);
    return response;
  }
);

export const editChecklistCategory = createAsyncThunk(
  "editChecklistCategory",
  async (updatedChecklistCategory) => {
    const response = await PutChecklistCategory(updatedChecklistCategory);
    return response;
  }
);

export const deleteChecklist = createAsyncThunk(
  "deleteChecklist",
  async (id) => {
    return DeleteChecklist(id);
  }
);
// delete retired
export const deleteRetired = createAsyncThunk(
  "deleteRetired",
  async (id) => {
    return DeleteRetired(id);
  }
);

export const deleteChecklistCategory = createAsyncThunk(
  "deleteChecklistCategory",
  async (id) => {
    const response = await DeleteChecklistCategory(id);
    return response;
  }
);
//delete Policy
export const deletePolicy = createAsyncThunk(
  "deletePolicy",
  async (id) => {
    return  deletePolicyId(id);
  }
);

//delete compliance
export const deleteCompliance = createAsyncThunk(
  "deleteCompliance",
  async (id) => {
    return  deleteComplianceId(id);
  }
);
//move to retired
export const moveToRetired = createAsyncThunk(
  "moveToRetired",
  async (id) => {
    return  MoveToRetiredCompliance(id);
  }
);

export const editChecklist = createAsyncThunk(
  "editChecklist",
  async (updatedChecklist) => {
    const response = await PutChecklist(updatedChecklist);
    return response;
  }
);
// Add Compliance
export const addCompliance = createAsyncThunk(
  "addCompliance",
  async () => {
    return PostCompliance();
  }
);
//add folder
export const addFolder = createAsyncThunk(
  "addFolder",
  async (formData) => {
    return PostFolder(formData);
  }
);

const checklistSlice = createSlice({
  name: "checklist",
  initialState: initialChecklistState,
  reducers: {
    setChecklistCategories(state, action) {
      state.checklistCategories = action.payload.checklistCategories;
    },
    setSelectedChecklistCategory(state, action) {
      state.selectedChecklistCategoryId = action.payload;
    },
    setSelectedChecklistId(state, action) {
      state.selectedChecklistId = action.payload;
      // Should reset selectedSubChecklistId other it will be selected
      state.selectedSubChecklistId = null;
    },
    setSelectedSubChecklistId(state, action) {
      state.selectedSubChecklistId = action.payload;
      state.selectedChecklistId = null;
    },
    setChecklists(state, action) {
      state.checklists = action.payload.checklists;
    },
    setSubChecklists: (state, action) => {
      state.subChecklists = action.payload.subChecklists;
    },
    resetAddChecklistCategoryRequestStatus(state) {
      state.requestStatus.addChecklistCategory = "idle";
    },

    resetEditChecklistCategoryRequestStatus(state) {
      state.requestStatus.editChecklistCategory = "idle";
    },
    resetAddChecklistRequestStatus(state) {
      state.requestStatus.addChecklist = "idle";
    },
    resetDeleteChecklistRequestStatus(state) {
      state.requestStatus.deleteChecklist = "idle";
    },
    resetDeleteChecklistCategoryRequestStatus(state) {
      state.requestStatus.deleteChecklistCategory = "idle";
    },

    resetEditChecklistRequestStatus(state) {
      state.requestStatus.editChecklist = "idle";
    },
    setSelectedLinkedPolicy(state, action) {
      state.SelectedLinkedPolicy = action.payload;
    },
    // Document ID
    setSelectedDocumentId(state, action) {
      state.documentId = action.payload.id;
    },
    //policy with document data
    setPolicyDocumentData(state, action) {
      state.associatedEntityId = action.payload.associatedEntityId;
    },
    // Policy List data get complaince
    setSelectPolicyList(state, action) {
      state.SeletedPolicy = action.payload;
      state.SelectedPolicyIndex = action.payload.index;
    },

    setSelectLinkPolicyList(state, action) {
      state.selectedLinkPolicy = action.payload;
    },
  },

  extraReducers: (builder) => {
    //Fetch checklist categories cases
    builder.addCase(fetchChecklistCategories.pending, (state) => {
      state.requestStatus["fetchChecklistCategories"] = "pending";
    });
    builder.addCase(fetchChecklistCategories.fulfilled, (state, action) => {
      state.checklistCategories = action.payload;
      state.requestStatus["fetchChecklistCategories"] = "succeeded";
    });
    builder.addCase(fetchChecklistCategories.rejected, (state) => {
      state.requestStatus["fetchChecklistCategories"] = "failed";
    });
    // Fetch checklists cases
    builder.addCase(fetchChecklists.pending, (state) => {
      state.requestStatus["fetchChecklists"] = "loading";
    });
    builder.addCase(fetchChecklists.fulfilled, (state, action) => {
      state.requestStatus["fetchChecklists"] = "succeeded";
      state.checklists = action.payload;
    });
    builder.addCase(fetchChecklists.rejected, (state, action) => {
      state.requestStatus["fetchChecklists"] = "failed";
      state.error = action.error.message;
    });

    // Fetch sub checklists cases
    builder.addCase(fetchSubChecklists.pending, (state) => {
      state.requestStatus["fetchSubChecklists"] = "loading";
    });
    builder.addCase(fetchSubChecklists.fulfilled, (state, action) => {
      state.requestStatus["fetchSubChecklists"] = "succeeded";
      state.subChecklists = action.payload;
    });
    builder.addCase(fetchSubChecklists.rejected, (state, action) => {
      state.requestStatus["fetchSubChecklists"] = "failed";
      state.error = action.error.message;
    });
    // Add checklist category cases
    builder.addCase(addChecklistCategory.pending, (state) => {
      state.requestStatus.addChecklistCategory = "pending";
    });
    builder.addCase(addChecklistCategory.fulfilled, (state, action) => {
      state.requestStatus.addChecklistCategory = "succeeded";
      state.checklistCategories.push(action.payload);
    });
    builder.addCase(addChecklistCategory.rejected, (state) => {
      state.requestStatus.addChecklistCategory = "failed";
    });
    // Edit checklist category cases
    builder.addCase(editChecklistCategory.pending, (state) => {
      state.requestStatus.editChecklistCategory = "pending";
    });
    builder.addCase(editChecklistCategory.fulfilled, (state, action) => {
      state.requestStatus.editChecklistCategory = "succeeded";
      const editedCategoryIndex = state.checklistCategories.findIndex(
        (category) => category.id === action.payload.id
      );
      if (editedCategoryIndex !== -1) {
        state.checklistCategories[editedCategoryIndex] = action.payload;
      }
    });
    builder.addCase(editChecklistCategory.rejected, (state) => {
      state.requestStatus.editChecklistCategory = "failed";
    });
    // Delete checklist cases
    builder.addCase(deleteChecklist.pending, (state) => {
      state.requestStatus.deleteChecklist = "pending";
      state.status = "loading";
    });
    builder.addCase(deleteChecklist.fulfilled, (state, action) => {
      state.checklists = state.checklists.filter(
        (checklist) => checklist.id !== action.payload
      );
      state.status = "succeeded";
      state.requestStatus.deleteChecklist = "succeeded";
    });
    builder.addCase(deleteChecklist.rejected, (state, action) => {
      state.requestStatus.deleteChecklist = "failed";
      state.status = "failed";
      state.error = action.error.message;
    });
    // Delete checklist category cases
    builder.addCase(deleteChecklistCategory.pending, (state) => {
      state.requestStatus.deleteChecklistCategory = "pending";
      state.status = "loading";
    });
    builder.addCase(deleteChecklistCategory.fulfilled, (state, action) => {
      state.checklistCategories = state.checklistCategories.filter(
        (checklistCategory) => checklistCategory.id !== action.payload
      );
      state.status = "succeeded";
      state.requestStatus.deleteChecklistCategory = "succeeded";
    });
    builder.addCase(deleteChecklistCategory.rejected, (state, action) => {
      state.requestStatus.deleteChecklistCategory = "failed";
      state.status = "failed";
      state.error = action.error.message;
    });

    // Add checklist cases
    builder.addCase(addChecklist.pending, (state) => {
      state.requestStatus.addChecklist = "pending";
    });
    builder.addCase(addChecklist.fulfilled, (state, action) => {
      state.requestStatus.addChecklist = "succeeded";
      state.checklists.push(action.payload);
    });
    builder.addCase(addChecklist.rejected, (state) => {
      state.requestStatus.addChecklist = "failed";
    });
    // Edit checklist cases
    builder.addCase(editChecklist.pending, (state) => {
      state.requestStatus.editChecklist = "pending";
    });
    builder.addCase(editChecklist.fulfilled, (state, action) => {
      state.requestStatus.editChecklist = "succeeded";
      const editedChecklistIndex = state.checklists.findIndex(
        (checklist) => checklist.id === action.payload.id
      );
      if (editedChecklistIndex !== -1) {
        state.checklists[editedChecklistIndex] = action.payload;
      }
    });
    builder.addCase(editChecklist.rejected, (state) => {
      state.requestStatus.editChecklist = "failed";
    });

    // Add compliance
    builder.addCase(addCompliance.pending, (state) => {
      state.requestStatus.addCompliance = "pending";
    });

    builder.addCase(addCompliance.fulfilled, (state, action) => {
      state.requestStatus.addCompliance = "succeeded";
      state.compliance.push(action.payload);
    });
    builder.addCase(addCompliance.rejected, (state) => {
      state.requestStatus.addCompliance = "failed";
    });
  },
});

export const checklistActions = checklistSlice.actions;

export default checklistSlice;
