import React, { useEffect, useState } from 'react'
import FullScreenModal from '../FullScreenModal'
import { GetFolderLinkedCompliances, deleteFolderLinkedComplainceId } from '../../../services/checklistService'
import ReusableList from '../../reusablelist/ReusableList'
import { Box } from '@mui/material'
import useUserRole from '../../../customHooks/useUserRole'
import { errorToast, successToast } from '../../Toast/Toaster'
import AreYouSure from '../Confirm/AreYouSure'
import { useSelector } from "react-redux";
import { ReusableTypeReturn } from '../../../utils/helperUtils'

const ViewFolderCompliancesModal = ({ folderData, onClose,updateListAPI}) => {
    const isAdmin = useUserRole();
    const complianceID = useSelector((state) => state.UISearch.complianceID);
    const isSearch = Object.keys(complianceID.complianceID).length > 0;
    const { SeletedPolicy } = useSelector((state) => state.checklist);
    const SeletedPolicyId = SeletedPolicy?.payload?.id;
    let ID = ReusableTypeReturn(isSearch, complianceID.complianceID.id, SeletedPolicyId)
  
    const [complianceList, setComplianceList] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteCompliance, setDeleteCompliance] = useState({ bool: false, data: {} })
    useEffect(() => {
        let isMounted = true
        const fetchCompliances = async () => {
            try {
                const res = await GetFolderLinkedCompliances(folderData.data.id)
                if (isMounted) {
                    setComplianceList(res.data)
                }
            } catch (error) {
                console.error('Failed to fetch compliances:', error)
            }
        }

        if (folderData.bool) {
            fetchCompliances()
        }

        return () => {
            isMounted = false
        }
    }, [folderData.data.id, folderData.bool, deleteCompliance])

    const handleDeleteComplianceAPI = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (deleteCompliance.data.id) {
            await deleteFolderLinkedComplainceId(deleteCompliance.data.id);
            successToast(`Moved ${deleteCompliance.data.name} to compliance successfully.`);
            setDeleteCompliance({ bool: false, data: {} });
            await updateListAPI(ID)
        }
        else {
            errorToast("Something went wrong...");
        }
        setLoading(false);
    }

    const handleMoveBackToCompliance = async (type, data) => {
        setDeleteCompliance({ bool: true, data })
    };
    const handleDeleteFolderClose = () => {
        setDeleteCompliance({ bool: false, data: {} });
    };
    return (<>

        {deleteCompliance.bool &&
            <AreYouSure
                open={deleteCompliance.bool}
                onClose={handleDeleteFolderClose}
                submitClicked={handleDeleteComplianceAPI}
                heading={`Are you sure you want to move back ${deleteCompliance?.data?.name}?`}
                loading={loading}
            />
        }

        <FullScreenModal
            open={folderData.bool}
            onClose={onClose}
            heading={folderData.data.name}
        >
            <Box sx={{ margin: "40px" }}>
                {complianceList.map((complianceListItem) => (
                    <ReusableList
                        key={complianceListItem.id}
                        data={complianceListItem}
                        menuItems={["Move back to Compliance list"]}
                        isAdmin={isAdmin}
                        onHamburgerClick={handleMoveBackToCompliance}
                    />
                ))}
            </Box>
        </FullScreenModal>
    </>

    )
}

export default ViewFolderCompliancesModal
