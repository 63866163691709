import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { ActionCenterGet } from "../../store/checklist-slice";
import styled from "@emotion/styled";
import { PaleOlive, Seashell } from "../../styles/Colors";
import NoResult from "../NoResult/NoResult";
import { formatDate } from "../../utils/helperUtils";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    width: 350px;
    background-color:${Seashell};
    padding:20px;
    border-radius:0 !important;
  }
`;

const ActionfillContainer = styled(Box)`
font-size: 18px;
color: white;
font-weight: 500;
text-align: center;
background:${PaleOlive};
padding:20px;
`;

const ActionPaperDiv = styled(Paper)`
  box-shadow: none;
  background-color: ${PaleOlive};
  &.MuiPaper-root {
    width: 100%;
    background-color: ${PaleOlive};
    padding: 4px;
    border-radius: 0 !important;
    margin: 20px 0;
  }
`;

function Action() {
  const dispatch = useDispatch();
  const [actionList, setActionList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      fetchActionAPI();
    }
  }, [open]);

  const fetchActionAPI = async () => {
    setLoading(true);
    try {
      const res = await dispatch(ActionCenterGet());
      if (res.payload?.data?.actioncenterList) {
        setActionList(res.payload.data.actioncenterList);
      }
    } catch (error) {
      console.error("Error fetching action list:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <IconButton color="success" onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box>
          <ActionfillContainer >
            Action Center
          </ActionfillContainer>
          {loading && <Box sx={{ display: 'flex', justifyContent: 'center' }} > <CircularProgress /></Box>}
          {!loading && actionList.length === 0 && (
            <NoResult text="No actions available" />
          )}
          {!loading && actionList.length > 0 && (
            <>
              {actionList.map((item, index) => (
                <ActionPaperDiv key={index}>
                  <Stack spacing={2} direction="row" alignItems="center" padding="5px">
                    <Typography variant="caption">{item.description}</Typography>
                  </Stack>
                  <Stack spacing={1} direction="row" alignItems="center" padding="5px">
                  <CalendarMonthIcon fontSize="small"/>  <Typography variant="caption">{formatDate(item.actionDate)}</Typography>
                  </Stack>
                </ActionPaperDiv>
              ))}
            </>
          )}
        </Box>
      </StyledPopover>
    </div>
  );
}

export default Action;
