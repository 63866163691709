import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import AddPolicyModal from "../../Modals/AddPolicyModal/AddPolicyModal";
import PolicyLinkedDropdown from "../../Modals/PolicyLinkedDropdown/PolicyLinkedDropdown";
import ViewPolicyModal from "../../Modals/viewpolicyModal/ViewPolicyModal";
import {
  checklistActions,
  deletePolicy,
  getLinkedPolicyList,
} from "../../../store/checklist-slice";
import { UIStateActions } from "../../../store/ui-state-slice";
import AreYouSure from "../../Modals/Confirm/AreYouSure";
import { PolicySubCrud, policyOptions } from "../../../constant/Constant";
import { errorToast, successToast } from "../../Toast/Toaster";
import ReusableList from "../../reusablelist/ReusableList";
import { PutPolicy } from "../../../services/checklistService";
import IconDropDown from "../../Inputs/IconDropDown";
import { UISearchActions } from "../../../store/seach-state-slice";
import useUserRole from "../../../customHooks/useUserRole";
import {
  GuardsmanRed,
  Iron,
  PaleOlive2,
  ParisGreen,
  Yellow,
} from "../../../styles/Colors";
import styled from "@emotion/styled";
import {
  ReusableTBoolReturn,
  ReusableTypeReturn,
  findChecklistById,
} from "../../../utils/helperUtils";
import PolicyLinkedChecklist from "../../Modals/PolicyLinkedChecklist/PolicyLinkedChecklist";
import ImportPolicy from "../../Modals/ImportPolicy/ImportPolicy";

const StyledPolicyHead = styled(Paper)`
  background-color: ${ParisGreen};
  color: black;
  padding: 16px;
  border: 1px solid ${Iron};
  border-radius: 0px;
  margin-bottom: 10px;
`;

const StyleBox = styled(Box)`
  overflow-x: auto;
  height: 100%;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  scrollbar-width: thin;
`;

const PolicyColumn = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const checklists = useSelector((state) => state.checklist.checklists);
  const selectedChecklistId = useSelector(
    (state) => state.checklist.selectedChecklistId
  );

  const subchecklistData = useSelector(
    (state) => state.checklist.selectedSubChecklistId
  );
  const selectedChecklist = ReusableTypeReturn(
    checklists && selectedChecklistId,
    findChecklistById(checklists, selectedChecklistId),
    null
  );
  const [names, setNames] = useState([]);
  const [openPolicy, setOpenPolicy] = useState({ id: {}, bool: false });
  const [modal, setModal] = useState({ bool: false, type: "", data: {} });
  const [loading, setLoading] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const combinedData = selectedChecklist || subchecklistData;
  const policyID = useSelector((state) => state.UISearch.policyID);
  const isSearchData = Object.keys(policyID).length > 0;
  const policiesList = ReusableTypeReturn(isSearchData, [policyID], names);
  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    policiesLinkData();
  }, [selectedChecklistId, selectedIdx, modal, subchecklistData, openPolicy]);

  const policiesLinkData = useCallback(async () => {
    const res = await dispatch(getLinkedPolicyList(combinedData?.id));
    if (res?.payload) {
      setNames([...res.payload]);
    }
  }, [dispatch, combinedData?.id]);

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (modal.data.id) {
      await dispatch(deletePolicy(modal.data.id));
      responseHandler(modal.data.name);
    } else {
      errorToast("Something went wrong...");
    }
    setLoading(false);
  };

  const responseHandler = (name) => {
    successToast(`${name} deleted successfully`);
    resetModal();
    policiesLinkData();
  };

  const handleColorAPI = async (checklistID) => {
    await PutPolicy(checklistID, { color: "white" });
    policiesLinkData();
  };

  const handleModal = useCallback((type, data) => {
    setModal({ bool: true, type, data });
  }, []);

  const resetModal = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const closeChecklistModal = () => setSelectedIdx(null);
  const closeLinked = () => setSelectedIdx(null);
  const handlePolicyView = (event, policy) => {
    event.stopPropagation();
    setOpenPolicy({ id: policy, bool: true });
  };
  const handlePolicyClose = () => setOpenPolicy({ id: {}, bool: false });

  const handlePolicySelect = (event, payload, index, isCompliance) => {
    event.stopPropagation();
    const action = ReusableTypeReturn(
      isCompliance,
      handleComplianceActions,
      handleRetiredActions
    );
    action(payload, index);
  };

  const handleComplianceActions = (payload, index) => {
    dispatch(UIStateActions.setCompliancesColumnVisibility(true));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
    dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
    dispatch(checklistActions.setSelectPolicyList({ payload, index }));
  };

  const handleRetiredActions = (payload, index) => {
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(true));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    dispatch(checklistActions.setSelectPolicyList({ payload, index }));
    dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
  };
  const handleSelectedTab = (val) => {
    const { name, id, color } = val;
    if (color !== "white") {
      handleColorAPI(id);
    }
    setSelectedTab(name);
  };
  return (
    <>
      <AddPolicyModal
        open={ReusableTBoolReturn(selectedIdx, 0)}
        onClose={closeChecklistModal}
        checklistId={combinedData?.id}
        type="Add"
      />
      <PolicyLinkedDropdown
        open={ReusableTBoolReturn(selectedIdx, 1)}
        onClose={closeLinked}
        checklistId={combinedData?.id}
        policiesList={policiesList}
      />
      <ImportPolicy
        open={ReusableTBoolReturn(selectedIdx, 2)}
        onClose={closeLinked}
        checklistId={combinedData?.id}
      />
      {ReusableTBoolReturn(modal.type, "Edit Policy") && (
        <AddPolicyModal
          open={modal.bool}
          onClose={resetModal}
          checklistId={checklists[0]?.id}
          type="Edit"
          data={modal.data}
        />
      )}
      {ReusableTBoolReturn(modal.type, "Delete Policy") && (
        <AreYouSure
          open={modal.bool}
          onClose={resetModal}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal.data.name}?`}
          loading={loading}
        />
      )}
      {isAdmin && (
        <ViewPolicyModal
          open={openPolicy.bool}
          onClose={handlePolicyClose}
          policyData={openPolicy.id}
        />
      )}
      {ReusableTBoolReturn(modal.type, "Share Policy") && (
        <PolicyLinkedChecklist
          open={modal.bool}
          onClose={resetModal}
          PolicyData={modal.data}
          checklistId={combinedData?.id}
        />
      )}

      <Box>
        {combinedData && (
          <StyledPolicyHead>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography variant="subtitle2">{combinedData.name}</Typography>
            </Stack>
          </StyledPolicyHead>
        )}
        {!isSearchData && isAdmin && (
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              background: "none",
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2}>
              <IconDropDown
                options={policyOptions}
                selectedIdx={selectedIdx}
                setSelectedIdx={setSelectedIdx}
              />
            </Grid>
          </Paper>
        )}
        <StyleBox>
          {policiesList?.map((item, index) => {
            const isDot = !!item.policyDescription || !!item.image;
            const isDotColor = item.policyDescription
              ? ReusableTypeReturn(item.image, Yellow, GuardsmanRed)
              : "";
            return (
              <ReusableList
                key={index}
                data={item}
                isButton
                menuItems={PolicySubCrud}
                onClickCompliance={(e) => {
                  handlePolicySelect(e, item, index, true);
                  handleSelectedTab(item);
                }}
                onClickRetired={(e) => {
                  handlePolicySelect(e, item, index, false);
                  handleSelectedTab(item);
                }}
                onClickContainer={(e) => handlePolicyView(e, item)}
                onHamburgerClick={handleModal}
                isAdmin={isAdmin}
                boxColor={ReusableTypeReturn(
                  item.name === selectedTab,
                  PaleOlive2,
                  item?.color
                )}
                isDot={isDot}
                isDotColor={ReusableTypeReturn(isDot, isDotColor, undefined)}
                isLinked={item.isLinked}
              />
            );
          })}
        </StyleBox>
      </Box>
    </>
  );
};

export default PolicyColumn;
