// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChecklistCategoriesDropdown_dropdownButton__4slWN {
  background-color: white !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.ChecklistCategoriesDropdown_menuItemClass__BBgIb {
  justify-content: space-between !important;
}

.ChecklistCategoriesDropdown_menuItemTitle__mkcmP {
  padding: 5px;
}
.ChecklistCategoriesDropdown_addchecklistcategory__F2iph{
  cursor: pointer;
  text-align: center;
  color:#007099;
  font-weight: 500;
}
.ChecklistCategoriesDropdown_addchecklistcategory__F2iph:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
  color: rgb(0, 78, 107);
}
`, "",{"version":3,"sources":["webpack://./src/components/Checklist/ChecklistCategoriesDropdown/ChecklistCategoriesDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,6BAA6B;EAC7B,8BAA8B;EAC9B,+BAA+B;EAC/B,0BAA0B;EAC1B,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".dropdownButton {\n  background-color: white !important;\n  padding-left: 20px !important;\n  padding-right: 20px !important;\n  text-transform: none !important;\n  font-size: 1rem !important;\n  box-shadow: none !important;\n  border-radius: 0 !important;\n}\n\n.menuItemClass {\n  justify-content: space-between !important;\n}\n\n.menuItemTitle {\n  padding: 5px;\n}\n.addchecklistcategory{\n  cursor: pointer;\n  text-align: center;\n  color:#007099;\n  font-weight: 500;\n}\n.addchecklistcategory:hover{\n  -webkit-text-decoration: none;\n  text-decoration: none;\n  color: rgb(0, 78, 107);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownButton": `ChecklistCategoriesDropdown_dropdownButton__4slWN`,
	"menuItemClass": `ChecklistCategoriesDropdown_menuItemClass__BBgIb`,
	"menuItemTitle": `ChecklistCategoriesDropdown_menuItemTitle__mkcmP`,
	"addchecklistcategory": `ChecklistCategoriesDropdown_addchecklistcategory__F2iph`
};
export default ___CSS_LOADER_EXPORT___;
