import axios from "axios";

export const GetChecklistCategories = async () => {
  const options = {
    method: "GET",
    url: "Checklist/ChecklistCategories",
  };
  return await axios.request(options);
};

export const GetChecklistCategory = async (id) => {
  const options = {
    method: "GET",
    url: `Checklist/ChecklistCategory/${id}`,
  };
  return await axios.request(options);
};

export const PutChecklistCategory = async (updatedChecklistCategory) => {
  const options = {
    method: "PUT",
    url: `Checklist/ChecklistCategory/${updatedChecklistCategory.id}`,
    data: updatedChecklistCategory.data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  var response = await axios.request(options);
  return response?.data;
};

export const PostChecklistCategory = async (checklistCategory) => {
  const options = {
    method: "POST",
    url: "Checklist/ChecklistCategory",
    data: checklistCategory,
  };
  var response = await axios.request(options);
  return response?.data;
};

export const DeleteChecklistCategory = async (id) => {
  const options = {
    method: "DELETE",
    url: `Checklist/ChecklistCategory/${id}`,
  };
  var response = await axios.request(options);
  return response?.data;
};

export const DeleteChecklistItem = async (id) => {
  const options = {
    method: "DELETE",
    url: `Checklist/ChecklistItem/${id}`,
  };
  return await axios.request(options);
};

export const GetChecklists = async (id) => {
  const options = {
    method: "GET",
    url: `Checklist/ChecklistCategory/${id}/Checklists`,
  };
  return await axios.request(options);
};

export const GetChecklistsNewAPI = async (id) => {
  const options = {
    method: "GET",
    url: `Checklist/StructuredChecklists/${id}`,
  };
  return await axios.request(options);
};

export const GetSubChecklists = async (id) => {
  const options = {
    method: "GET",
    url: `Checklist/Checklist/${id}/Checklists`,
  };
  return axios.request(options);
};

// Linked policy get
export const LinkedPolicysList = async (id) => {
  const options = {
    method: "GET",
    url: `Checklist/Checklist/${id}/Policies`,
  };
  var response = await axios.request(options);
  return response?.data;
};

//Get Policy ID
export const GetPolicylist = async (limit = 20, offset = 0) => {
  const options = {
    method: "GET",
    url: `Policy/Policies?limit=${limit}&offset=${offset}`,
  };
  return axios.request(options);
};

//get compliance ID
export const GetCompliancelist = async () => {
  const options = {
    method: "GET",
    url: `Compliance/Compliance/list`,
  };
  return axios.request(options);
};
//Get Policy Compliance List API

export const getPolicyLinkedComplianceList = async (policyId) => {
  const options = {
    method: "GET",
    url: `Policy/Policy/${policyId}/Compliances`,
  };
  return axios.request(options);
};
export const DeleteChecklist = async (id) => {
  const options = {
    method: "DELETE",
    url: `Checklist/Checklist/${id}`,
  };
  return axios.request(options)
};
//delete retired
export const DeleteRetired= async (id) => {
  const options = {
    method: "DELETE",
    url: `Document/Document/${id}`,
  };
  return axios.request(options)
};
export const PostChecklist = async (checklist) => {
  const options = {
    method: "POST",
    url: "Checklist/Checklist",
    data: checklist,
  };
  var response = await axios.request(options);
  return response?.data;
};

// Add Policy API
export const PostPolicylist = async (policylist) => {
  const options = {
    method: "POST",
    url: "Policy/Policy",
    data: policylist,
  };
  var response = await axios.request(options);
  return response?.data;
};
//Link Policy with checklist
export const CheckListPolicyLink = async (checkListId, policyId,policyNumber) => {
  const options = {
    method: "POST",
    url: `Checklist/Checklist/${checkListId}/Policies/${policyId}`,
    data: {policyNumber},
  };
  var response = await axios.request(options);
  return response?.data;
};

//Link Compliance with policy
export const CompliancePolicyLink = async (policyId, complianceId) => {
  const options = {
    method: "POST",
    url: `Policy/Policy/${policyId}/Compliances/${complianceId}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
// Add Document
export const AddDocumentAPI = async (values) => {
  const options = {
    method: "POST",
    url: "Document/Document",
    data: values,
  };
  var response = await axios.request(options);
  return response?.data;
};

// Add Policy Doc Linking
export const DocPolicyLinkingAPI = async (policyId, docId) => {
  const options = {
    method: "POST",
    url: `Policy/Policy/${policyId}/Document/${docId}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
// Add Compliance Doc Linking
export const DocComplianceLinkingAPI = async (complianceId, docId) => {
  const options = {
    method: "POST",
    url: `Compliance/Compliance/${complianceId}/Document/${docId}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
export const PutChecklist = async (updatedChecklist) => {
  const options = {
    method: "PUT",
    url: `Checklist/Checklist/${updatedChecklist.id}`,
    data: updatedChecklist.data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  var response = await axios.request(options);
  return response?.data;
};

// add Linked Policy List
export const PostLinkedPolicylist = async (
  selectedChecklistId,
  selectedPolicyId,
) => {
  const options = {
    method: "POST",
    url: `Checklist/Checklist/${selectedChecklistId}/Policies/${selectedPolicyId}`,
  };
  var response = await axios.request(options);
  return response?.data;
};

// Add Compliance
export const PostCompliance = async (complianceData) => {
  const options = {
    method: "POST",
    url: "Compliance/Compliance",
    data: complianceData,
  };
  const response = await axios.request(options);
  return response?.data;
};
//link compliance to policy
export const PostComplianceLinkPolicy = async (policyId, complianceId) => {
  const options = {
    method: "POST",
    url: `Policy/Policy/${policyId}/Compliances/${complianceId}`,
  };
  const response = await axios.request(options);
  return response?.data;
};

export const LinkedCompliance = async (complianceId) => {
  const options = {
    method: "GET",
    url: `Compliance/compliance/${complianceId}/documents`,
  };
  return axios.request(options);
};
// Document and policy ID linked

export const PostPolicyDocumentlist = async (
  id,
  selectDocument,
  policydocumentdata
) => {
  const option = {
    method: "POST",
    url: `Policy/Policy/${id}/Document/${selectDocument}`,
    data: policydocumentdata,
  };
  var response = await axios.request(option);
  return response?.data;
};

// Policy document get api
export const GetPolicyDocument = async (associatId) => {
  const options = {
    method: "GET",
    url: `Policy/Policy/${associatId}/documents`,
  };
  return axios.request(options);
};

//checklist Color
export const CheckListColorPut = async (checkListId,color) => {
  const options = {
    method: "PUT",
    url: `Checklist/ChecklistColor/${checkListId}?color=${color}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//policy Color
export const PolicyColorPut = async (policyID,color) => {
  const options = {
    method: "PUT",
    url: `Policy/PolicyColor/${policyID}?color=${color}`,
  };
  var response = await axios.request(options);
  return response?.data;
};

//delete policy
export const deletePolicyId = async (policyID) => {
  const options = {
    method: "DELETE",
    url: `Policy/Policy/${policyID}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//delete compliance
export const deleteComplianceId = async (complianceID) => {
  const options = {
    method: "DELETE",
    url: `Compliance/Compliance/${complianceID}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//delete folder
export const deleteFolderId = async (folderID) => {
  const options = {
    method: "DELETE",
    url: `Folder/Folder/${folderID}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//delete folder of compliance
export const deleteFolderLinkedComplainceId = async (complainceId) => {
  const options = {
    method: "DELETE",
    url: `Folder/Compliance/${complainceId}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//move to retired
export const MoveToRetiredCompliance = async (complianceID) => {
  const options = {
    method: "POST",
    url: `Compliance/RetiredCompliance?id=${complianceID}`,
  };
  var response = await axios.request(options);
  return response?.data;
};
//edit policy
export const PutPolicy = async (policyID,data) => {
  const options = {
    method: "PUT",
    url: `Policy/Policy/${policyID}`,
    data:data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  var response = await axios.request(options);
  return response?.data;
};
//edit compliance
export const PutCompliance = async (complianceId,data) => {
  const options = {
    method: "PUT",
    url: `Compliance/Compliance/${complianceId}`,
    data:data,
    headers: {
      "Content-Type": "application/json",
    },
  };
  var response = await axios.request(options);
  return response?.data;
};

//add folder
export const PostFolder = async (folderData) => {
  const options = {
    method: "POST",
    url: "Folder/Folder",
    data: folderData,
  };
  const response = await axios.request(options);
  return response?.data;
};

//edit folder
export const PostEditFolder = async (id, folderData) => {
    try {
    const options = {
      method: "PUT",
      url: `Folder/Folder/${id}`,
      data: {
        Name: folderData.name,
      },
    };
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    throw new Error("Error editing folder:", error);
  }
};

//policy draft
export const isPolicydraft = async (formData) => {
  const options = {
    method: "PUT",
    url: `Policy/UpdatePolicyDescription`,
    data: formData,
  };
  var response = await axios.request(options);
  return response?.data;
};

export const getPolicyById = async (policyId) => {
  const options = {
    method: "GET",
    url: `Policy/Policy/${policyId}`,
  };
  return axios.request(options);
};

export const getComplianceById = async (complianceId) => {
  const options = {
    method: "GET",
    url: `Compliance/Compliance/${complianceId}`,
  };
  return axios.request(options);
};

export const getChecklistById = async (checklistId) => {
  const options = {
    method: "GET",
    url: `Checklist/Checklist/${checklistId}`,
  };
  return axios.request(options);
};


//link folder to policy
export const PostFolderLinkPolicy = async (policyId, folderId) => {
  const options = {
    method: "POST",
    url: `Policy/Policy/${policyId}/Folder/${folderId}`,
  };
  const response = await axios.request(options);
  return response?.data;
};

//link compliance to folder
export const PostComplianceLinkFolder = async (folderId, complianceId) => {
  const options = {
    method: "POST",
    url: `Folder/Folder/${folderId}/Compliance/${complianceId}`,
  };
  const response = await axios.request(options);
  return response?.data;
};


export const GetPolicyLinkedFolders = async (policyId) => {
  const options = {
    method: "GET",
    url: `Policy/Policy/${policyId}/Folder`,
  };
  return axios.request(options);
};

export const GetFolderLinkedCompliances = async (folderId) => {
  const options = {
    method: "GET",
    url: `Folder/Folder/${folderId}/Compliance`,
  };
  return axios.request(options);
};

//get checklist
export const GetChecklistAll = async () => {
  const options = {
    method: "GET",
    url: `Checklist/Checklists`,
  };
  return axios.request(options);
};