import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
  checklistCategoryID: {},
  checklistID: {},
  policyID: {},
  complianceID: {},
};

const UISearchStateSlice = createSlice({
  name: "UISearch",
  initialState: initialUIState,
  reducers: {
    setPolicyID(state, action) {
      state.policyID = action.payload.policyID;
    },
    setComplianceID(state, action) {
      state.complianceID = action.payload;
    },
    setChecklistID(state, action) {
      state.checklistID = action.payload;
    },
    setChecklistCategoryID(state, action) {
      state.checklistCategoryID = action.payload;
    },
  },
});

export const UISearchActions = UISearchStateSlice.actions;

export default UISearchStateSlice;