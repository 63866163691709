import { configureStore } from "@reduxjs/toolkit";
import addOnSlice from "./addon-slice";
import checklistSlice from "./checklist-slice";
import permissionSlice from "./permissions-slice";
import UIStateSlice from "./ui-state-slice";
import UISearchStateSlice from "./seach-state-slice";
import UIUserStateSlice from "./user-state-slice";

const store = configureStore({
  reducer: {
    checklist: checklistSlice.reducer,
    addOn: addOnSlice.reducer,
    permission: permissionSlice.reducer,
    UIState: UIStateSlice.reducer,
    UISearch:UISearchStateSlice.reducer,
    UIUser:UIUserStateSlice.reducer
  },
});

export default store;
