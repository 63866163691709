import React, { useState, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { Link, useNavigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import classes from "./MainNavigation.module.css";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { loginRequest } from "../../authConfig";
import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Notifications from "../Notifications/Notifications";
import Action from "../Action/Action";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { errorToast } from "../Toast/Toaster";
import ViewSearchModal from "../Modals/viewsearchModal/ViewSearchModal";
import ViewAdvanceSearch from "../Modals/ViewAdvanceSearch/ViewAdvanceSearch";
import AutoCompleteSearch from "../SearchBox/AutoCompleteSearch";
import useUserRole from "../../customHooks/useUserRole";
import { HawkesBlue, DarkJungleGreen, Iron } from "../../styles/Colors";
import { UIStateActions } from "../../store/ui-state-slice";
import { UISearchActions } from "../../store/seach-state-slice";
import { checklistActions, SearchGet } from "../../store/checklist-slice";

import styled from "@emotion/styled";

const AppBarStyled = styled(AppBar)`
background-color:${HawkesBlue};
color:${DarkJungleGreen}
height:60px;
`;

const StyledAdvanceSearchButton = styled(Button)`
  background: ${Iron};
  color: black;
  border-radius: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 45px;
  &:hover {
    background: ${Iron};
    color: black;
  }
`;
export default function MainNavigation() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [open, setOpen] = useState(false);

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  const handleopensearch = () => {
    handleFalseGrids();
    setOpen(true);
  };

  const handleclosesearch = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const fetchNotificationAPI = async () => {
    setLoading(true);
    try {
      const res = await dispatch(SearchGet());
      if (res?.payload) {
        setSearchList(res?.payload?.data);
      }
    } catch (error) {
      errorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setSelectedOptions(value);
    if (value) {
      setIsOpenSearchModal(true);
    }
  };

  const handleSearchClose = () => {
    setIsOpenSearchModal(false);
  };

  const handleAdminPanelRedirect = () => {
    navigate("/admin");
    handleUserMenuClose();
  };

  const handleFalseGrids = useCallback(() => {
    dispatch(UISearchActions.setChecklistID({ checklistID: "" }));
    dispatch(UISearchActions.setPolicyID({ policyID: "" }));
    dispatch(UISearchActions.setComplianceID({ complianceID: "" }));
    dispatch(checklistActions.setSelectedChecklistId(null));
    dispatch(checklistActions.setSelectedChecklistCategory(null));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
    dispatch(UIStateActions.setPoliciesColumnVisibility(false));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
  }, [dispatch]);

  const date = new Date();
  const yr = date.getFullYear();
  return (
    <Box sx={{ flexGrow: 1, mx: 1 }}>
      <ViewSearchModal
        isOpen={isOpenSearchModal}
        onClose={handleSearchClose}
        data={selectedOptions}
      />
      <AppBarStyled position="sticky">
        <Toolbar>
          <Link to="/">
            <img
              src="logo.jpg"
              width="100px"
              className={classes.logo}
              alt="logo"
            ></img>
          </Link>
          <Typography
            color={DarkJungleGreen}
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              padding: "0 12px",
              fontWeight: 500,
              fontSize: "12px",
            }}
          >
            CAP Checklist &#64; {yr} |Procedure & Polices, Compliance, Retired
            P&P | Tucson Pathology Associates
          </Typography>

          <AutoCompleteSearch
            handleChange={handleChange}
            searchList={searchList}
            placeholder="Search here..."
            loading={loading}
            selectedOptions={selectedOptions}
            apiCall={fetchNotificationAPI}
          />
          <Box sx={{ padding: "5px", backgroundColor: "white" }}>
            <ViewAdvanceSearch open={open} onClose={handleclosesearch} />
            <StyledAdvanceSearchButton
              variant="contained"
              onClick={handleopensearch}
              endIcon={<ManageSearchIcon />}
              sx={{ marginBottom: "20px", borderRadius: 0 }}
            >
              Advance Search
            </StyledAdvanceSearchButton>
          </Box>
          <Action />
          <Notifications />

          <IconButton
            size="large"
            aria-label="user menu"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleUserMenu}
            color="disabled"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
          >
            <AuthenticatedTemplate>
              {isAdmin && (
                <MenuItem onClick={handleAdminPanelRedirect}>
                  Go to Admin Panel
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <MenuItem onClick={handleLogin}>Log In</MenuItem>
            </UnauthenticatedTemplate>
          </Menu>
        </Toolbar>
      </AppBarStyled>
    </Box>
  );
}
