// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_mainStyles__Vg4Xg {
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".mainStyles {\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainStyles": `Layout_mainStyles__Vg4Xg`
};
export default ___CSS_LOADER_EXPORT___;
