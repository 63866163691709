import React, { useState, useEffect, useCallback, memo } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import styled from "@emotion/styled";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import {
  fetchSubChecklists,
  getLinkedPolicyList,
} from "../store/checklist-slice";
import { UIStateActions } from "../store/ui-state-slice";
import { Iron, PaleOlive, PaleOlive1, PaleOlive2 } from "../styles/Colors";
import { ReusableTBoolReturn, ReusableTypeReturn } from "../utils/helperUtils";

const StyledIcon = styled(ListItemIcon)`
  min-width: unset;
`;

const baseStyles = ({ bgColor }) => ({
  backgroundColor: bgColor,
  border: `1px solid ${Iron}`,
  "&.Mui-selected, &.Mui-focusVisible, &:hover": {
    backgroundColor: bgColor,
  },
});

const createStyledListItemButton = (padding) =>
  styled(ListItemButton)(({ bgColor }) => ({
    ...baseStyles({ bgColor }),
    padding,
  }));

const StyledListItemButton = createStyledListItemButton("6px");
const StyledListItemButtonChild =
  createStyledListItemButton("8px 8px 8px 40px");
const StyledListItemButtonSubChild = createStyledListItemButton(
  "10px 10px 10px 60px"
);

const StyledListItemText = styled(ListItemText)(({ size }) => ({
  ".MuiTypography-root": {
    fontSize: size,
    fontWeight: 500,
  },
}));

const StyledList = styled(List)(({ bgColor }) => ({
  bgcolor: bgColor,
  paddingBottom: "0px",
  margin: "10px",
}));

const ReusableNestedChecklists = memo(function ReusableNestedChecklists({
  ChecklistData,
  onMenuitemClick,
  isAdmin,
  onClickContainer,
  onClickSubContainer,
  setActiveChecklist,
  setActiveSubChecklist,
  setActiveSubSubChecklist,
  activeChecklist,
  activeSubChecklist,
  activeSubSubChecklist,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuLevel, setMenuLevel] = useState("");
  const [subCheckListData, setSubCheckListData] = useState([]);
  const [subSubCheckListData, setSubSubCheckListData] = useState({});
  const [selectedSubCheckList, setSelectedSubCheckList] = useState(null);
  const [subChecklistOpen, setSubChecklistOpen] = useState({});
  const [linkedPolicyLength, setLinkedPolicyLength] = useState(null);
  useEffect(() => {
    fetchSubCheckListData();
    fetchLinkedPoliciesOfChecklist();
  }, [ChecklistData, menuAnchor]);

  useEffect(() => {
    subCheckListData?.forEach((subCheckList) => {
      fetchSubSubCheckListData(subCheckList.id);
    });
  }, [subCheckListData]);

  const fetchSubCheckListData = async () => {
    const res = await dispatch(fetchSubChecklists(ChecklistData?.id));
    setSubCheckListData(res.payload);
  };

  const fetchSubSubCheckListData = async (id) => {
    const res = await dispatch(fetchSubChecklists(id));
    setSubSubCheckListData((prevState) => ({
      ...prevState,
      [id]: res.payload,
    }));
  };

  const fetchLinkedPoliciesOfChecklist = async () => {
    const res = await dispatch(getLinkedPolicyList(ChecklistData?.id));
    setLinkedPolicyLength(res.payload);
  };

  const handleClick = useCallback(
    (val) => {
      setOpen((prevOpen) => !prevOpen);
      setActiveChecklist(val);
      setActiveSubChecklist(null);
      setActiveSubSubChecklist(null);

      if (subCheckListData?.length > 0) {
        dispatch(UIStateActions.setPoliciesColumnVisibility(false));
        dispatch(UIStateActions.setCompliancesColumnVisibility(false));
        dispatch(
          UIStateActions.setRetiredPolicyProceduresColumnVisibility(false)
        );
      } else {
        onClickContainer(ChecklistData?.id, ChecklistData);
      }
    },
    [subCheckListData, dispatch, ChecklistData, onClickContainer]
  );

  const handleSubChecklistClick = (subCheckList) => {
    setSubChecklistOpen((prevState) => ({
      ...prevState,
      [subCheckList.id]: !prevState[subCheckList.id],
    }));
    fetchSubSubCheckListData(subCheckList.id);
    setActiveSubChecklist(subCheckList.id);
    setActiveSubSubChecklist(null);
    setActiveChecklist(null);
    onClickSubContainer(subCheckList);
  };

  const handleSubSubChecklistClick = (subCheckList) => {
    setActiveSubSubChecklist(subCheckList.id);
    setActiveChecklist(null);
    setActiveSubChecklist(null);
    onClickSubContainer(subCheckList);
  };

  const handleMenuClick = useCallback((event, level, item) => {
    setMenuAnchor(event.currentTarget);
    setMenuLevel(level);
    setSelectedSubCheckList(item);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
    setMenuLevel("");
    setSelectedSubCheckList(null);
  }, []);

  const handleMenuItemClick = (action, subCheckList) => {
    onMenuitemClick(action, subCheckList);
    handleMenuClose();
  };

  return (
    <StyledList
      bgColor={"transparent"}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <StyledListItemButton
        onClick={() => handleClick(ChecklistData?.name)}
        sx={{ background: `${PaleOlive}` }}
      >
        <StyledListItemText size="18px" primary={ChecklistData.name} />
        {subCheckListData?.length > 0 &&
          ReusableTypeReturn(
            open,
            <ArrowDropUpIcon sx={{ fontSize: "30px" }} />,
            <ArrowDropDownIcon sx={{ fontSize: "30px" }} />
          )}
        {isAdmin && (
          <StyledIcon
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label="list"
            onClick={(e) => handleMenuClick(e, "parent", ChecklistData)}
          >
            <MenuIcon fontSize="small" />
          </StyledIcon>
        )}
      </StyledListItemButton>
      <Collapse
        in={open && subCheckListData?.length > 0}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {subCheckListData?.map((subCheckList, subCheckListindex) => (
            <React.Fragment key={subCheckListindex}>
              <StyledListItemButtonChild
                sx={{ background: `${PaleOlive1}` }}
                onClick={() => handleSubChecklistClick(subCheckList)}
              >
                <StyledListItemText size="14px" primary={subCheckList.name} />
                {subSubCheckListData[subCheckList.id]?.length > 0 &&
                  ReusableTypeReturn(
                    subChecklistOpen[subCheckList.id],
                    <ArrowDropUpIcon />,
                    <ArrowDropDownIcon />
                  )}
                {isAdmin && (
                  <StyledIcon
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="list"
                    onClick={(e) => handleMenuClick(e, "child", subCheckList)}
                  >
                    <MenuIcon fontSize="small" />
                  </StyledIcon>
                )}
              </StyledListItemButtonChild>
              <Collapse
                in={subChecklistOpen[subCheckList.id]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {subSubCheckListData[subCheckList.id]?.map(
                    (subSubChecklist, index) => (
                      <StyledListItemButtonSubChild
                        bgColor={ReusableTypeReturn(
                          activeSubSubChecklist === subSubChecklist.id,
                          PaleOlive2,
                          subSubChecklist.color
                        )}
                        key={index}
                        onClick={() =>
                          handleSubSubChecklistClick(subSubChecklist)
                        }
                      >
                        <StyledListItemText
                          size="12px"
                          primary={subSubChecklist.name}
                        />
                        {isAdmin && (
                          <StyledIcon
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-label="list"
                            onClick={(e) =>
                              handleMenuClick(e, "subChild", subSubChecklist)
                            }
                          >
                            <MenuIcon fontSize="small" />
                          </StyledIcon>
                        )}
                      </StyledListItemButtonSubChild>
                    )
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Collapse>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {ReusableTBoolReturn(menuLevel, "parent") && (
          <>
            <MenuItem
              disabled={linkedPolicyLength?.length > 0}
              onClick={() =>
                handleMenuItemClick("Add new sub title", ChecklistData)
              }
            >
              Add new sub title
            </MenuItem>
            <MenuItem onClick={() => console.log("add new item")}>
              Add new item
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("Edit title", ChecklistData)}
            >
              Edit title
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Delete checklist", ChecklistData)
              }
            >
              Delete title
            </MenuItem>
          </>
        )}
        {ReusableTBoolReturn(menuLevel, "child") && selectedSubCheckList && (
          <>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Add new sub item", selectedSubCheckList)
              }
            >
              Add new sub item
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Edit sub title", selectedSubCheckList)
              }
            >
              Edit sub title
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Delete sub title", selectedSubCheckList)
              }
            >
              Delete sub title
            </MenuItem>
          </>
        )}
        {ReusableTBoolReturn(menuLevel, "subChild") && (
          <>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Edit sub item", selectedSubCheckList)
              }
            >
              Edit sub title
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Delete sub item", selectedSubCheckList)
              }
            >
              Delete sub item
            </MenuItem>
          </>
        )}
      </Menu>
    </StyledList>
  );
});

export default ReusableNestedChecklists;
