import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
  showPoliciesColumn: false,
  showCompliancesColumn: false,
  showRetiredPolicyProceduresColumn: false,
  showNotificationsColumn: false,
};

const UIStateSlice = createSlice({
  name: "UIState",
  initialState: initialUIState,
  reducers: {
    setPoliciesColumnVisibility(state, action) {
      state.showPoliciesColumn = action.payload;
    },
    setCompliancesColumnVisibility(state, action) {
      state.showCompliancesColumn = action.payload;
    },
    setRetiredPolicyProceduresColumnVisibility(state, action) {
      state.showRetiredPolicyProceduresColumn = action.payload;
    },
    setNotificationsColumnVisibility(state, action) {
      state.showNotificationsColumn = action.payload;
    },
  },
});

export const UIStateActions = UIStateSlice.actions;

export default UIStateSlice;
