import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { errorToast, successToast } from '../../Toast/Toaster';
import Input from '../../Inputs/Input';
import FileInput from '../../Inputs/FileInput';
import { useDispatch } from "react-redux";
import { LinkDocCompliance, LinkPolicyCompliance, addDocument} from '../../../store/checklist-slice';
import mammoth from 'mammoth';
import { PostCompliance } from '../../../services/checklistService';

const ImportCompliance = ({ open, onClose, policyId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "", complianceDescription: "" });
  const [inputErrors, setInputErrors] = useState({ name: "", complianceDescription: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const addComplianceRes = await PostCompliance(formData);
      const complianceId = addComplianceRes.id;

      const DocData = {
        name: "Doc-" + formData.name.replaceAll(" ", ""),
        path: "user/document/" + formData.name.replaceAll(" ", "") + "/test.pdf",
        content: ""
      };
      const responseDoc = await dispatch(addDocument(DocData));
      const docId = responseDoc.payload.id;

      await dispatch(LinkPolicyCompliance({ policyId, complianceId }));
      await dispatch(LinkDocCompliance({ complianceId, docId }));

      successToast(`Compliance added successfully...`);
      modalClose();
    } catch (error) {
      errorToast("Failed to add new compliance, please try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData(prevData => ({ ...prevData, [fieldName]: value }));
    setInputErrors(prevErrors => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleFileChange = async (file) => {
    if (!file) return;

    if (!file.name.endsWith('.docx')) {
      setInputErrors(prevErrors => ({ ...prevErrors, complianceDescription: 'Please upload a .docx file.' }));
      return;
    }

    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      setFormData(prevData => ({ ...prevData, complianceDescription: result.value }));
      setInputErrors(prevErrors => ({ ...prevErrors, complianceDescription: "" }));
    } catch (err) {
      console.error('Error processing file:', err);
      setInputErrors(prevErrors => ({ ...prevErrors, complianceDescription: 'Error processing file. Please try again.' }));
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "This field is required";
      isValid = false;
    } else if (formData.name.length > 260) {
      errors.name = `Policy Name must be 260 characters or fewer. Currently ${formData.name.length} characters.`;
      isValid = false;
    }

    if (!formData.complianceDescription.trim()) {
      errors.complianceDescription = "File upload is required";
      isValid = false;
    }

    setInputErrors(errors);
    return isValid;
  };

  const modalClose = () => {
    setFormData({ name: "", complianceDescription: "" });
    setInputErrors({ name: "", complianceDescription: "" });
    onClose();
  };

  return (
    <ModalWrapper
      open={open}
      onClose={modalClose}
      loading={loading}
      heading="Import Compliance"
      handleSave={handleSubmit}
    >
      <Input
        label="New Compliance Name"
        type="text"
        value={formData.name}
        onChange={handleChange("name")}
        error={inputErrors.name}
      />
      <FileInput
        label="Upload E-Sign"
        onChange={handleFileChange}
        name="file"
        accept=".doc,.docx"
        error={inputErrors.complianceDescription}
      />
    </ModalWrapper>
  );
};

export default ImportCompliance;
