import React, { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { errorToast, successToast } from '../../Toast/Toaster';
import { GetChecklistAll, PostLinkedPolicylist } from '../../../services/checklistService';
import AutoCompleteSearch from '../../SearchBox/AutoCompleteSearch';

const PolicyLinkedChecklist = ({ open, onClose, PolicyData, checklistId }) => {
    const [loading, setLoading] = useState(false)
    const [checklist, setChecklist] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputErrors, setInputErrors] = useState({ selectedOptions: "" });

    const fetchChecklistApi = async () => {
        const res = await GetChecklistAll()
        if (res?.data) {
            const filteredChecklist = res.data?.filter((item) => item.id !== checklistId)
            setChecklist(filteredChecklist)
        }
        else {
            errorToast("something went wrong...")
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setLoading(true);

        try {
            const response = await PostLinkedPolicylist(selectedOptions.id, PolicyData.id)
            if (response && response.error) {
                errorToast(`Failed to Link ${PolicyData.name} to ${selectedOptions.name}`);
            }
            else {
                successToast(`Linked ${PolicyData.name} to ${selectedOptions.name} successfully...`);
                handleClose();
            }
        } catch (error) {
            errorToast(`Failed to Link ${PolicyData.name} to ${selectedOptions.name}`);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        onClose()
        setInputErrors({ ...inputErrors, selectedOptions: "" });
        setSelectedOptions([])
    }
    const handleChangeSearch = (event, value) => {
        event.preventDefault();
        setSelectedOptions(value)
        setInputErrors({ ...inputErrors, selectedOptions: "" });
    };
    const validateForm = () => {
        const errors = {};
        let isValid = true;
        if (selectedOptions?.length === 0) {
            errors.selectedOptions = "This field is required";
            isValid = false;
        }
        setInputErrors(errors);
        return isValid;
    };

    return (
        <ModalWrapper
            open={open}
            onClose={handleClose}
            loading={loading}
            heading={`Link ${PolicyData.name} to Checklist`}
            handleSave={handleSubmit}
        >
            <AutoCompleteSearch
                handleChange={handleChangeSearch}
                searchList={checklist}
                placeholder="Search here..."
                loading={loading}
                selectedOptions={selectedOptions}
                apiCall={fetchChecklistApi}
                width="100%"
                error={inputErrors.selectedOptions}
            />
        </ModalWrapper>
    )
}

export default PolicyLinkedChecklist