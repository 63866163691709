import { createSlice } from "@reduxjs/toolkit";

const intitialAddOnState = {
  addOns: [],
};

const addOnSlice = createSlice({
  name: "addOn",
  initialState: intitialAddOnState,
  reducers: {
    setAddOns(state, action) {
      state.addOns = action.payload.addOns;
    },
  },
});

export const addOnActions = addOnSlice.actions;

export default addOnSlice;
