import React, { useEffect, useState, useCallback } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { RetiredSubCrud } from "../../../constant/Constant";
import AreYouSure from "../../Modals/Confirm/AreYouSure";
import { deleteCompliance, getPolicyLinkedCompliance } from "../../../store/checklist-slice";
import { errorToast, successToast } from "../../Toast/Toaster";
import ReusableList from "../../reusablelist/ReusableList";
import AddComplianceModal from "../../Modals/AddComplianceModal/AddComplianceModal";
import useUserRole from "../../../customHooks/useUserRole";
import styled from "@emotion/styled";
import { Iron, PaleOlive,ParisGreen} from "../../../styles/Colors";
import { PutCompliance } from "../../../services/checklistService";
const StyledRetiredHead = styled(Paper)`
  background-color: ${ParisGreen};
  color: black;
  padding: 16px; 
  border: 1px solid ${Iron};
  border-radius:0px;
  margin-bottom:10px;
`;
const RetiredPPColumn = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ bool: false, type: "", data: {} })
  const [selectedTab, setSelectedTab] = useState("")
  const { SeletedPolicy } = useSelector((state) => state.checklist);

  useEffect(() => {
    let id = SeletedPolicy.payload.id
    fetchData(id);
  }, [SeletedPolicy, modal]);

  const fetchData = async (id) => {
    if (id) {
      try {
        let res = await dispatch(getPolicyLinkedCompliance(id));
        const resData = res.payload.data
        const filteredData = resData?.filter((list) => list.compliance.isRetired === 1)
        setData(filteredData)
      } catch (error) {
        console.error("Error fetching policy linked compliance:", error);
      }
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault()
    try {
      setLoading(true);
      if (modal?.data?.id) {
        const response = await dispatch(deleteCompliance(modal.data.id));
        if (!response.error) {
          successToast(`${modal.data.name} deleted successfully`);
          setModal({ bool: false, type: "", data: {} });
          fetchData()
        }
      }
    } catch (error) {
      errorToast(error.message);
    } finally {
      setLoading(false);
    }

  };

  const handleColorAPI = async (complianceID, ComplianceName) => {
    await PutCompliance(complianceID, { color: "white", name: ComplianceName });
  };

  const handleModal = useCallback((type, dataItem) => {
    setModal({ bool: true, type: type, data: dataItem });
  }, []);
  const closeConfirmationModalNew = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const handleSelectedTab = (val) => {
    const { name, id, color } = val
    if (color !== "white") {
      handleColorAPI(id, name)
    }
    setSelectedTab(name)
  }
  return (
    <>
      {modal.type === "Delete Retired" && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}

      {modal.type === "Edit Retired" &&
        <AddComplianceModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          data={modal?.data}
          type="Edit"
          isRetired={true}
        />
      }
      <Box>
        <StyledRetiredHead >
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography  variant="subtitle2">
              {SeletedPolicy.payload.name}
            </Typography>
          </Stack>
        </StyledRetiredHead>
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          {data?.map((item, index) => {
            const boxColor = item.compliance.name === selectedTab ? PaleOlive : item.compliance?.color;

            return (
              <ReusableList
                key={index}
                data={item.compliance}
                menuItems={RetiredSubCrud}
                isAdmin={isAdmin}
                onHamburgerClick={handleModal}
                onClickContainer={() => handleSelectedTab(item.compliance)}
                boxColor={boxColor}
              />
            );
          })}
        </Box>
      </Box>
    </>

  )
};

export default RetiredPPColumn;
