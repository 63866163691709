import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { LinkDocPolicy, LinkPolicyCheckList, addDocument, addPostlist, checklistActions, getLinkedPolicyList, getPolicyLists } from "../../../store/checklist-slice";
import { errorToast, successToast } from "../../Toast/Toaster";
import { PutPolicy } from "../../../services/checklistService";
import { ReusableTypeReturn } from "../../../utils/helperUtils";

const AddPolicyModal = ({ open, onClose, checklistId, type, data,updateData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({ name: "" });
  const [inputErrors, setInputErrors] = useState({ name: "" });

  useEffect(() => {
    if (data?.name) {
      setFormData((prevData) => ({ ...prevData, name: data.name }));
    }
  }, [data?.name]);
  
  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    setInputErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      type === "Add"?await handleAddPolicy(): await handleEditPolicy();
      dispatch(getLinkedPolicyList(checklistId));
      modalClose();
    } catch (error) {
      errorToast("Failed to add new Policy, please try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPolicy = async () => {
    const addPolicyRes = await dispatch(addPostlist({ name: formData.name, complianceColor: "darkgray", retiredColor: "darkgray" }));
    const policyId = addPolicyRes.payload.id;

    await dispatch(LinkPolicyCheckList({ checkListId: checklistId, policyId, policyNumber: "12" }));

    const DocData = {
      name: "Doc-" + formData.name.replaceAll(" ", ""),
      path: "user/document/" + formData.name.replaceAll(" ", "") + "/test.pdf",
      content: ""
    };
    const responseDoc = await dispatch(addDocument(DocData));
    const docId = responseDoc.payload.id;

    await dispatch(LinkDocPolicy({ policyId, docId }));

    dispatch(checklistActions.setSelectedLinkedPolicy(addPolicyRes.payload.id));
    successToast("Policy added successfully...");
  };
  const handleEditPolicy = async () => {
    const res = await PutPolicy(data.id, { name: formData.name });
    dispatch(checklistActions.setSelectPolicyList({ payload:res,index: res.policyIndex }));
    await dispatch(getPolicyLists());
    if (updateData) {
      await updateData();
    }
    successToast("Policy edited successfully...");
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {};
  
    if (!formData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    } else if (formData.name?.length > 260) {
      errors.name = `Policy Name must be 260 characters or fewer. Currently ${formData.name?.length} characters.`;
      isValid = false;
    }
  
    setInputErrors(errors);
    return isValid;
  };
  
  const modalClose = () => {
    setFormData({ name: "" });
    setInputErrors({ name: "" })
    onClose();
  };
  return (
    <>
      <ModalWrapper
        open={open} onClose={modalClose}
        loading={loading}
        heading={ReusableTypeReturn(type === "Add", "Add New Policy", "Edit Policy")}
        handleSave={handleSubmit}
      >
        <Input
          label={ReusableTypeReturn(type === "Add", "New Policy Name", "Edit Policy Name")}
          type="text"
          value={formData.name}
          onChange={handleChange("name")}
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddPolicyModal;
