import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import HomePage from "./pages/HomePage";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAddOns } from "./store/addon-actions";
import { fetchIsAdmin } from "./store/permissions-slice";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { ToastContainer } from "react-toastify";
import Admin from "./pages/Admin";
import { postAddSelf } from "./services/addSelf";
import { UIUserActions } from "./store/user-state-slice";
import { getUserById } from "./services/userById";
import PrivateRoute from "./utils/PrivateRoute";
import AdminUserAdd from "./pages/AdminUserAdd";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007099",
    },
    secondary: {
      main: "#00b3cc",
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRes = await postAddSelf();
        const singleUserRes = await getUserById(userRes.id);
        dispatch(UIUserActions.setUserData(singleUserRes.data));
        dispatch(fetchAddOns());
        dispatch(fetchIsAdmin());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch, isAuthenticated]);

  useMsalAuthentication(InteractionType.Redirect, loginRequest);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Layout>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route element={<PrivateRoute isLoggedIn={isAuthenticated} />}>
              <Route path="/admin" exact element={<Admin />} />
              <Route path="/add-user" exact element={<AdminUserAdd />} />
            </Route>
          </Routes>
        </Layout>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
