import React, { useEffect, useState, useCallback } from "react";
import {
  fetchChecklistCategories,
  checklistActions,
  deleteChecklistCategory,
} from "../../../store/checklist-slice";
import { UIStateActions } from "../../../store/ui-state-slice";
import { fetchResourcePermissionLevel } from "../../../store/permissions-slice";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classes from "./ChecklistCategoriesDropdown.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { PermissionLevel } from "../../../enums/PermissionLevel";
import AddChecklistCategoryModal from "../../Modals/AddChecklistCategoryModal/AddChecklistCategoryModal";
import AreYouSure from "../../Modals/Confirm/AreYouSure";
import { errorToast, successToast } from "../../Toast/Toaster";
import useUserRole from "../../../customHooks/useUserRole";
import styled from "@emotion/styled";

const StyledSelectCategoyButton = styled(Button)`
  background-color: #f1f1f1 !important;
  color: black;
  border-radius: 0px;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 9px;
  padding: 2px;
`;
const StyledBox = styled(Box)`
  color: black;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const ChecklistCategoriesDropdown = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [isChecklistCategoryModalOpen, setIsChecklistCategoryModalOpen] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setModal] = useState({ bool: false, type: "", data: {} });
  const open = Boolean(anchorEl);
  const checklistCategories = useSelector(
    (state) => state.checklist.checklistCategories
  );
  const fetchChecklistCategoriesStatus = useSelector(
    (state) => state.checklist.requestStatus["fetchChecklistCategories"]
  );
  const permissionLevels = useSelector(
    (state) => state.permission.resourcePermissionLevels
  );

  useEffect(() => {
    dispatch(fetchChecklistCategories());
  }, [dispatch]);

  useEffect(() => {
    checklistCategories?.map((checklistCategory) =>
      dispatch(fetchResourcePermissionLevel(checklistCategory.id))
    );
  }, [dispatch, checklistCategories]);

  const handleAddChecklistCategoryModal = () => {
    setAnchorEl(null);
    setIsChecklistCategoryModalOpen(true);
    setIsEdit(false);
  };

  const handleEditChecklistCategoryModal = (checklistCategoryData) => {
    setAnchorEl(null);
    setIsChecklistCategoryModalOpen(true);
    setIsEdit(true);
    setInitialData(checklistCategoryData);
  };

  const closeChecklistCategoryModal = () => {
    setIsChecklistCategoryModalOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChecklistCategory = (checklistCategoryId) => {
    if (checklistCategoryId) {
      dispatch(
        checklistActions.setSelectedChecklistCategory(checklistCategoryId)
      );
      dispatch(UIStateActions.setPoliciesColumnVisibility(false));
      dispatch(UIStateActions.setCompliancesColumnVisibility(false));
      dispatch(
        UIStateActions.setRetiredPolicyProceduresColumnVisibility(false)
      );
    }
    handleClose();
  };

  const hasPermissions = (resourceId) => {
    return isAdmin || permissionLevels[resourceId] >= PermissionLevel.Edit;
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (modal?.data?.id) {
        const response = await dispatch(
          deleteChecklistCategory(modal?.data?.id)
        );
        if (!response.error) {
          successToast("Checklist category deleted successfully!");
          setModal({ bool: false, type: "", data: {} });
          dispatch(UIStateActions.setPoliciesColumnVisibility(false));
          dispatch(UIStateActions.setCompliancesColumnVisibility(false));
          dispatch(
            UIStateActions.setRetiredPolicyProceduresColumnVisibility(false)
          );
        } else if (response.error) {
          errorToast(response.error.message);
          setModal({ bool: false, type: "", data: {} });
        }
      }
      dispatch(fetchChecklistCategories());
    } catch (error) {
      errorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleModal = useCallback((type, data) => {
    setModal({ bool: true, type: type, data: data });
    setIsEdit(true);
  }, []);
  const closeConfirmationModalNew = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  return (
    <React.Fragment>
      {fetchChecklistCategoriesStatus === "pending" ? (
        <CircularProgress />
      ) : (
        <div>
          <AddChecklistCategoryModal
            open={isChecklistCategoryModalOpen}
            onClose={closeChecklistCategoryModal}
            isEdit={isEdit}
            initialData={initialData}
            checklistCategoryId={selectedCategory?.id}
          />
          {modal.type === "delete" && (
            <AreYouSure
              open={modal.bool}
              onClose={closeConfirmationModalNew}
              submitClicked={handleDelete}
              heading={`Are you sure you want to delete ${modal?.data?.name}?`}
              loading={loading}
            />
          )}
          <StyledSelectCategoyButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon sx={{ fontSize: "40px !important" }} />}
          >
            Select Checklist Category
          </StyledSelectCategoyButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isAdmin && (
              <StyledBox onClick={handleAddChecklistCategoryModal}>
                Add New Checklist Category
              </StyledBox>
            )}
            <AddChecklistCategoryModal
              open={isChecklistCategoryModalOpen}
              onClose={closeChecklistCategoryModal}
            />
            {isAdmin && <Divider />}
            {checklistCategories
              ? checklistCategories.map((checklistCategory) => (
                  <MenuItem
                    key={checklistCategory.id}
                    id={checklistCategory.id}
                    className={classes.menuItemClass}
                    onClick={() => {
                      handleSelectChecklistCategory(checklistCategory.id);
                    }}
                  >
                    <div className={classes.menuItemTitle}>
                      {checklistCategory.name}
                    </div>
                    <div>
                      {hasPermissions(checklistCategory.id) && (
                        <EditIcon
                          onClick={() => {
                            handleEditChecklistCategoryModal(checklistCategory);
                            setSelectedCategory(checklistCategory);
                          }}
                          style={{ color: "#757575" }}
                          titleAccess={`Edit ${checklistCategory.name}`}
                        />
                      )}
                      {hasPermissions(checklistCategory.id) && (
                        <DeleteIcon
                          onClick={() =>
                            handleModal("delete", checklistCategory)
                          }
                          style={{ color: "#e53935" }}
                          titleAccess={`Delete ${checklistCategory.name}`}
                        />
                      )}
                    </div>
                  </MenuItem>
                ))
              : ""}
          </Menu>
        </div>
      )}
    </React.Fragment>
  );
};

export default ChecklistCategoriesDropdown;
