import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
    userResponse: null,
};

const UIUserStateSlice = createSlice({
  name: "UIUser",
  initialState: initialUIState,
  reducers: {
    setUserData(state, action) {
      state.userResponse = action.payload;
    },
   
  },
});

export const UIUserActions = UIUserStateSlice.actions;

export default UIUserStateSlice;