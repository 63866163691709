import axios from "axios";

export const GetIsAdministrator = async () => {
  const options = {
    method: "GET",
    url: "Permission/IsAdministrator",
    baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
  };
  return await axios.request(options);
};

export const GetResourcePermissionLevel = async (resourceId) => {
  const options = {
    method: "GET",
    url: "Permission/ResourcePermissionLevel",
    baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
    params: { ResourceId: resourceId },
  };
  return await axios.request(options);
};
