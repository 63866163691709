import axios from "axios";

export const postAddUser = async (formData) => {
    const options = {
        method: "POST",
        url: `User/User`,
        baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
        data: formData,
    };
    return  axios.request(options);
  };
  