import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import styled from '@emotion/styled';
import { FreshGreen, Iron,GuardsmanRed } from '../../styles/Colors';
const StyledInput=styled(TextField)`
& label.Mui-focused {
    color: black;
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
  }
  & .MuiOutlinedInput-root {
    color: black;
    &:hover fieldset {
        border-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
    }
    &.Mui-focused fieldset {
        border-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
    }
  }
`

const StyleButtonSave = styled(Button)`
color: white;
margin-right: 16px;
background-color: ${FreshGreen};
&:hover {
  color: white;
  background-color: ${FreshGreen};
}
`;

const FileInput = ({ label, onChange, error, name,accept="image/*"}) => {
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            onChange(file);
        }
    };

    return (
        <>
            <input
                accept={accept}
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
                name={name}
            />
            <StyledInput
                id="outlined-basic"
                label={label}
                value={fileName}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <>
                            <StyleButtonSave
                                variant="contained"
                                component="span"
                                onClick={() =>
                                    document.getElementById(
                                        'contained-button-file'
                                    ).click()
                                }
                            >
                                Browse
                            </StyleButtonSave>
                        </>
                    ),
                }}
                fullWidth
                autoComplete="off"
                error={!!error}
                helperText={error}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    );
};

export default FileInput;
