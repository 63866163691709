// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignatureView_container__TmAB3 {
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.SignatureView_content__K-NKm {
  display: flex;
  flex-direction: column;
}

.SignatureView_description__nKOfR {
  flex: 1 1;
  font-size: 16px;
}
.SignatureView_image__4HV7z {
  width: 350px; 
  height: 180px;
  object-fit: contain;
  background-color: white;
}

.SignatureView_updated__RLN7H {
  display: flex;
  font-size: 14px;
}

.SignatureView_updated__RLN7H > svg {
  margin-right: 5px;
  color: #555;
}
.SignatureView_flex__9MR5a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/signatureview/SignatureView.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".container {\n  gap: 20px;\n  padding: 20px;\n  border: 1px solid #ccc;\n  background-color: #f9f9f9;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n}\n\n.description {\n  flex: 1;\n  font-size: 16px;\n}\n.image {\n  width: 350px; \n  height: 180px;\n  object-fit: contain;\n  background-color: white;\n}\n\n.updated {\n  display: flex;\n  font-size: 14px;\n}\n\n.updated > svg {\n  margin-right: 5px;\n  color: #555;\n}\n.flex{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SignatureView_container__TmAB3`,
	"content": `SignatureView_content__K-NKm`,
	"description": `SignatureView_description__nKOfR`,
	"image": `SignatureView_image__4HV7z`,
	"updated": `SignatureView_updated__RLN7H`,
	"flex": `SignatureView_flex__9MR5a`
};
export default ___CSS_LOADER_EXPORT___;
