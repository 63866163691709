import React from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FreshGreen } from '../../styles/Colors';

const RadioButtonGroup = ({ options, value, onChange }) => {
  const handleChangeRadio = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="radio-button-group-label"
        value={value}
        onChange={handleChangeRadio}
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio sx={{'&, &.Mui-checked': {color: FreshGreen}}}/>}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
