import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Box from "@mui/material/Box";
import ProgressBars from "../common/ProgressBar";
import { NotificationGet } from "../../store/checklist-slice";
import { useDispatch } from "react-redux";
import { Cinderella, GuardsmanRed, SaharaSand, Seashell } from "../../styles/Colors";
import NoResult from "../NoResult/NoResult";
import { formatDate } from "../../utils/helperUtils";
import { fetchNotificationData, handleContainerClose } from "../../utils/NotificationUtils";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const NotificationPaperDiv = styled(Paper)(({ backgroundColor }) => ({
  boxShadow: 'none',
  backgroundColor: backgroundColor,
  cursor: "pointer",
  '&.MuiPaper-root': {
    backgroundColor: backgroundColor,
    width: '100%',
    padding: '4px',
    borderRadius: '0 !important',
    margin: '20px 0',
  },
}));

const NotificatonRedContainer = styled(Box)`
font-size: 18px;
color: white;
font-weight: 500;
text-align: center;
background:${GuardsmanRed};
padding:20px;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    width: 350px;
    background-color:${Seashell};
    padding:20px;
    border-radius:0 !important;
  }
`;
const Notifications = () => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [completedProgress, setCompletedProgress] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      fetchNotificationAPI();
    }
  }, [open]);

  const fetchNotificationAPI = async () => {
    try {
      const res = await dispatch(NotificationGet());
      if (res?.payload) {
        const { notifictionList = [], complatedPercentage } = res.payload.data;
        setNotificationData(notifictionList);
        setCompletedProgress(complatedPercentage);
      }
    } catch (error) {
      console.error("Error fetching action list:", error);
    }
  };


  const handleNotiClick = async (val) => {
    await fetchNotificationData(val, (data) => {
      handleContainerClose(val, data, dispatch, handleClose);
    });
  };
  
  return (
    <div>
      <IconButton color="error" onClick={handleClick}>
        <NotificationsIcon />
      </IconButton>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >

        <Box>
          <NotificatonRedContainer >
            Notification Center
          </NotificatonRedContainer>

          <ProgressBars completedProgress={completedProgress} />

          <Box >
            {notificationData?.length === 0 ?
              <NoResult text="No notifications available" />
              :
              <>
                {notificationData?.map((item, index) => (
                   <NotificationPaperDiv backgroundColor={item?.typeOfNotification==="Update"?Cinderella:SaharaSand} key={index} onClick={() => handleNotiClick(item)}>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      padding="5px"
                    >
                      <Typography variant="caption">{item.description}</Typography>
                    </Stack>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      padding="5px"
                    >
                    <CalendarMonthIcon fontSize="small"/>   <Typography variant="caption">{formatDate(item.notificationDate)}</Typography>
                    </Stack>
                  </NotificationPaperDiv>
                ))}
              </>
            }
          </Box>
        </Box>
      </StyledPopover>
    </div>
  );
}

export default Notifications;
