
import { getComplianceById, getPolicyById } from '../services/checklistService';
import { checklistActions } from '../store/checklist-slice';
import { UISearchActions } from '../store/seach-state-slice';
import { UIStateActions } from '../store/ui-state-slice';

const serviceMap = {
    Policy: getPolicyById,
    Compliance: getComplianceById,
};

export const fetchNotificationData = async (data, callback) => {
    if (!data?.type || !data?.id) return;
  
    const fetchService = serviceMap[data.type];
    if (!fetchService) return;
  
    try {
      const res = await fetchService(data.id);
      if (res?.data) {
        callback(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  
  

export const handleContainerClose = (data, modalData, dispatch, onClose) => {
    onClose();
    if (!data?.type) return;

    switch (data.type) {
        case "Policy":
            dispatch(UISearchActions.setPolicyID({ policyID: modalData }));
            dispatch(checklistActions.setSelectedChecklistCategory(null));
            dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
            dispatch(checklistActions.setSelectedChecklistId(null));
            break;
        case "Compliance":
            dispatch(UISearchActions.setComplianceID({ complianceID: modalData }));
            dispatch(checklistActions.setSelectedChecklistCategory(null));
            dispatch(UISearchActions.setPolicyID({ policyID: {} }));
            dispatch(checklistActions.setSelectedChecklistId(null));
            break;
        default:
            return;
    }

    dispatch(UIStateActions.setPoliciesColumnVisibility(data.type === "Policy"));
    dispatch(UIStateActions.setCompliancesColumnVisibility(data.type === "Compliance"));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
};


