import React, { useCallback, useState, memo } from 'react'
import ReusableList from '../reusablelist/ReusableList'
import { useDispatch } from "react-redux";
import { ComplianceSubCrud } from '../../constant/Constant';
import AddComplianceModal from '../Modals/AddComplianceModal/AddComplianceModal';
import AreYouSure from '../Modals/Confirm/AreYouSure';
import { deleteCompliance, moveToRetired } from '../../store/checklist-slice';
import { errorToast, successToast } from '../Toast/Toaster';
import { UISearchActions } from '../../store/seach-state-slice';
import ViewComplainceModal from '../Modals/viewComplainceModal/ViewComplainceModal';
import useUserRole from '../../customHooks/useUserRole';
import NoResult from '../NoResult/NoResult';

const AdvanceSearchCompliance = ({ data, updateData,searchValue}) => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [modal, setModal] = useState({ bool: false, type: "", data: {} })
  const [loading, setLoading] = useState(false)
  const [openCompliance, setOpenCompliance] = useState({ id: {}, bool: false })

  const handleModal = useCallback((type, dataaaa) => {
    setModal({ bool: true, type, data:dataaaa });
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);


  const handleMoveToRetired = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (modal.data.id) {
      await dispatch(moveToRetired(modal.data.id));
      successToast(`Moved ${modal.data.name} to retired successfully.`);
      setModal({ bool: false, type: "", data: {} });
      await updateData();
    }
    else {
      errorToast('Something went wrong...');
    }
    setLoading(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (modal.data.id) {
      await dispatch(deleteCompliance(modal.data.id));
      successToast(`${modal.data.name} deleted successfully`);
      setModal({ bool: false, type: "", data: {} });
      dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
      await updateData();
    }
    else {
      errorToast("Something went wrong...");
    }
    setLoading(false);
  };


  const handleComplianceView = (event, policy) => {
    event.stopPropagation();
    setOpenCompliance({ id: policy, bool: true })
  }
  const handleComplianceClose = () => {
    setOpenCompliance({ id: {}, bool: false })
  }


  const searchData = searchValue ? data.filter((item) => item.name?.toLowerCase().includes(searchValue?.toLowerCase())) : data

  return (
    <>
      {isAdmin &&
        <ViewComplainceModal
          open={openCompliance?.bool}
          onClose={handleComplianceClose}
          policyData={openCompliance?.id}
          updateData={updateData}
        />}
      {modal.type === "Edit Compliance" &&
        <AddComplianceModal
          open={modal.bool}
          onClose={closeConfirmationModal}
          data={modal?.data}
          type="Edit"
          updateData={updateData}
        />
      }
      {modal.type === "Move To Retired" &&
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModal}
          submitClicked={handleMoveToRetired}
          heading={`Confirm moving ${modal?.data?.name} to retired?`}
          loading={loading}
          button2Text="Move to retired"
        />
      }


      {modal.type === "Delete Compliance" &&
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModal}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      }

      {searchData.length > 0 ? (
        searchData.map((item, index) => (
          <ReusableList
            key={index}
            data={item}
            isAdmin={isAdmin}
            menuItems={ComplianceSubCrud}
            onHamburgerClick={handleModal}
            onClickContainer={(e) => handleComplianceView(e, item)}
            boxColor={item?.color}
          />
        ))
      ) : (
         <NoResult/>        
      )}

    </>
  )
}

export default memo(AdvanceSearchCompliance)