import React from 'react';
import Button from '@mui/material/Button';
import { Iron } from '../../styles/Colors';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  background: ${Iron};
  color: black;
  border-radius: 0px;
  font-size:12px;
  &:hover {
    background: ${Iron};
    color: black;
  }
`;

const CustomButton = ({ variant, className, content,onClick, ...restProps }) => {
  return (
    <StyledButton variant={variant} className={className} onClick={onClick}  {...restProps}>
      {content}
    </StyledButton>
  );
};

export default CustomButton;
