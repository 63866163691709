import React, { useCallback, useState, memo } from 'react'
import ReusableList from '../reusablelist/ReusableList'
import { useDispatch } from "react-redux";
import { RetiredSubCrud } from '../../constant/Constant';
import { deleteCompliance } from '../../store/checklist-slice';
import { errorToast, successToast } from '../Toast/Toaster';
import AreYouSure from '../Modals/Confirm/AreYouSure';
import { UISearchActions } from '../../store/seach-state-slice';
import AddComplianceModal from '../Modals/AddComplianceModal/AddComplianceModal';
import useUserRole from '../../customHooks/useUserRole';
import NoResult from '../NoResult/NoResult';

const AdvanceSearchRetired = ({ data, updateData, searchValue }) => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [modal, setModal] = useState({ bool: false, type: "", data: {} })
  const [loading, setLoading] = useState(false)

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (modal.data.id) {
      const response = await dispatch(deleteCompliance(modal.data.id));

      if (!response.error) {
        successToast(`${modal.data.name} deleted successfully`);
        setModal({ bool: false, type: "", data: {} });
        dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
        await updateData();
      } else {
        errorToast(response.error.message);
      }
    }

    setLoading(false);
  };

  const handleModal = useCallback((type, dataaaa) => {
    setModal({ bool: true, type, data:dataaaa });
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const searchData = searchValue ? data.filter((item) => item.name?.toLowerCase().includes(searchValue?.toLowerCase())) : data

  return (
    <>

      {modal.type === "Edit Retired" &&
        <AddComplianceModal
          open={modal.bool}
          onClose={closeConfirmationModal}
          data={modal?.data}
          type="Edit"
          updateData={updateData}
          isRetired={true}
        />
      }
      {modal.type === "Delete Retired" &&
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModal}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      }
      {searchData.length > 0 ? (
        searchData.map((item, index) => (
          <ReusableList
            key={index}
            data={item}
            isAdmin={isAdmin}
            menuItems={RetiredSubCrud}
            onHamburgerClick={handleModal}
            boxColor={item?.color}
          />
        ))
      ) : (
        <NoResult/>
      )}

    </>
  )
}

export default memo(AdvanceSearchRetired)