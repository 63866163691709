import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";
import AreYouSure from "../Modals/Confirm/AreYouSure";
import { errorToast, successToast } from "../Toast/Toaster";
import { checklistActions, deleteChecklist } from "../../store/checklist-slice";
import AddChecklistModal from "../Modals/AddChecklistModal/AddChecklistModal";
import AddsubcheckListModal from "../Modals/AddSubcheckListModal/AddsubcheckListModal";
import { Box } from "@mui/material";
import { UISearchActions } from "../../store/seach-state-slice";
import { UIStateActions } from "../../store/ui-state-slice";
import useUserRole from "../../customHooks/useUserRole";
import NoResult from "../NoResult/NoResult";
import ReusableNestedChecklists from "../ReusableNestedChecklists";

const AdvanceSearchChecklist = ({ data, updateData, searchValue }) => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [modal, setModal] = useState({ bool: false, type: "", data: {} });
  const [loading, setLoading] = useState(false);
  const [activeChecklist, setActiveChecklist] = useState(null);
  const [activeSubChecklist, setActiveSubChecklist] = useState(null);
  const [activeSubSubChecklist, setActiveSubSubChecklist] = useState(null);
  const handleDelete = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        setLoading(true);
        if (modal?.data?.id) {
          const response = await dispatch(deleteChecklist(modal?.data?.id));
          if (!response.error) {
            successToast(`Checklist deleted successfully`);
            setModal({ bool: false, type: "", data: {} });
            await updateData();
          }
        }
      } catch (error) {
        errorToast(error.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, modal]
  );

  const handleModal = useCallback((type, dataaaa) => {
    setModal({ bool: true, type: type, data: dataaaa });
  }, []);

  const closeConfirmationModalNew = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const handleChecklistSelectClick = () => {
    console.log("handleChecklistSelectClick");
  };
  const handleSubChecklistSelectClick = useCallback(
    (val) => {
      dispatch(checklistActions.setSelectedSubChecklistId(val));
      dispatch(UISearchActions.setPolicyID({ policyID: {} }));
      dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
      handleFalseGrids();
    },
    [dispatch]
  );

  const handleFalseGrids = useCallback(() => {
    dispatch(UIStateActions.setPoliciesColumnVisibility(true));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
  }, [dispatch]);

  const searchData = searchValue
    ? data.filter((item) =>
        item.name?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    : data;
  return (
    <>
      {modal.type === "Edit title" && (
        <AddChecklistModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          isEdit={true}
          initialData={modal?.data}
          updateData={updateData}
        />
      )}

      {modal.type === "Delete checklist" && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}
      {modal.type === "Add new sub title" && (
        <AddsubcheckListModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          heading={`Add new sub title for ${modal.data.name}`}
          type="Add"
          data={modal.data}
          updateData={updateData}
        />
      )}
      {modal.type === "Edit sub title" && (
        <AddsubcheckListModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          heading={`Edit sub title for ${modal.data.name}`}
          type="Edit"
          data={modal.data}
        />
      )}

      {modal.type === "Delete Sub Checklist" && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}

      {searchData.length > 0 ? (
        searchData.map((checklist, index) => (
          <Box key={index}>
            <ReusableNestedChecklists
              ChecklistData={checklist}
              onMenuitemClick={handleModal}
              isAdmin={isAdmin}
              onClickContainer={handleChecklistSelectClick}
              onClickSubContainer={handleSubChecklistSelectClick}
              setActiveChecklist={setActiveChecklist}
              activeChecklist={checklist?.name === activeChecklist}
              setActiveSubChecklist={setActiveSubChecklist}
              activeSubChecklist={activeSubChecklist}
              setActiveSubSubChecklist={setActiveSubSubChecklist}
              activeSubSubChecklist={activeSubSubChecklist}
            />
          </Box>
        ))
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default memo(AdvanceSearchChecklist);
