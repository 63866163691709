// DropIcon.js
import React from 'react';
import { useDrop } from 'react-dnd';
import { FreshGreen } from '../../styles/Colors';

const DropIcon = ({ id, onDrop, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'COMPLIANCE',
    drop: (draggedItem, monitor) => {
      const dropTargetId = id;
      if (onDrop) {
        onDrop(draggedItem, dropTargetId);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <div
      ref={drop}
      style={{ backgroundColor: isActive ? FreshGreen: 'transparent' }}
    >
      {children}
    </div>
  );
};

export default DropIcon;


