import SignatureCanvas from 'react-signature-canvas'
import ErrorMessage from '../Inputs/ErrorMessage'
import { FreshGreen } from '../../styles/Colors'

const Signature = ({sigCanvas,error}) => {
    return (
        <>
            <SignatureCanvas
                penColor={FreshGreen}
                canvasProps={{ width: 520, height: 200, className: 'sigCanvas' }}
                backgroundColor="rgb(245, 245, 245)"
                ref={sigCanvas}
            />
                {error && <ErrorMessage error={error} />}
        </>
    )
}

export default Signature