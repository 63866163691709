import React, { useState } from 'react'
import AdminSideBar from '../components/AdminComponents/AdminSideBar'
import styled from '@emotion/styled';
import Input from '../components/Inputs/Input';
import CustomButton from '../components/common/CustomButton';
import { errorToast, successToast } from '../components/Toast/Toaster';
import { postAddUser } from '../services/addUser';
import { Iron } from '../styles/Colors';
const Container = styled.div`
  height: 100vh;
`;

const FlexBox = styled.div`
display:flex;
flex-grow: 1;
flex-direction: row;
justify-content: space-between;
gap:20px;
`
const StyledSubmit=styled(CustomButton)`
background:${Iron};
color:black;
border-radius:0px;
font-size:12px;
&:hover {
  background: ${Iron};
  color: black;
}
`
const AdminUserAdd = () => {
    const [formData, setFormData] = useState({
        "username": "",
        "firstName": "",
        "lastName": "",
        "email": ""
    });
    const [inputErrors, setInputErrors] = useState({
        "username": "",
        "firstName": "",
        "lastName": "",
        "email": ""
    })
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setLoading(true);

        try {
            const res=await postAddUser(formData)
            if(res?.data?.id){
                successToast('User added successfully...')
                const reset={
                    "username": "",
                    "firstName": "",
                    "lastName": "",
                    "email": ""
                }
                setFormData(reset)
                setInputErrors(reset)
            }else{
                errorToast(res?.error?.message);
            }         
        } catch (error) {
            errorToast(error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (fieldName) => (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "",
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!formData.username?.trim()) {
            errors.username = "This field is required";
            isValid = false;
        }
        if (!formData.firstName?.trim()) {
            errors.firstName = "This field is required";
            isValid = false;
        }
        if (!formData.lastName?.trim()) {
            errors.lastName = "This field is required";
            isValid = false;
        }
        if (!formData.email?.trim()) {
            errors.email = "This field is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Invalid email address";
            isValid = false;
        }
        setInputErrors(errors);
        return isValid;
    };
    return (
        <AdminSideBar heading="Add User">
            <Container>
                <form onSubmit={handleSubmit}>
                    <Input
                        label={"UserName"}
                        type="text"
                        value={formData.username}
                        onChange={handleChange("username")}
                        error={inputErrors.username}
                    />
                    <FlexBox>
                        <Input
                            label={"FirstName"}
                            type="text"
                            value={formData.firstName}
                            onChange={handleChange("firstName")}
                            error={inputErrors.firstName}
                        />
                        <Input
                            label={"LastName"}
                            type="text"
                            value={formData.lastName}
                            onChange={handleChange("lastName")}
                            error={inputErrors.lastName}
                        />
                    </FlexBox>
                    <Input
                        label={"Email"}
                        type="email"
                        value={formData.email}
                        onChange={handleChange("email")}
                        error={inputErrors.email}
                    />
                    <StyledSubmit
                        variant="contained"
                        content={loading ? "Submitting..." : "Submit"}
                        type="submit"
                        disabled={loading}
                    />
                </form>
            </Container>
        </AdminSideBar>
    )
}

export default AdminUserAdd