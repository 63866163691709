import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Button from "@mui/material/Button";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useSelector } from "react-redux";
import {
  GridColumn,
  ChecklistCategoryColumn,
  PolicyColumn,
  ComplianceColumn,
  RetiredPPColumn,
} from "../Columns/index";
import { Iron } from "../../styles/Colors";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import DefaultColumn from "../Columns/DefaultColumn/DefaultColumn";
import { ReusableTypeReturn } from "../../utils/helperUtils";

const StyleButton = styled(Button)`
  background-color: ${Iron};
  color: black;
  &:hover {
    background-color: ${Iron};
    color: black;
  }
`;

const MainPageContent = () => {
  const { instance } = useMsal();
  const showPoliciesColumn = useSelector(
    (state) => state.UIState.showPoliciesColumn
  );
  const showCompliancesColumn = useSelector(
    (state) => state.UIState.showCompliancesColumn
  );
  const showRetiredPolicyProceduresColumn = useSelector(
    (state) => state.UIState.showRetiredPolicyProceduresColumn
  );

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  const noColumnsVisible =
    !showPoliciesColumn &&
    !showCompliancesColumn &&
    !showRetiredPolicyProceduresColumn;
  const selectedChecklistCategoryId = useSelector(
    (state) => state.checklist.selectedChecklistCategoryId
  );
  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <AuthenticatedTemplate>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={3} className="sidebar_sec">
            <ChecklistCategoryColumn />
          </Grid>

          {showPoliciesColumn && (
            <Grid item xs>
              <GridColumn columnTitle="Policy">
                <PolicyColumn />
              </GridColumn>
            </Grid>
          )}
          {showCompliancesColumn && (
            <Grid item xs>
              <GridColumn columnTitle="Compliance">
                <ComplianceColumn />
              </GridColumn>
            </Grid>
          )}
          {showRetiredPolicyProceduresColumn && (
            <Grid item xs>
              <GridColumn columnTitle="Retired P&P">
                <RetiredPPColumn />
              </GridColumn>
            </Grid>
          )}

          {noColumnsVisible && (
            <Grid
              item
              xs={ReusableTypeReturn(selectedChecklistCategoryId, 9, 12)}
            >
              <DefaultColumn
                description="Tucson Pathology Associates, P.C."
                year="2023 - 2024"
                subTitle="CAP Inspection System"
                height={ReusableTypeReturn(
                  selectedChecklistCategoryId,
                  "70%",
                  "100%"
                )}
              />
            </Grid>
          )}
        </Grid>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography color="black">Please log in to see this content</Typography>
        <StyleButton onClick={handleLogin}>Log In with Azure</StyleButton>
      </UnauthenticatedTemplate>
    </Box>
  );
};

export default MainPageContent;
