
export const policy = [
    {
        number: '1.2',
        message: 'Specimen Collection, Handling, and Reporting'
    }
]

export const policyColumn = [
    {
        number: '1.2.1',
        message: 'GEN.40016 (Specimen Collection Procedure..'
    },
    {
        number: '1.2.1',
        message: 'GEN.40016 (Specimen Collection Procedure..'
    }
]


export const compliance = [
    'Add New Compliance',
    'Add a Linked',
    'Compliance',
    'Import Compliance',
    'Add a Folder'
];

export const radioData = [
    { value: "path", label: "Enter document path",id:1 },
    { value: "content", label: "Enter document contents",id:2 }
  ];
   

  export const ComplianceTableColumns = ["Name", "Color"]
  export const DocumentTableColumns = ["Name", "Path", "Content"]

export const ChecklistSubCrud=["Add sub checklist","Edit checklist", "Delete checklist"]
export const PolicySubCrud=["Edit Policy", "Delete Policy","Share Policy"]
export const ComplianceSubCrud=["Edit Compliance", "Delete Compliance","Move To Retired","Share Compliance"]
export const ChecklistSubSubCrud=["Edit Sub Checklist", "Delete Sub Checklist"]
export const RetiredSubCrud=["Edit Retired", "Delete Retired"]

export const signatureOptions = [
    { value: 'sign', label: 'Sign here' },
    { value: 'upload', label: 'Upload Signature' }
  ];
export const policyOptions = [
    'Add New Policy',
    'Add a Linked Policy',
    'Import a Policy',
];
export const complianceOptions = [
    'Add New Compliance',
    'Add a Linked Compliance',
    'Import Compliance',
    'Add a Folder',
];
export   const AdvanceSearchOptions = [
    { name: "checklist", key: "Checklist" },
    { name: "policy", key: "Policy" },
    { name: "compliance", key: "Compliance" },
    { name: "retired", key: "Retired" },
];

export const AdminTableUserColumns = ["Username", "FirstName", "LastName", "Email","Role"]
