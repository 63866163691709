import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styled from '@emotion/styled';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadCrum = styled.div`
  margin-bottom: 20px;
`;

const BreadCrumComponent = ({ breadcrumbs }) => {
  return (
    <StyledBreadCrum>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs?.map((breadcrumb, index) => (
          <Typography
            key={index}
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {breadcrumb.label === 'Home' ? (
              <>
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {breadcrumb.label}
              </>
            ) : (
              breadcrumb.label
            )}
          </Typography>
        ))}
      </Breadcrumbs>
    </StyledBreadCrum>
  );
};

export default BreadCrumComponent;
