import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "45124d98-2b1f-425e-9239-906876d69094", // Client ID
    authority: "https://login.microsoftonline.com/81a4db74-dfac-4007-9c13-2a08caac94d8", // Tenant ID of the React.JS App Registration
    redirectUri: window.location.origin
      ? window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
      : "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginRequest = {
  scopes: ["api://015ad394-2966-497d-8d19-8f82397061b3/API.Access"],
  extraScopesToConsent: ["api://0c2dab65-d57a-4196-8724-3f5cd18600d5/API.Access"],
  redirectUri: window.location.origin
    ? window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "")
    : "http://localhost:3000",
};

export const capLoginApiRequest = {
  scopes: ["api://015ad394-2966-497d-8d19-8f82397061b3/API.Access"],
};

export const lisLoginApiRequest = {
  scopes: ["api://0c2dab65-d57a-4196-8724-3f5cd18600d5/API.Access"],
};
