import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checklistActions, getPolicyLists } from "../../../store/checklist-slice";
import { PostLinkedPolicylist } from "../../../services/checklistService";
import ModalWrapper from "../ModalWrapper";
import { errorToast, successToast } from "../../Toast/Toaster";
import AutoCompleteSearch from "../../SearchBox/AutoCompleteSearch";

const PolicyLinkedDropdown = ({ open, onClose, checklistId, policiesList }) => {
  const dispatch = useDispatch();
  const { selectedChecklistId, SelectedLinkedPolicy } = useSelector(state => state.checklist);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({ selectedOptions: "" });
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, [dispatch, selectedChecklistId, SelectedLinkedPolicy]);

  const fetchData = async () => {
    const res = await dispatch(getPolicyLists());
    if (res?.payload?.data) {
      const fetchedPolicies = res.payload.data;
      const filteredPolicies = fetchedPolicies?.filter(
        fetchedPolicy => !policiesList?.some(policy => policy.id === fetchedPolicy.id)
      );
      setNames(filteredPolicies);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      const response = await PostLinkedPolicylist(checklistId, selectedOptions.id);
      if (response && response.error) {
        errorToast("Failed to link policy, please try again!");
      } else {
        dispatch(checklistActions.setSelectLinkPolicyList(response));
        successToast("Linked Policy added successfully!");
        setSelectedOptions([]);
        onClose();
      }
    } catch (error) {
      errorToast("Failed to link policy list, please try again!");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    if (selectedOptions?.length === 0) {
      errors.selectedOptions = "This field is required";
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };
  const handleChangeSearch = (event, value) => {
    event.preventDefault();
    setSelectedOptions(value)
    setInputErrors({ ...inputErrors, selectedOptions: "" });
  };

  const handleModalClose = () => {
    setSelectedOptions([])
    setLoading(false)
    setInputErrors({ selectedOptions: "" });
    onClose()
  }
  return (
    <ModalWrapper
      open={open}
      onClose={handleModalClose}
      loading={loading}
      heading={"Linked Policy List"}
      handleSave={handleSubmit}
    >
      <AutoCompleteSearch
        handleChange={handleChangeSearch}
        searchList={names}
        placeholder="Search here..."
        selectedOptions={selectedOptions}
        apiCall={fetchData}
        width="100%"
        error={inputErrors.selectedOptions}
      />
    </ModalWrapper>
  );
};

export default memo(PolicyLinkedDropdown);
