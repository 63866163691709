import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "@emotion/styled";
import { Iron } from "../../styles/Colors";

const StyledComplete = styled(Autocomplete)`
  width: ${({ width }) => width || "300px"};
  background-color: white;
  color: black;
  border-color: ${Iron};
  z-index: 999;
  margin-right: 8px;
  & .MuiOutlinedInput-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${Iron};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${Iron};
    }
  }
`;

const AutoCompleteSearch = ({
  apiCall,
  placeholder = "",
  searchList,
  handleChange,
  loading = false,
  selectedOptions,
  width = "300px",
  error = false,
}) => {
  return (
    <StyledComplete
      width={width}
      value={selectedOptions?.name}
      disablePortal
      loading={loading}
      loadingText={<div>Loading...</div>}
      id="combo-box-demo"
      options={searchList}
      getOptionLabel={(option) => option.name}
      noOptionsText={<div>No data found</div>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: false,
          }}
          error={error}
          helperText={error}
          onClick={apiCall}
        />
      )}
      onChange={handleChange}
    />
  );
};
export default memo(AutoCompleteSearch);
