import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
const StyledDatePicker=styled(DatePicker)`
width: 100%;
margin:16px 0;
& .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: black;
    }
  }
  & .MuiInputLabel-root {
    color: black;
  }
`
function DatePickerComponent({selectedDate,setSelectedDate,label}) {
    
    const handleChange = (newDate) => {
        setSelectedDate(newDate);
      };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                    label={label}
                    value={selectedDate|| null}
                    onChange={(newDate) => handleChange(newDate)}
                    views={['year', 'month', 'day']}
                    displayWeekNumber={true}
                    blackuceAnimations={true}
                    format="MM/DD/YYYY"
                    textField={(props) => <TextField {...props} error={false} />}
                />
        </LocalizationProvider>
    );
}

export default DatePickerComponent;
