import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ isLoggedIn }) => {
    const userResponse = useSelector((state) => state.UIUser.userResponse);
    const userRole = userResponse?.groupMembers ? userResponse?.groupMembers[0].groupName : "user"
   
    return isLoggedIn && userRole==="Administrators"? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute