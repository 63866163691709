import React, { useCallback, useState, memo } from 'react';
import ReusableList from '../reusablelist/ReusableList';
import { useDispatch } from "react-redux";
import { PolicySubCrud } from '../../constant/Constant';
import AddPolicyModal from '../Modals/AddPolicyModal/AddPolicyModal';
import { checklistActions, deletePolicy } from '../../store/checklist-slice';
import { errorToast, successToast } from '../Toast/Toaster';
import AreYouSure from '../Modals/Confirm/AreYouSure';
import { UISearchActions } from '../../store/seach-state-slice';
import { UIStateActions } from '../../store/ui-state-slice';
import ViewPolicyModal from '../Modals/viewpolicyModal/ViewPolicyModal';
import useUserRole from '../../customHooks/useUserRole';
import NoResult from '../NoResult/NoResult';

const AdvanceSearchPolicy = ({ data, updateData, close, searchValue }) => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [modal, setModal] = useState({ bool: false, type: "", data: {} });
  const [loading, setLoading] = useState(false);
  const [openPolicy, setOpenPolicy] = useState({ id: {}, bool: false });

  const handleModal = useCallback((type, dataaaa) => {
    setModal({ bool: true, type, data:dataaaa });
  }, []);

  const resetModal = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (modal.data.id) {
      const response = await dispatch(deletePolicy(modal.data.id));
      responseHandler(response, modal.data.name);
      if (!response.error) {
        await updateData();
      }
    }
    setLoading(false);
  };

  const responseHandler = (response, name) => {
    if (!response.error) {
      successToast(`${name} deleted successfully`);
      resetModal();
    } else {
      errorToast(response.error.message);
    }
  };


  const handleCompliance = (e, singlePoliccy) => {
    e.stopPropagation();
    dispatch(UISearchActions.setPolicyID({ policyID: singlePoliccy }));
    dispatch(UIStateActions.setPoliciesColumnVisibility(true));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
    dispatch(checklistActions.setSelectedChecklistCategory(null));
    dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
    dispatch(checklistActions.setSelectedChecklistId(null));
    close()
  }
  const handleRetired = (e) => {
    e.stopPropagation();
    close()
  }
  const handlePolicyView = (event, policy) => {
    event.stopPropagation();
    setOpenPolicy({ id: policy, bool: true });
  };
  const handlePolicyClose = () => setOpenPolicy({ id: {}, bool: false });

  const searchData = searchValue ? data.filter((item) => item.name?.toLowerCase().includes(searchValue?.toLowerCase())) : data
  return (
    <>
      {isAdmin &&
        <ViewPolicyModal
          open={openPolicy.bool}
          onClose={handlePolicyClose}
          policyData={openPolicy.id}
          updateData={updateData}
        />}
      {modal.type === "Edit Policy" && (
        <AddPolicyModal
          open={modal.bool}
          onClose={resetModal}
          type="Edit"
          data={modal.data}
          updateData={updateData}
        />
      )}

      {modal.type === "Delete Policy" && (
        <AreYouSure
          open={modal.bool}
          onClose={resetModal}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal.data.name}?`}
          loading={loading}
        />
      )}

      {searchData.length > 0 ? (
        searchData.map((item, index) => (
          <ReusableList
            key={index}
            data={item}
            isButton
            isAdmin={isAdmin}
            menuItems={PolicySubCrud}
            onHamburgerClick={handleModal}
            onClickCompliance={(e) => handleCompliance(e, item)}
            onClickRetired={(e) => handleRetired(e)}
            onClickContainer={(e) => handlePolicyView(e, item)}
            boxColor={item?.color}
          />
        ))
      ) : (
        <NoResult/> 
      )}

    </>
  );
};

export default memo(AdvanceSearchPolicy);
