import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ChecklistSubCrud } from "../constant/Constant";

export const getParentState = (openParent) => {
  return openParent ? true : undefined;
}

export const getIcon = (subCheckListData, state) => {
  if (subCheckListData?.length > 0) {
    if (state) {
      return <ArrowDropDownIcon color={'black'} />
    }
    else {
      return <ArrowDropUpIcon color={'black'} />
    }
  }
  else {
    return
  }
}

export const typeCheck = (datatype)=>{
  if(datatype==="ChecklistCategory"){
    return false;
  }
  else if(datatype==="Checklist"){
    return false;
  }
  else {
    return true;
  }
}
export const typeCheckOptions = (datatype)=>{
  if(datatype==="ChecklistCategory"){
    return ;
  }
  else if(datatype==="Checklist"){
    return ChecklistSubCrud;
  }
  else {
    return ;
  }
}


export const ReusableTypeReturn=(isBool,Condition1,Condition2)=>{
return isBool?Condition1:Condition2;
}

export const ReusableTBoolReturn=(Condition1,Condition2)=>{
  return Condition1===Condition2?true:false;
  }
  

export const formatDate = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export  const findChecklistById = (checklists, selectedChecklistId) => {
  // Check if the main checklist matches the selectedChecklistId
  const mainChecklist = checklists.find(item => item.id === selectedChecklistId);
  
  if (mainChecklist) {
    return mainChecklist;
  }

  // Check in the main checklist items
  for (const checklist of checklists) {
    const mainItem = checklist.items?.find(item => item.id === selectedChecklistId);
    if (mainItem) {
      return mainItem;
    }
  }

  // Check in the subtitles and their items
  for (const checklist of checklists) {
    for (const subtitle of checklist.subtitles || []) {
      if (subtitle.id === selectedChecklistId) {
        return subtitle;
      }
      const subItem = subtitle.items?.find(item => item.id === selectedChecklistId);
      if (subItem) {
        return subItem;
      }
    }
  }

  return null; // Return null if no matching id is found
};