import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addChecklist,
  fetchChecklists,
} from "../../../store/checklist-slice";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { errorToast, successToast } from "../../Toast/Toaster";

const AddNewItem = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, updateData, label,initialData} = props;
  const [loading, setLoading] = useState(false);
  const [checklistData, setChecklistData] = useState({ name: "" });
  const [inputErrors, setInputErrors] = useState({ name: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const checklistDataAdd = {
        "name": checklistData.name,
        "parentId": initialData.id,
        "checklistCategoryId": initialData.checklistCategoryId,
        "title": false
      };
    try {
      const res = await dispatch(addChecklist(checklistDataAdd));
      if (res.type === "addChecklist/fulfilled") {
        successToast("New item added successfully!");
        setChecklistData({ name: "" });
        await dispatch(fetchChecklists(res?.payload?.checklistCategoryId));
        onClose();
        if (updateData) {
          await updateData();
        }
      } else {
        errorToast("failed to add new item");
      }
    } catch (error) {
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { name: "" };
    if (!checklistData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    } else if (checklistData.name?.length > 260) {
      errors.name = `Checklist Name must be 260 characters or fewer. Currently ${checklistData.name?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };

  const closeModal = () => {
    onClose();
    setChecklistData({ name: "" });
    setInputErrors({ name: "" });
  };

  return (
    <>
      <ModalWrapper
        open={open}
        onClose={closeModal}
        loading={loading}
        heading={label}
        handleSave={handleSubmit}
      >
        <Input
          label={label}
          type="text"
          value={checklistData.name}
          onChange={(e) => {
            setChecklistData({ ...checklistData, name: e.target.value });
            setInputErrors({ name: "" });
          }}
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddNewItem;
