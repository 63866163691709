import React from "react";
import { Link, Box, Typography, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
      marginBottom: "2.0rem",
    },
    h3: {
      fontSize: "3.0rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "2.0rem",
      fontWeight: 600,
    },
  },
});

const DefaultColumn = ({ description, year, subTitle,height }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height,
          textAlign: 'center',
          flexDirection: "column",
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Link to="/" underline="none">
            <img src="logo.jpg" width="150px" alt="logo" />
          </Link>
        </Box>
        <Typography component="h1" variant="h5" align="center">
          {description}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography component="h1" variant="h4" align="center">
            {year}
          </Typography>
          <Typography component="h1" variant="h3" align="center">
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DefaultColumn;
