import React, { useEffect, useState } from "react";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { addFolder } from "../../../store/checklist-slice";
import { useDispatch } from "react-redux";
import { successToast } from "../../Toast/Toaster";
import { PostEditFolder, PostFolderLinkPolicy } from "../../../services/checklistService";
import { ReusableTypeReturn } from "../../../utils/helperUtils";

const AddFolder = ({ open, onClose, type, policyId, fetchData, folderIddd, data }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ name: "" });
    const [inputErrors, setInputErrors] = useState({ name: "" });


    useEffect(() => {
        if (data?.name) {
            setFormData((prevData) => ({
                ...prevData,
                name: data.name
            }));
        }
    }, [data?.name]);

    const handleChange = fieldName => event => {
        const { value } = event.target;
        setFormData(prevData => ({ ...prevData, [fieldName]: value }));
        setInputErrors(prevErrors => ({ ...prevErrors, [fieldName]: "" }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
        setLoading(true);

        try {
            let res;
            if (type === "Add") {
                res = await dispatch(addFolder(formData));
                const folderId = res.payload.id;
                await PostFolderLinkPolicy(policyId, folderId);
            } else {
                res = await PostEditFolder(folderIddd, formData);
                await fetchData(policyId);
            }
            if (res) {
                successToast(`Folder ${type}ed successfully`);
                setFormData({ name: "" });
                onClose();
                await fetchData(policyId);
            } else {
                console.error("Invalid response structure:", res);
            }
        } catch (error) {
            console.error("Error in handleSubmit:", error);
        } finally {
            setLoading(false);
        }
    };


    const validateForm = () => {
        const errors = {};
        let isValid = true;
        if (!formData.name.trim()) {
            errors.name = "This field is required";
            isValid = false;
        }
        setInputErrors(errors);
        return isValid;
    };

    return (
        <ModalWrapper
            open={open}
            onClose={onClose}
            heading={ReusableTypeReturn(type === "Add", "Add New Folder", "Edit Folder")}
            handleSave={handleSubmit}
            loading={loading}
        >
            <Input
                label={ReusableTypeReturn(type === "Add", "New Folder Name", "Edit Folder Name")}
                type="text"
                value={formData.name}
                onChange={handleChange("name")}
                error={inputErrors.name}
            />
        </ModalWrapper>
    );
};

export default AddFolder;
