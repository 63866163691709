import styled from "@emotion/styled";
import { useState } from "react";
import { MenuItem, Menu, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LinkIcon from "@mui/icons-material/Link";
import ShareIcon from "@mui/icons-material/Share";
import { Eminence, FrenchBlue, Iron } from "../../styles/Colors";
import { ReusableTypeReturn } from "../../utils/helperUtils";

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  background-color: ${({ color }) => color} !important;
  border: 1px solid ${Iron};
`;

const BaseButtonStyle = `
  text-transform: capitalize !important;
  font-size: 11px !important;
  outline:none;
  border: none;
  color: white;
  padding: 8px 6px;
  width:fit-content;
  cursor: pointer;
`;

const StyledButton = styled.button`
  ${BaseButtonStyle}
  background-color: ${({ color }) => color} !important;
  margin-right: 8px;
  font-weight: bold;
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor} !important;
  }
`;
const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ dotColor }) => dotColor};
  border-radius: 50%;
`;

const ReusableList = ({
  menuItems,
  isButton = false,
  isLinked = false,
  onClickCompliance,
  onClickRetired,
  onClickContainer,
  isDot = false,
  onHamburgerClick,
  data,
  isAdmin,
  boxColor,
  children,
  isDotColor,
}) => {
  const [checklistAnchorEl, setChecklistAnchorEl] = useState(null);

  const handleChecklistMenuClick = (event) => {
    event.stopPropagation();
    setChecklistAnchorEl(event.currentTarget);
  };

  const handleChecklistMenuClose = (event, type, val) => {
    event.stopPropagation();
    setChecklistAnchorEl(null);
    onHamburgerClick && onHamburgerClick(type, val);
  };

  return (
    <div>
      <ListContainer color={boxColor} onClick={onClickContainer}>
        <div>{data?.name}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isButton && (
            <div>
              <StyledButton
                color={Eminence}
                hoverColor="#6a0dc1"
                onClick={onClickCompliance}
              >
                Compliance
              </StyledButton>
              <StyledButton
                color={FrenchBlue}
                hoverColor="rgb(0, 78, 107)"
                onClick={onClickRetired}
              >
                Retired
              </StyledButton>
            </div>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>{isDot && <Dot dotColor={isDotColor} />}</Box>
            <Box>
              {isAdmin && (
                <>
                  <IconButton
                    aria-controls="checklist-menu"
                    aria-haspopup="true"
                    onClick={handleChecklistMenuClick}
                    sx={{ padding: "0 !important" }}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                  <Menu
                    id="checklist-menu"
                    anchorEl={checklistAnchorEl}
                    keepMounted
                    open={Boolean(checklistAnchorEl)}
                    onClose={handleChecklistMenuClose}
                  >
                    {menuItems?.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={(e) => handleChecklistMenuClose(e, item, data)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Box>
            <Box>
              {isLinked && (
                <div>
                  <LinkIcon />
                </div>
              )}
            </Box>

            <Box>
              {ReusableTypeReturn(
                isLinked,
                <ShareIcon fontSize="small" />,
                <></>
              )}
            </Box>
          </Box>
        </div>
      </ListContainer>
      <div>{children}</div>
    </div>
  );
};

export default ReusableList;
