import React, { useEffect, useState, memo } from "react";
import ModalWrapper from "../ModalWrapper";
import { LinkPolicyCompliance, getComlianceLists } from "../../../store/checklist-slice";
import { useDispatch } from "react-redux";
import { errorToast, successToast } from "../../Toast/Toaster";
import AutoCompleteSearch from "../../SearchBox/AutoCompleteSearch";

const ConfirmationModal = ({ open, onClose, policyId, complianceList }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({ selectedOptions: "" });
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {

    fetchComplianceLists();
  }, [dispatch]);
  const fetchComplianceLists = async () => {
    try {
      const response = await dispatch(getComlianceLists());
      if (response?.payload?.data) {
        const fetchedCompliances = response.payload.data;
        const filteredCompliances = fetchedCompliances?.filter(
          fetchedCompliance => !complianceList?.some(compliance => compliance.complianceId === fetchedCompliance.id)
        );
        setData(filteredCompliances);
      }
    } catch (error) {
      console.error("Error fetching compliance lists:", error);
    } 
  };
  const handleClose = () => {
    onClose();
    setSelectedOptions([]);
  };

  const handleAddNewLinkedCompliance = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(LinkPolicyCompliance({ policyId, complianceId: selectedOptions?.id }));
      if (response.payload.id) {
        successToast(`Compliance linked successfully...`);
        setSelectedOptions([]);
        onClose();
      }
    } catch (error) {
      errorToast("Error adding compliance. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (selectedOptions?.length === 0) {
      errors.selectedOptions = "This field is required";
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };


  const handleChangeSearch = (event, value) => {
    event.preventDefault();
    setSelectedOptions(value)
    setInputErrors({ ...inputErrors, selectedOptions: "" });
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      loading={loading}
      heading={"Linked Compliance List"}
      handleSave={handleAddNewLinkedCompliance}
    >
      <AutoCompleteSearch
        handleChange={handleChangeSearch}
        searchList={data}
        placeholder="Search here..."
        selectedOptions={selectedOptions}
        loading={loading}
        apiCall={fetchComplianceLists}
        width="100%"
        error={inputErrors.selectedOptions}
      />
    </ModalWrapper>
  );
};

export default memo(ConfirmationModal);
