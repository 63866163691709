import { Editor } from '@tinymce/tinymce-react';
import ErrorMessage from './ErrorMessage';
import { useState, useEffect,memo } from 'react';

 const TinyMCEEditor=({ DocContent, onChange, error })=>{
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleEditorChange = (content) => {
        onChange(content);
    }

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <Editor
                    onInit={() => {
                        setLoading(false);
                    }}
                    onEditorChange={handleEditorChange}
                    value={DocContent}
                    apiKey="am67fmrr9dw7mwcpdbjk7ixksuke4cdx3of7euj9111udyx2"
                    init={{
                        width: "100%",
                        selector: 'textarea', 
                        height: 842,
                        menubar: true,
                        highlight_on_focus: false,
                        plugins: [
                            'image code', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                            'emoticons','directionality'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic strikethrough forecolor backcolor | link image media | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | removeformat | help ltr rtl',
                        content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; width: 800px; height: 100vh; margin: 20px auto; background: white; padding: 20px; box-sizing: border-box; border-width: 1px 1px 0 1px; border-style: solid; border-color: #ccc; outline: none; }',
                        statusbar: true,
                        branding: false,
                        image_uploadtab: true,
                        images_file_types: "jpeg,jpg,png",
                        images_upload_handler: (blobInfo) => {
                            const base64str =
                                "data:" +
                                blobInfo.blob().type +
                                ";base64," +
                                blobInfo.base64();
                            return Promise.resolve(base64str);
                        },
                    }}
                />
            )}
            {error && <ErrorMessage error={error} />}
        </>
    );
}
export default memo(TinyMCEEditor);