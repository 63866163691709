import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./custom.scss";
import "./index.css";
import App from "./App";
import store from "./store/index";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, capLoginApiRequest, lisLoginApiRequest } from "./authConfig";
import axios from "axios";

const msalInstance = new PublicClientApplication(msalConfig);

axios.defaults.baseURL = process.env.REACT_APP_CAP_API_BASE_URL;
axios.interceptors.request.use(
  async (response) => {
    if (response.baseURL === process.env.REACT_APP_CAP_API_BASE_URL) {
      const account = msalInstance.getAllAccounts()[0];
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...capLoginApiRequest,
        account: account,
      });
      response.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
    } else if (response.baseURL === process.env.REACT_APP_LIS_API_BASE_URL) {
      const account = msalInstance.getAllAccounts()[0];
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...lisLoginApiRequest,
        account: account,
      });
      response.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MsalProvider>
);
