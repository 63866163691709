import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { LinkDocCompliance, LinkPolicyCompliance, addDocument } from "../../../store/checklist-slice";
import { errorToast, successToast } from "../../Toast/Toaster";
import { PostCompliance, PutCompliance } from "../../../services/checklistService";
import { ReusableTypeReturn } from "../../../utils/helperUtils";

const AddComplianceModal = ({ open, onClose, policyId, data, type,updateData,isRetired}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: ""
  });
  const [inputErrors, setInputErrors] = useState({ name: "" })
  const text=isRetired?"Retired":"Compliance"

  useEffect(() => {
    if (data?.name) {
      setFormData((prevData) => ({ ...prevData, name: data.name }));
    }
  }, [data?.name]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      type !== "Edit"? await handleAddCompliance():await handleEditCompliance();
      onClose();
    } catch (error) {
      errorToast(`Failed to ${text}, please try again!`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCompliance = async () => {
    const addComplianceRes = await PostCompliance({ name: formData.name });
    const complianceId = addComplianceRes.id;

    const DocData = {
      name: "Doc-" + formData.name.replaceAll(" ", ""),
      path: "user/document/" + formData.name.replaceAll(" ", "") + "/test.pdf",
      content: ""
    };
    const responseDoc = await dispatch(addDocument(DocData));
    const docId = responseDoc.payload.id;

    await dispatch(LinkPolicyCompliance({ policyId, complianceId }));
    await dispatch(LinkDocCompliance({ complianceId, docId }));

    successToast(`Compliance added successfully...`);
    setFormData({ name: "" });
  };

  const handleEditCompliance = async () => {
    await PutCompliance(data.id, { name: formData.name });
    successToast(`${text} edited successfully...`);
    if (updateData) {
      await updateData();
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    }
    else if (formData.name?.length > 260) {
      errors.name = `${text} Name must be 260 characters or fewer. Currently ${formData.name?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };
  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    setInputErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleClose=()=>{
    setFormData({name: ""})
    setInputErrors({ name: "" })
    onClose()
  }
  return (
    <>
      <ModalWrapper
        open={open} 
        onClose={handleClose}
        loading={loading}
        heading={ReusableTypeReturn(type!=="Edit", `Add New ${text}`, `Edit ${text}`)}
        handleSave={handleSubmit}
      >
        <Input
          label={ReusableTypeReturn(type!=="Edit", `New ${text} Name`, `Edit ${text} Name`)}
          type="text"
          value={formData.name}
          onChange={handleChange("name")}
          name="name"
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddComplianceModal;
