import React, { useEffect, useState } from "react";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { errorToast, successToast } from "../../Toast/Toaster";
import { addChecklist, editChecklist } from "../../../store/checklist-slice";
import { useDispatch } from "react-redux";
import { UIStateActions } from "../../../store/ui-state-slice";

const AddsubcheckListModal = ({ onClose, open, type, heading, data,updateData,label}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: ""
  });
  const [inputErrors, setInputErrors] = useState({ name: "" })

  useEffect(() => {
    if (data?.name && type !== "Add") {
      setFormData((prevData) => ({
        ...prevData,
        name: data.name
      }));
    }
  }, [data?.name]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let subcChecklistAddData = {
      "name": formData.name,
      "parentId": data?.id,
      "checklistCategoryId": data?.checklistCategoryId,
      "title":true
    }
    let subcChecklistEditData = {
      "name": formData.name,
      "parentId": data?.parentId,
      "checklistCategoryId": data?.checklistCategoryId
    }
    try {
      let response;
      if (type === "Edit") {
        response = await dispatch(editChecklist({ id: data?.id, data: subcChecklistEditData }));
      } else {
        response = await dispatch(addChecklist(subcChecklistAddData));
        setFormData({
          name: ""
        });
        dispatch(UIStateActions.setPoliciesColumnVisibility(false));
        dispatch(UIStateActions.setCompliancesColumnVisibility(false));
        dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
      }
      if (response?.payload.id) {
        successToast(`New sub title added successfully...`);
      }
      else if (response?.type === "editChecklist/fulfilled") {
        successToast(`New sub title edited successfully...`);
      }
      if(updateData){
        updateData()
      }
      onClose();
    } catch (error) {
      errorToast("Failed to add sub checklist, please try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    }
    else if (formData.name?.length > 260) {
      errors.name = `Policy Name must be 260 characters or fewer. Currently ${formData.name?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };
  return (
    <>
      <ModalWrapper
        open={open} onClose={onClose}
        loading={loading}
        heading={heading}
        handleSave={handleSubmit}
      >
        <Input
          label={label}
          type="text"
          value={formData.name}
          onChange={handleChange("name")}
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddsubcheckListModal;
