import axios from "axios";

export const GetAddOns = async () => {
  const options = {
    method: "GET",
    url: "AddOn/AddOns",
    baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
  };
  return await axios.request(options);
};
