import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export default function Footer() {
  return (
    <Paper
      sx={{
        bottom: "0px",
        width: "100%",
        position: "fixed",
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        >
          <img src="/logo.jpg" width={75} height={30} alt="Logo" />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
          }}
        >
          <Grid container spacing={1} columns={1} justifyContent="center">
            <Grid item xs={12} align="center">
              <Typography variant="caption" color="initial" display="block">
                Copyright ©{new Date().getFullYear().toString()}. Tucson
                Pathology Associates
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
}
