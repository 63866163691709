import React, { useState, useEffect, memo } from 'react';
import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import AdminSideBar from '../components/AdminComponents/AdminSideBar';
import { AdminTableUserColumns } from '../constant/Constant';
import { getUsersList } from '../services/usersList';
import { getUserRolesList } from '../services/userRoleList';
import { postLinkUserGroup } from '../services/userGroupLinking';
import { errorToast, successToast } from '../components/Toast/Toaster';

const Container = styled.div`
  height: 100vh;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Admin = () => {
  const [usersList, setUsersList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchUserRoles();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const usersResponse = await getUsersList();
      if (usersResponse) {
        setUsersList(usersResponse.data);
      }
    } catch (error) {
      console.error("Failed to fetch users", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRoles = async () => {
    setLoading(true);
    try {
      const userRolesResponse = await getUserRolesList();
      if (userRolesResponse) {
        setUserRoleList(userRolesResponse.data);
      }
    } catch (error) {
      console.error("Failed to fetch user roles", error);
    } finally {
      setLoading(false);
    }
  };

 

  const handleRoleChange = async (userId, roleId) => {
    const response = await postLinkUserGroup(roleId, userId);
    if (response.status === 200) {
      successToast("User role changed successfully...");
      fetchUsers();
    } else {
      errorToast("Something went wrong...");
    }
  };

  return (
    <AdminSideBar heading="Admin">
      <Container>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={300} />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {AdminTableUserColumns.map((column, index) => (
                    <StyledTableCell key={index}>{column.toUpperCase()}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList?.map((user) => (
                  <StyledTableRow key={user.username}>
                    <StyledTableCell component="th" scope="row">
                      {user.username}
                    </StyledTableCell>
                    <StyledTableCell>{user.firstName}</StyledTableCell>
                    <StyledTableCell>{user.lastName}</StyledTableCell>
                    <StyledTableCell>{user.email}</StyledTableCell>
                    <StyledTableCell>
                      <select
                        name="role"
                        id={`role-${user.username}`}
                        value={user.groupMembers ? user.groupMembers[0].groupId : ""}
                        onChange={(e) => handleRoleChange(user.id, e.target.value)}
                      >
                        <option value="" disabled>
                          Choose Role
                        </option>
                        {userRoleList?.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name?.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </AdminSideBar>
  );
};

export default memo(Admin);
