import React, { useCallback, useEffect, useState, useMemo } from "react";
import classes from "./ChecklistCategory.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChecklists,
  checklistActions,
  deleteChecklist,
  checkListColor,
  addChecklist,
} from "../../../store/checklist-slice";
import { UIStateActions } from "../../../store/ui-state-slice";
import { Box, TextField, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { PermissionLevel } from "../../../enums/PermissionLevel";
import AddChecklistModal from "../../Modals/AddChecklistModal/AddChecklistModal";
import AreYouSure from "../../Modals/Confirm/AreYouSure";
import { errorToast, successToast } from "../../Toast/Toaster";
import AddsubcheckListModal from "../../Modals/AddSubcheckListModal/AddsubcheckListModal";
import { UISearchActions } from "../../../store/seach-state-slice";
import useUserRole from "../../../customHooks/useUserRole";
import { FreshGreen, Seashell } from "../../../styles/Colors";
import styled from "@emotion/styled";
import { ReusableTBoolReturn } from "../../../utils/helperUtils";
import AddNewItem from "../../Modals/AddNewItem/AddNewItem";
import Input from "../../Inputs/Input";
import { StyleButtonClose, StyleButtonSubmit } from "../../Modals/ModalWrapper";
import ReusableChecklist from "../../reusableChecklist/ReusableChecklist";

const StyledChecklistName = styled.div`
  color: black;
  text-align: center;
  border-bottom: 1px solid #d4d7d9;
  padding: 8px !important;
  text-transform: uppercase;
  font-size: 14px;
`;

const StyledChecklistTitle = styled.h1`
  color: ${FreshGreen};
  text-align: center;
  border-color: rgba(0, 0, 0, 0.12);
`;

const StyledGrid = styled(Grid)`
  position: absolute;
  left: 2%;
  top: 0px;
  transform: translate(350px, 52px);
  margin: 0px;
  @media (max-width: 1440px) {
    transform: translate(370px, 52px);
    left: 0%;
  }

  @media (min-width: 1600px) and (max-width: 2560px) {
    transform: translate(655px, 52px);
  }
`;

const StyledAddChecklist = styled(Button)`
  background: ${Seashell};
  color: black;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  box-shadow: none;
  &:hover {
    background: ${Seashell};
    color: black;
    box-shadow: none;
  }
`;

const ChecklistCategory = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const permissionLevels = useSelector(
    (state) => state.permission.resourcePermissionLevels
  );
  const selectedChecklistId = useSelector(
    (state) => state.checklist.selectedChecklistId
  );
  const selectedChecklistCategoryId = useSelector(
    (state) => state.checklist.selectedChecklistCategoryId
  );
  const checklistCategories = useSelector(
    (state) => state.checklist.checklistCategories
  );
  const checklists = useSelector((state) => state.checklist.checklists);
  const [isEdit, setIsEdit] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ bool: false, type: "", data: {} });
  const [showInput, setShowInput] = useState(false);
  const [title, setTitle] = useState("");
  const [inputErrors, setInputErrors] = useState({ title: "" });

  useEffect(() => {
    dispatch(fetchChecklists(selectedChecklistCategoryId));
  }, [dispatch, selectedChecklistCategoryId, selectedChecklistId, modal]);
  const selectedCategory = useMemo(
    () =>
      checklistCategories?.find(
        (category) => category.id === selectedChecklistCategoryId
      ),
    [checklistCategories, selectedChecklistCategoryId]
  );

  const closeChecklistModal = () => {
    setIsChecklistModalOpen(false);
  };

  const handleDelete = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        setLoading(true);
        if (modal?.data?.id) {
          const response = await dispatch(deleteChecklist(modal?.data?.id));
          if (!response.error) {
            successToast(`${modal?.data?.name} deleted successfully`);
            setModal({ bool: false, type: "", data: {} });
            dispatch(UIStateActions.setPoliciesColumnVisibility(false));
            dispatch(UIStateActions.setCompliancesColumnVisibility(false));
            dispatch(
              UIStateActions.setRetiredPolicyProceduresColumnVisibility(false)
            );
          }
        }
      } catch (error) {
        errorToast(error.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, modal]
  );

  const handleColorAPI = async (checklistID) => {
    try {
      await dispatch(
        checkListColor({
          params: checklistID,
          color: "white",
        })
      );
      await dispatch(fetchChecklists(selectedChecklistCategoryId));
    } catch (error) {
      console.log(error);
    }
  };
  const handleModal = useCallback((type, data) => {
    setModal({ bool: true, type: type, data: data });
    setIsEdit(true);
  }, []);
  const closeConfirmationModalNew = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const handleFalseGrids = useCallback(() => {
    dispatch(UIStateActions.setPoliciesColumnVisibility(true));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
  }, [dispatch]);

  const handleChecklistSelectClick = useCallback(
    (checklist) => {
      dispatch(
        checklistActions.setSelectedChecklistId(checklist.id, checklist)
      );
      dispatch(UISearchActions.setPolicyID({ policyID: {} }));
      dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
      handleFalseGrids();
      if (checklist?.color !== "white") handleColorAPI(checklist.id);
    },
    [dispatch, handleColorAPI]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const checklistDataAdd = {
      name: title,
      checklistCategoryId: selectedChecklistCategoryId,
      title: true,
    };

    try {
      const action = addChecklist;
      const payload = checklistDataAdd;
      const res = await dispatch(action(payload));
      const successMessage = "New title added successfully!";
      const errorMessage = "failed to add new title";
      if (res.type === "addChecklist/fulfilled") {
        successToast(successMessage);

        await dispatch(fetchChecklists(res?.payload?.checklistCategoryId));
        handleCloseTitleShow();
      } else {
        errorToast(errorMessage);
      }
    } catch (error) {
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { title: "" };
    if (!title?.trim()) {
      errors.title = "This field is required";
      isValid = false;
    } else if (title?.length > 260) {
      errors.title = `Title Name must be 260 characters or fewer. Currently ${title?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };

  const handleTitleShow = () => {
    setShowInput(true);
  };
  const handleCloseTitleShow = () => {
    setShowInput(false);
    setTitle("");
    setInputErrors({ title: "" });
  };
  const hasPermissions = (resourceId) => {
    return isAdmin || permissionLevels[resourceId] >= PermissionLevel.Edit;
  };
  return (
    <React.Fragment>
      {ReusableTBoolReturn(modal.type, "Edit title") && (
        <AddChecklistModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          isEdit={isEdit}
          initialData={modal?.data}
          label="Edit title"
          successMessage="title Edited added successfully"
        />
      )}
      {ReusableTBoolReturn(modal.type, "Add new item") && (
        <AddNewItem
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          initialData={modal?.data}
          label="Add new item"
        />
      )}
      {!isEdit && (
        <AddChecklistModal
          open={isChecklistModalOpen}
          onClose={closeChecklistModal}
          checklistCategoryId={selectedCategory?.id}
          label="Add New Title"
        />
      )}
      {ReusableTBoolReturn(modal.type, "Delete title") && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}
      {ReusableTBoolReturn(modal.type, "Add new sub title") && (
        <AddsubcheckListModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          heading={`Add new sub title for ${modal.data.name}`}
          type="Add"
          label="Sub title name"
          data={modal.data}
        />
      )}
      {ReusableTBoolReturn(modal.type, "Edit item") && (
        <AddsubcheckListModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          heading={`Edit item for ${modal.data.name}`}
          type="Edit"
          label="Edit item name"
          data={modal.data}
        />
      )}

      {ReusableTBoolReturn(modal.type, "Delete item") && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}

      {ReusableTBoolReturn(modal.type, "Edit sub title") && (
        <AddsubcheckListModal
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          heading={`Edit sub title for ${modal.data.name}`}
          type="Edit"
          label="Edit sub title name"
          data={modal.data}
        />
      )}
      {ReusableTBoolReturn(modal.type, "Delete sub title") && (
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModalNew}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      )}
      {selectedCategory && (
        <Box className={classes.columnCard}>
          <StyledChecklistName>Checklist</StyledChecklistName>
          <Box sx={{ margin: "10px" }}>
            {hasPermissions(selectedCategory.id) && (
              <StyledAddChecklist
                variant="contained"
                onClick={handleTitleShow}
                endIcon={<AddIcon sx={{ fontSize: "40px !important" }} />}
              >
                Add New title
              </StyledAddChecklist>
            )}
          </Box>

          {showInput && (
            <form style={{ margin: "10px" }} onSubmit={handleSubmit}>
              <Input
                label="Add New Title"
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setInputErrors({ name: "" });
                }}
                error={inputErrors.title}
              />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <StyleButtonClose onClick={handleCloseTitleShow} sx={{ mr: 2 }}>
                  Cancel
                </StyleButtonClose>
                <StyleButtonSubmit
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </StyleButtonSubmit>
              </Box>
            </form>
          )}

          <Box sx={{ margin: "10px" }}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput,
                },
              }}
              sx={{ width: "100%" }}
              placeholder="Search..."
              type="text"
              name="search"
            />
          </Box>

          <ReusableChecklist
            isAdmin={true}
            onMenuitemClick={handleModal}
            data={checklists}
            selectedChecklistCategoryId={selectedChecklistCategoryId}
            onClickContainer={handleChecklistSelectClick}
          />

          <StyledGrid>
            <StyledChecklistTitle>
              {selectedCategory?.name}
            </StyledChecklistTitle>
          </StyledGrid>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ChecklistCategory;
