import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import styled from "@emotion/styled";
import {
  Iron,
  VeniceBlue,
  Cloud,
  CuriousBlue,
  PaleOlive,
  PaleOlive1,
  PaleOlive2,
} from "../../styles/Colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UIStateActions } from "../../store/ui-state-slice";
import { useDispatch } from "react-redux";
import { ReusableTypeReturn } from "../../utils/helperUtils";

const StyledIcon = styled(ListItemIcon)`
  min-width: unset;
`;

const baseStyles = (bgColor) => ({
  backgroundColor: bgColor,
  border: `1px solid ${Iron}`,
  "&.Mui-selected, &.Mui-focusVisible, &:hover": {
    backgroundColor: bgColor,
  },
});

const StyledListItemButton = styled(ListItemButton)(
  ({ bgColor, padding, margin }) => ({
    ...baseStyles(bgColor),
    padding: padding || "10px",
    margin: margin || "0",
    marginInline: "10px",
  })
);

const StyledList = styled(List)(({ bgColor }) => ({
  listStyle: "none",
  paddingLeft: "5px",
  paddingTop: "8px",
  paddingBottom: "8px",
  backgroundColor: bgColor || "transparent", // Use bgColor if provided
  height: "calc(100vh - 400px)",
  overflowY: "auto",
  marginBottom: "0px",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
}));

const StyledListItemText = styled(ListItemText)(({ size, padding, color }) => ({
  ".MuiTypography-root": {
    fontSize: size,
    fontWeight: 500,
    padding: padding || "inherit",
    color: color || "inherit",
  },
}));

export default function ReusableChecklist({
  isAdmin,
  onMenuitemClick,
  data,
  selectedChecklistCategoryId,
  onClickContainer,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({});
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = React.useState(null);
  const [listItemMenuAnchorEls, setListItemMenuAnchorEls] = React.useState({});
  const [subListMenuAnchorEls, setSubListMenuAnchorEls] = React.useState({});
  const [clickedMain, setClickedMain] = React.useState({
    name: "",
    id: "",
    checklistCategoryId: "",
  });

  const [clickedSub, setClickedSub] = React.useState({
    name: "",
    id: "",
    checklistCategoryId: "",
  });
  const [clickedItem, setClickedItem] = React.useState({
    name: "",
    id: "",
    checklistCategoryId: "",
  });

  const [clicledActive, setClicledActive] = React.useState({
    idMain: null,
    idSub: null,
  });
  const handleClick = (id, titleData, subtitledata, type) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    dispatch(UIStateActions.setPoliciesColumnVisibility(false));
    dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
    const { id: iddd, titleName } = titleData;
    const { id: idddsub } = subtitledata;
    setClickedItem({
      name: titleName,
      id: iddd,
      checklistCategoryId: selectedChecklistCategoryId,
    });
    if (type !== "subtitle") {
      setClicledActive({
        idMain: null,
        idSub: null,
      });
    } else {
      setClicledActive({
        idMain: iddd,
        idSub: idddsub,
      });
    }
  };

  const handleMainMenuClick = (event, main) => {
    setMainMenuAnchorEl(event.currentTarget);
    if (main) {
      setClickedMain({
        name: main.titleName,
        id: main.id,
        checklistCategoryId: selectedChecklistCategoryId,
      });
    }
  };

  const handleMainMenuClose = () => {
    setMainMenuAnchorEl(null);
  };

  const handleListItemMenuClick = (event, id) => {
    setListItemMenuAnchorEls({
      ...listItemMenuAnchorEls,
      [id]: event.currentTarget,
    });
  };

  const handleListItemMenuClose = (id) => {
    setListItemMenuAnchorEls({
      ...listItemMenuAnchorEls,
      [id]: null,
    });
  };

  const handleMenuItemClick = (action, subCheckList) => {
    onMenuitemClick(action, subCheckList);
    handleMainMenuClose();
  };

  const handleSubMenuItemClick = (id, action, subCheckList) => {
    onMenuitemClick(action, subCheckList);
    handleListItemMenuClose(id);
  };

  const handleSubListMenuClick = (event, id, sub) => {
    setSubListMenuAnchorEls({
      ...subListMenuAnchorEls,
      [id]: event.currentTarget,
    });

    if (sub) {
      setClickedSub({
        name: sub.titleName,
        id: sub.id,
        checklistCategoryId: selectedChecklistCategoryId,
      });
    }
  };

  const handleSubListMenuClose = (id) => {
    setSubListMenuAnchorEls({
      ...subListMenuAnchorEls,
      [id]: null,
    });
  };

  const handleItemsData = (itemData, idMain, idSub) => {
    setClicledActive({
      idMain: idMain,
      idSub: idSub,
    });
    onClickContainer(itemData);
    setClickedItem({
      name: itemData.name,
      id: itemData.id,
      checklistCategoryId: selectedChecklistCategoryId,
    });
  };

  return (
    <StyledList
      bgColor="transparent"
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {data?.map((main, mainIndex) => (
        <React.Fragment key={mainIndex}>
          <StyledListItemButton
            onClick={() => handleClick(mainIndex, main, main, "title")}
            bgColor={ReusableTypeReturn(
              clickedItem.name === main.titleName ||
                clicledActive.idMain === main.id,
              PaleOlive,
              VeniceBlue
            )}
            margin="20px 0 0 0"
          >
            <StyledListItemText
              size="18px"
              primary={main.titleName}
              color="white"
            />
            {(main.items?.length > 0 || main.subtitles?.length > 0) &&
              (open[mainIndex] ? (
                <ArrowDropUpIcon fontSize="large" />
              ) : (
                <ArrowDropDownIcon fontSize="large" />
              ))}
            {isAdmin && (
              <StyledIcon onClick={(event) => handleMainMenuClick(event, main)}>
                <MenuIcon fontSize="small" />
              </StyledIcon>
            )}
          </StyledListItemButton>
          <Menu
            anchorEl={mainMenuAnchorEl}
            open={Boolean(mainMenuAnchorEl)}
            onClose={handleMainMenuClose}
          >
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Add new sub title", clickedMain)
              }
            >
              Add new sub title
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("Add new item", clickedMain)}
            >
              Add new item
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("Edit title", clickedMain)}
            >
              Edit title
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("Delete title", clickedMain)}
            >
              Delete title
            </MenuItem>
          </Menu>

          <Collapse in={open[mainIndex]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {main?.items?.map((item, itemIndex) => (
                <StyledListItemButton
                  key={itemIndex}
                  padding="6px 6px 6px 50px"
                  bgColor={ReusableTypeReturn(
                    clickedItem.id === item.id,
                    PaleOlive2,
                    Cloud
                  )}
                  onClick={() => handleItemsData(item, main.id, null)}
                >
                  <StyledListItemText primary={item.name} size="12px" />
                  {isAdmin && (
                    <StyledIcon
                      onClick={(event) =>
                        handleListItemMenuClick(event, item.id)
                      }
                    >
                      <MenuIcon fontSize="small" />
                    </StyledIcon>
                  )}
                  <Menu
                    anchorEl={listItemMenuAnchorEls[item.id]}
                    open={Boolean(listItemMenuAnchorEls[item.id])}
                    onClose={() => handleListItemMenuClose(item.id)}
                  >
                    <MenuItem
                      onClick={() =>
                        handleSubMenuItemClick(item.id, "Edit item", item)
                      }
                    >
                      Edit item
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleSubMenuItemClick(item.id, "Delete item", item)
                      }
                    >
                      Delete item
                    </MenuItem>
                  </Menu>
                </StyledListItemButton>
              ))}
              {main?.subtitles?.map((sub, subIndex) => (
                <React.Fragment key={subIndex}>
                  <StyledListItemButton
                    onClick={() =>
                      handleClick(
                        `${mainIndex}-${subIndex}`,
                        main,
                        sub,
                        "subtitle"
                      )
                    }
                    bgColor={ReusableTypeReturn(
                      clickedItem.id === sub.id ||
                        clicledActive.idSub === sub.id,
                      PaleOlive1,
                      CuriousBlue
                    )}
                  >
                    <StyledListItemText
                      size="14px"
                      primary={sub.titleName}
                      padding="6px 6px 6px 20px"
                    />
                    {sub.items?.length > 0 &&
                      (open[`${mainIndex}-${subIndex}`] ? (
                        <ArrowDropUpIcon fontSize="medium" />
                      ) : (
                        <ArrowDropDownIcon fontSize="medium" />
                      ))}
                    {isAdmin && (
                      <StyledIcon
                        onClick={(event) =>
                          handleSubListMenuClick(
                            event,
                            `${mainIndex}-${subIndex}`,
                            sub
                          )
                        }
                      >
                        <MenuIcon fontSize="small" />
                      </StyledIcon>
                    )}
                  </StyledListItemButton>
                  <Menu
                    anchorEl={subListMenuAnchorEls[`${mainIndex}-${subIndex}`]}
                    open={Boolean(
                      subListMenuAnchorEls[`${mainIndex}-${subIndex}`]
                    )}
                    onClose={() =>
                      handleSubListMenuClose(`${mainIndex}-${subIndex}`)
                    }
                  >
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("Add new item", clickedSub)
                      }
                    >
                      Add new item
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("Edit sub title", clickedSub)
                      }
                    >
                      Edit sub title
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("Delete sub title", clickedSub)
                      }
                    >
                      Delete sub title
                    </MenuItem>
                  </Menu>
                  <Collapse
                    in={open[`${mainIndex}-${subIndex}`]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {sub?.items?.map((item, subItemIndex) => (
                        <StyledListItemButton
                          key={subItemIndex}
                          padding="6px 6px 6px 50px"
                          bgColor={ReusableTypeReturn(
                            clickedItem.id === item.id,
                            PaleOlive2,
                            Cloud
                          )}
                          onClick={() => handleItemsData(item, main.id, sub.id)}
                        >
                          <StyledListItemText primary={item.name} size="12px" />
                          {isAdmin && (
                            <StyledIcon
                              onClick={(event) =>
                                handleListItemMenuClick(event, item.id)
                              }
                            >
                              <MenuIcon fontSize="small" />
                            </StyledIcon>
                          )}
                          <Menu
                            anchorEl={listItemMenuAnchorEls[item.id]}
                            open={Boolean(listItemMenuAnchorEls[item.id])}
                            onClose={() => handleListItemMenuClose(item.id)}
                          >
                            <MenuItem
                              onClick={() =>
                                handleSubMenuItemClick(
                                  item.id,
                                  "Edit item",
                                  item
                                )
                              }
                            >
                              Edit item
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                handleSubMenuItemClick(
                                  item.id,
                                  "Delete item",
                                  item
                                )
                              }
                            >
                              Delete item
                            </MenuItem>
                          </Menu>
                        </StyledListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </StyledList>
  );
}
