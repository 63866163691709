import { useSelector } from 'react-redux';

const useUserRole = () => {
  const userResponse = useSelector((state) => state.UIUser.userResponse);
  const roles = ["Administrators", "editor", "admin", "Admin", "Editor", "administrator", "Administrator", "editors"];

  return userResponse?.groupMembers?.some(member => roles.includes(member.groupName)) || false;
};

export default useUserRole;
