import React, { memo } from 'react';
import Signature from '../signature/Signature'
import { Box, Button, Typography } from "@mui/material";
import Input from '../Inputs/Input';
import RadioButtonGroup from '../Inputs/RadioButtonGroup';
import { signatureOptions } from '../../constant/Constant';
import FileInput from '../Inputs/FileInput';
import {formatDate } from '../../utils/helperUtils';
import DatePickerComponent from '../Inputs/DatePickerComponent';
import styled from '@emotion/styled';
import { GuardsmanRed, Iron } from '../../styles/Colors';

const StyleButtonClose = styled(Button)`
  background-color: ${GuardsmanRed};
  margin-right: 16px;
  color: white;
  &:hover {
    background-color: ${GuardsmanRed};
    color: white;
  }
`;
const StyleButtonSubmit = styled(Button)`
  background-color: ${Iron};
  color: black;
  &:hover {
    background-color: ${Iron};
    color: black;
  }
`;

const SignaturePreview = memo(({
  handleFileChange,
  signatureType,
  handleSignatureChange,
  sigCanvas,
  clear,
  save,
  formData,
  handleChange,
  inputErrors,
  submittedDate,
  setSubmittedDate
}) => {

  const isUpload = signatureType === "upload"
  return (
    <>
      <RadioButtonGroup
        options={signatureOptions}
        value={signatureType}
        onChange={handleSignatureChange}
      />
      {isUpload ?
        <FileInput
          label="Upload E-Sign"
          onChange={handleFileChange}
          error={inputErrors.upload}
          name="file"
        />
        :
        <Box>
          <Signature
            sigCanvas={sigCanvas}
            error={inputErrors.sign}
          />
        </Box>
      }
      <Input
        label={"Name"}
        type="text"
        value={formData.name}
        onChange={handleChange("name")}
        error={inputErrors.name}
      />
      <DatePickerComponent
        selectedDate={submittedDate}
        setSelectedDate={setSubmittedDate}
        label={"Submit on"}
      />
      <Box sx={{ display: 'flex', justifyContent: "space-between", mt: 2 }}>
        <Typography variant="subtitle1" component="div">
          Submitted on:&nbsp;{formatDate(submittedDate)}
        </Typography>
        <Box>
          <StyleButtonClose  onClick={clear}>
            Reset
          </StyleButtonClose>
          <StyleButtonSubmit onClick={save} >
            Save
          </StyleButtonSubmit>
        </Box>
      </Box>
    </>
  )
})

export default SignaturePreview