import React, { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import styled from '@emotion/styled';
import { DarkJungleGreen, HawkesBlue } from '../../styles/Colors';

const AppBarStyled=styled(AppBar)`
background-color:${HawkesBlue};
color:${DarkJungleGreen};
position:relative;
`
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal({ children, open,heading,onClose}) {
  const handleClose = () => onClose();
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarStyled>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1}} variant="h6" component="div">
              {heading}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBarStyled>
        {children}
      </Dialog>
    </React.Fragment>
  );
}
