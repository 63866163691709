// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  box-shadow: 24px;
  padding: 20px;
  border-radius: 10px;
}
.sidebar_sec {
  height: calc(100vh - 142px);
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[".modalBox {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  background-color: white;\n  box-shadow: 24px;\n  padding: 20px;\n  border-radius: 10px;\n}\n.sidebar_sec {\n  height: calc(100vh - 142px);\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
