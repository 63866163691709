export const HawkesBlue = "#D9E4F5";
export const FreshGreen = "#79d04e";
export const DarkJungleGreen = "#1A2421";
export const Iron = "#D4D7D9";
export const PaleOlive = "#228B22";
export const PaleOlive1 = "#4CBB17";
export const PaleOlive2 = "#90EE90";
export const Eminence = "#6C3082";
export const FrenchBlue = "#0072BB";
export const ArtyClickAmber = "#FFBF00";
export const GuardsmanRed = "#BA0101";
export const Seashell = "#F1F1F1";
export const GreenHaze = "#00AF4E";
export const DustyOrange = "#EC7C30";
export const Cinderella = "#FBCFD0";
export const SaharaSand = "#F5EB92";
export const Yellow = "yellow";
export const VeniceBlue="#08457E";
export const CuriousBlue="#1DACD6";
export const Cloud="#C7C4BF";
export const ParisGreen="#00A550";