import React, { useEffect, useState } from 'react';
import FullScreenModal from '../FullScreenModal';
import { Box } from "@mui/material";
import ReusableList from '../../reusablelist/ReusableList';
import { useDispatch} from "react-redux";
import { typeCheck, typeCheckOptions } from '../../../utils/helperUtils';
import { fetchModalData, handleContainerClose } from '../../../utils/ViewSearchModalUtils';
import { UISearchActions } from '../../../store/seach-state-slice';
import { checklistActions } from '../../../store/checklist-slice';
import { UIStateActions } from '../../../store/ui-state-slice';
import BreadCrumComponent from '../../common/BreadCrum/BreadCrumComponent';

const ViewSearchModal = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (isOpen) {
      fetchModalData(data, setModalData);
    }
    if(!data){
        dispatch(UISearchActions.setChecklistID({ checklistID: "" }));
        dispatch(UISearchActions.setPolicyID({ policyID: "" }));
        dispatch(UISearchActions.setComplianceID({ complianceID: "" }));
        dispatch(checklistActions.setSelectedChecklistId(null));
        dispatch(checklistActions.setSelectedChecklistCategory(null));
        dispatch(UIStateActions.setRetiredPolicyProceduresColumnVisibility(false));
        dispatch(UIStateActions.setPoliciesColumnVisibility(false));
        dispatch(UIStateActions.setCompliancesColumnVisibility(false));
    }
  }, [isOpen, data]);

  const handleContainer = () => {
    handleContainerClose(data, modalData, dispatch, onClose);
  };

  const breadcrumbs = [
    { label: 'Home' },
    { label: data?.type },
    { label: data?.name },
  ];
  return (
    <FullScreenModal
      open={isOpen}
      onClose={onClose}
      heading="Searched"
    >
      <Box sx={{ margin: "40px" }}>
        <BreadCrumComponent breadcrumbs={breadcrumbs} />
        {modalData && (
          <ReusableList
            key={modalData.id}
            data={modalData}
            isButton={typeCheck(data?.type)}
            menuItems={typeCheckOptions(data?.type)}
            onClickContainer={handleContainer}
          />
        )}
      </Box>
    </FullScreenModal>
  );
};

export default ViewSearchModal;
