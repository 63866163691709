import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  Grid,
  MenuItem,
  Menu,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import FolderIcon from "@mui/icons-material/Folder";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import AddComplianceModal from "../../Modals/AddComplianceModal/AddComplianceModal";
import { deleteCompliance, getPolicyLinkedCompliance, moveToRetired } from "../../../store/checklist-slice";
import { ComplianceSubCrud, complianceOptions } from "../../../constant/Constant";
import AreYouSure from "../../Modals/Confirm/AreYouSure";
import { errorToast, successToast } from "../../Toast/Toaster";
import ReusableList from "../../reusablelist/ReusableList";
import AddFolder from "../../Modals/AddFolderModal/AddFolder";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropIcon from "../../DND/DropIcon";
import DraggableBlock from "../../DND/DraggableBlock";
import ViewComplainceModal from "../../Modals/viewComplainceModal/ViewComplainceModal";
import IconDropDown from "../../Inputs/IconDropDown";
import { UIStateActions } from "../../../store/ui-state-slice";
import { UISearchActions } from "../../../store/seach-state-slice";
import { GetPolicyLinkedFolders, PostComplianceLinkFolder, PutCompliance, deleteFolderId } from "../../../services/checklistService";
import ViewFolderCompliancesModal from "../../Modals/ViewFolderCompliancesModal/ViewFolderCompliancesModal";
import useUserRole from "../../../customHooks/useUserRole";
import { ArtyClickAmber, GuardsmanRed, Iron, PaleOlive2,ParisGreen, Yellow } from "../../../styles/Colors";
import styled from "@emotion/styled";
import { ReusableTBoolReturn, ReusableTypeReturn } from "../../../utils/helperUtils";
import ComplianceLinkedPolicy from "../../Modals/ComplianceLinkedPolicy/ComplianceLinkedPolicy";
import ImportCompliance from "../../Modals/ImportCompliance/ImportCompliance";
const StyledComplianceHead = styled(Paper)`
  background-color: ${ParisGreen};
  color: black;
  padding: 16px; 
  border:1px solid ${Iron};
  border-radius:0px;
  margin-bottom:10px;
`;

const StyledBox = styled(Box)`
  overflow-x: auto;
  height: 100%;
  max-height: 400px;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  scrollbar-width: thin;
`;

const StyledFolderIcon = styled(FolderIcon)`
 color: ${ArtyClickAmber};
 `;
const ComplianceColumn = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserRole();
  const [listData, setListData] = useState([])
  const [modal, setModal] = useState({ bool: false, type: "", data: {} })
  const [loading, setLoading] = useState(false)
  const [openCompliance, setOpenCompliance] = useState({ id: {}, bool: false })
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [folderList, setFolderList] = useState([])
  const [modalFolder, setModalFolder] = useState({ bool: false, data: {} })
  const [modalFolderEdit, setModalFolderEdit] = useState({ bool: false, data: {} })
  const [selectedTab, setSelectedTab] = useState("")
  const [contextMenu, setContextMenu] = useState(null);
  const [deleteFoler, setDeleteFolder] = useState({ bool: false, data: {} })
  const { SeletedPolicy } = useSelector((state) => state.checklist);
  const complianceID = useSelector((state) => state.UISearch.complianceID);
  const isSearch = Object.keys(complianceID.complianceID).length > 0;
  const complianceList = ReusableTypeReturn(isSearch, [complianceID.complianceID], listData);
  const SeletedPolicyId = SeletedPolicy?.payload?.id;
  let id = ReusableTypeReturn(isSearch, complianceID.complianceID.id, SeletedPolicyId)

  useEffect(() => {
    if (id) {
      fetchData(id);
      fetchFolders(id)
    }
  }, [id, selectedIdx, modal, openCompliance, modalFolderEdit, deleteFoler]);

  const fetchData = async (ID) => {
    let res = await dispatch(getPolicyLinkedCompliance(ID));
    if (res.payload) {
      const resData = res.payload.data
      const filteredData = resData.filter((list) => list.compliance.isRetired === 0)
      setListData(filteredData)
    }

  };

  const fetchFolders = async (IDD) => {
    let resFolder = await GetPolicyLinkedFolders(IDD);
    setFolderList(resFolder.data)
  }

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (modal.data.id) {
      await dispatch(deleteCompliance(modal.data.id));
      successToast(`${modal.data.name} deleted successfully`);
      setModal({ bool: false, type: "", data: {} });
      dispatch(UIStateActions.setCompliancesColumnVisibility(false));
      dispatch(UISearchActions.setComplianceID({ complianceID: {} }));
    }
    else {
      errorToast("Something went wrong...");
    }
    setLoading(false);
  };

  const handleFolderDelete=async(event)=>{
    event.preventDefault();
    setLoading(true);

    if (deleteFoler.data.id) {
      await deleteFolderId(deleteFoler.data.id);
      successToast(`Folder ${deleteFoler.data.name} deleted successfully`);
      setDeleteFolder({ bool: false, data: {} });
    }
    else {
      errorToast("Something went wrong...");
    }
    setLoading(false);
  }
  const handleMoveToRetired = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (modal.data.id) {
      await dispatch(moveToRetired(modal.data.id));
      successToast(`Moved ${modal.data.name} to retired successfully.`);
      setModal({ bool: false, type: "", data: {} });
    }
    else {
      errorToast("Something went wrong...");
    }
    setLoading(false);
  };

  const closeChecklistModal = () => setSelectedIdx(null);

  const handleModal = useCallback((type, data) => {
    setModal({ bool: true, type, data });
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setModal({ bool: false, type: "", data: {} });
  }, []);

  const handleCloseFolder = () => {
    setSelectedIdx(null)
  }

  const handleDrop = async (draggedItem, dropTargetId) => {
    if (draggedItem && dropTargetId) {
      const { complianceId: draggedComplianceId } = draggedItem
      const { id: DroppedFolderId } = dropTargetId
      const resFolderCompliance = await PostComplianceLinkFolder(DroppedFolderId, draggedComplianceId)
      fetchData(id);
      successToast(`${resFolderCompliance.compliance.name} linked successfully to ${resFolderCompliance.folder.name}`)
    }
  };

  const handleColorAPI = async (complianceIDDDD, ComplianceName) => {
    await PutCompliance(complianceIDDDD, { color: "white", name: ComplianceName });
  };
  const handleComplianceView = (event, policy) => {
    const viewData = ReusableTypeReturn(isSearch, policy, policy.compliance)
    event.stopPropagation();
    setOpenCompliance({ id: viewData, bool: true })
  }
  const handleComplianceClose = () => {
    setOpenCompliance({ id: {}, bool: false })
  }

  const closeLinkedModal = () => {
    setSelectedIdx(null)
  }

  const handleFolderClick = (e, fData) => {
    e.stopPropagation();
    setModalFolder({ bool: true, data: fData });
    handleCloseContextMenu()
  };

  const handleCloseFolderClick = useCallback(() => {
    setModalFolder({ bool: false, data: {} });
  }, []);

  const handleEditFolder = (e, folder) => {
    e.stopPropagation();
    setModalFolderEdit({ bool: true, data: folder });
    handleCloseContextMenu();
  };
  const handleDeleteFolder = (e, folder) => {
    e.stopPropagation();
    setDeleteFolder({ bool: true, data: folder })
    handleCloseContextMenu();
  };


  const handleEditFolderClose = () => {
    setModalFolderEdit({ bool: false, data: {} });
  };
  const handleDeleteFolderClose = () => {
    setDeleteFolder({ bool: false, data: {} });
  };
  const handleSelectedTab = (val) => {
    const { name, id: idddd, color } = val
    if (color !== "white") {
      handleColorAPI(idddd, name)
    }
    setSelectedTab(name)
  }

  const handleContextMenu = (event, folder) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          folder,
        }
        : null
    );
  };
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  return (
    <>
      {modalFolder.bool && (
        <ViewFolderCompliancesModal
          folderData={modalFolder}
          onClose={handleCloseFolderClick}
          folderName={modalFolder.data.name}
          updateListAPI={fetchData}
        />
      )}
      {isAdmin &&
        <ViewComplainceModal
          open={openCompliance?.bool}
          onClose={handleComplianceClose}
          policyData={openCompliance?.id}
        />}

      {ReusableTBoolReturn(modal.type, "Delete Compliance") &&
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModal}
          submitClicked={handleDelete}
          heading={`Are you sure you want to delete ${modal?.data?.name}?`}
          loading={loading}
        />
      }


      {deleteFoler.bool &&
        <AreYouSure
          open={deleteFoler.bool}
          onClose={handleDeleteFolderClose}
          submitClicked={handleFolderDelete}
          heading={`Are you sure you want to delete folder ${deleteFoler?.data?.name}?`}
          loading={loading}
        />
      }
      {ReusableTBoolReturn(modal.type, "Move To Retired") &&
        <AreYouSure
          open={modal.bool}
          onClose={closeConfirmationModal}
          submitClicked={handleMoveToRetired}
          heading={`Confirm moving ${modal?.data?.name} to retired?`}
          loading={loading}
          button2Text="Move to retired"
        />
      }
      {ReusableTBoolReturn(modal.type, "Edit Compliance") &&
        <AddComplianceModal
          open={modal.bool}
          onClose={closeConfirmationModal}
          policyId={SeletedPolicyId}
          data={modal?.data}
          type="Edit"
        />
      }
      {ReusableTBoolReturn(modal.type, "Share Compliance") &&
        <ComplianceLinkedPolicy
          open={modal.bool}
          onClose={closeConfirmationModal}
          complianceData={modal.data}
          policyId={SeletedPolicyId}
        />
      }
      <ConfirmationModal
        open={ReusableTBoolReturn(selectedIdx, 1)}
        onClose={closeLinkedModal}
        policyId={SeletedPolicyId}
        complianceList={complianceList}
      />
      <ImportCompliance
        open={ReusableTBoolReturn(selectedIdx, 2)}
        onClose={closeLinkedModal}
        policyId={SeletedPolicyId}
      />
      <AddFolder
        open={ReusableTBoolReturn(selectedIdx, 3)}
        onClose={handleCloseFolder}
        type="Add"
        policyId={SeletedPolicyId}
        fetchData={fetchData}
      />
      {modalFolderEdit.bool && (
        <AddFolder
          open={modalFolderEdit.bool}
          onClose={handleEditFolderClose}
          type="Edit"
          policyId={SeletedPolicyId}
          fetchData={fetchData}
          folderIddd={modalFolderEdit.data.id}
          data={modalFolderEdit.data}
        />
      )}
      <AddComplianceModal open={ReusableTBoolReturn(selectedIdx, 0)} onClose={closeChecklistModal} policyId={SeletedPolicyId} />

      <Box>
        {!isSearch && SeletedPolicy && <StyledComplianceHead>
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="subtitle2">
              {SeletedPolicy?.payload?.name}
            </Typography>
          </Stack>
        </StyledComplianceHead>}

        <DndProvider backend={HTML5Backend}>
          {!isSearch && <>
            <Paper
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 500,
                flexGrow: 1,
                background: "none",
                boxShadow: "none",
              }}
            >
              {isAdmin &&
                <Grid container spacing={2}>
                  <IconDropDown
                    options={complianceOptions}
                    selectedIdx={selectedIdx}
                    setSelectedIdx={setSelectedIdx}
                  />
                </Grid>
              }
            </Paper>

            <Box
              sx={{
                paddingInline: '13px',
                flexWrap: 'wrap',
                textAlign: 'start',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '10px',
                width: 'fit-content',
                marginBottom: '10px',
              }}
            >
              {folderList?.map((folder, index) => (
                <DropIcon onDrop={handleDrop} id={folder} key={index}>
                  <StyledFolderIcon
                    onContextMenu={(e) => handleContextMenu(e, folder)}
                    style={{ cursor: 'context-menu' }}
                    fontSize="large"
                    onClick={(e) => handleFolderClick(e, folder)}
                  />
                  <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                      contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                    }
                  >
                    <MenuItem onClick={(e) => handleFolderClick(e, contextMenu.folder)}>View</MenuItem>
                    {isAdmin && <MenuItem onClick={(e) => handleEditFolder(e, contextMenu.folder)}>Edit</MenuItem>}
                    {isAdmin && <MenuItem onClick={(e) => handleDeleteFolder(e, contextMenu.folder)}>Delete</MenuItem>}
                  </Menu>
                  <Box
                    onClick={(e) => handleFolderClick(e, folder)}
                    sx={{ textWrap: 'wrap', wordBreak: 'break-word', textAlign: 'center' }}
                  >
                    {folder.name}
                  </Box>
                </DropIcon>
              ))}
            </Box>
          </>}

          <StyledBox>
            {complianceList?.map((item, index) => {
              const complianceData = ReusableTypeReturn(isSearch, item, item.compliance);
              const isDot = !!complianceData.complianceDescription || !!complianceData.image;
              const isDotColor = complianceData.complianceDescription
                ? (ReusableTypeReturn(complianceData.image,Yellow,GuardsmanRed))
                : '';
              const boxColor = ReusableTypeReturn(complianceData.name === selectedTab, PaleOlive2, complianceData?.color);
              return (
                <DraggableBlock key={index} id={item} type="COMPLIANCE">
                  <ReusableList
                    key={index}
                    data={complianceData}
                    menuItems={ComplianceSubCrud}
                    onHamburgerClick={handleModal}
                    isAdmin={isAdmin}
                    onClickContainer={(e) => {
                      handleComplianceView(e, item);
                      handleSelectedTab(complianceData);
                    }}
                    isDot={isDot}
                    isDotColor={ReusableTypeReturn(isDot,isDotColor,undefined)}
                    boxColor={boxColor}
                    isLinked={complianceData.isLinked}
                  />
                </DraggableBlock>
              );
            })}
          </StyledBox>
        </DndProvider>
      </Box>
    </>

  );
};

export default ComplianceColumn;
