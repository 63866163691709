import { Fragment } from "react";
import Footer from "./Footer";
import Box from "@mui/material/Box";
import MainNavigation from "./MainNavigation";
import classes from "./Layout.module.css";

const Layout = (props) => {
  return (
    <Fragment>
      <Box sx={{ height: "100%" }}>
        <MainNavigation />
        <main className={classes.mainStyles}>{props.children}</main>
      </Box>
      <Footer />
    </Fragment>
  );
};

export default Layout;
