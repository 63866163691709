import React, { useDeferredValue, useEffect, useState } from 'react';
import FullScreenModal from '../FullScreenModal';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, CircularProgress } from '@mui/material';
import { AdvanceSearchOptions } from '../../../constant/Constant';
import { GetChecklistAll, GetCompliancelist, GetPolicylist } from '../../../services/checklistService';
import AdvanceSearchChecklist from '../../AdvanceSearchChecklist/AdvanceSearchChecklist';
import AdvanceSearchCompliance from '../../AdvanceSearchCompliance/AdvanceSearchCompliance';
import AdvanceSearchPolicy from '../../AdvanceSearchPolicy/AdvanceSearchPolicy';
import AdvanceSearchRetired from '../../AdvanceSearchRetired/AdvanceSearchRetired';
import Input from '../../Inputs/Input';
import { FreshGreen } from '../../../styles/Colors';

const ViewAdvanceSearch = ({ open, onClose }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [data, setData] = useState([]);
    const [search, seSearch] = useState("")
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const deferredValue = useDeferredValue(search)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                let response;
                switch (selectedOption) {
                    case "checklist":
                        response = await GetChecklistAll();
                        break;
                    case "policy":
                        response = await GetPolicylist(10000, 0);
                        break;
                    case "compliance":
                        response = await GetCompliancelist();
                        response.data = response.data?.filter(item => item.isRetired === 0);
                        break;
                    case "retired":
                        response = await GetCompliancelist();
                        response.data = response.data?.filter(item => item.isRetired === 1);
                        break;
                    default:
                        return;
                }
                setData(response?.data || []);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (selectedOption) {
            fetchData();
        }
    }, [selectedOption]);

    const updateData = async () => {
        setLoading(true);
        setError(null);
        try {
            let response;
            switch (selectedOption) {
                case "checklist":
                    response = await GetChecklistAll();
                    break;
                case "policy":
                    response = await GetPolicylist(10000, 0);
                    break;
                case "compliance":
                case "retired":
                    response = await GetCompliancelist();
                    if (selectedOption === "compliance") {
                        response.data = response.data?.filter(item => item.isRetired === 0);
                    } else if (selectedOption === "retired") {
                        response.data = response.data?.filter(item => item.isRetired === 1);
                    }
                    break;
                default:
                    return;
            }
            setData(response?.data || []);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    

    const renderContent = (closeMoodal) => {
        if (loading) return <CircularProgress />;
        if (error) return <Box>Error: {error}</Box>;
        switch (selectedOption) {
            case "checklist":
                return <AdvanceSearchChecklist data={data} updateData={updateData} searchValue={deferredValue} />;
            case "policy":
                return <AdvanceSearchPolicy data={data} updateData={updateData} close={closeMoodal} searchValue={deferredValue} />;
            case "compliance":
                return <AdvanceSearchCompliance data={data} updateData={updateData} searchValue={deferredValue} />;
            case "retired":
                return <AdvanceSearchRetired data={data} updateData={updateData} searchValue={deferredValue} />;
            default:
                return null;
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value)
        seSearch("")
    };
    const closeModal = () => {
        setSelectedOption('');
        setData([]);
        onClose();
        seSearch("")
        setLoading(false)
    };
    return (
        <FullScreenModal open={open} onClose={closeModal} heading="Advance Search">
            <Box sx={{ margin: "10px 40px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="search-options-label"
                            name="search-options-group"
                            value={selectedOption}
                            onChange={handleOptionChange}
                        >
                            {AdvanceSearchOptions.map((option) => (
                                <FormControlLabel
                                    key={option.name}
                                    value={option.name}
                                    control={<Radio sx={{'&, &.Mui-checked': {color: FreshGreen}}}/>}
                                    label={option.key}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {selectedOption &&
                        <Box sx={{ width: "50%" }}>
                            <Input
                                label="Search"
                                type="Search"
                                name="search"
                                onChange={(e) => seSearch(e.target.value)}
                                value={search}
                            />
                        </Box>
                    }

                </Box>

                <Box sx={{ marginTop: "20px" }}>
                    {renderContent(closeModal)}
                </Box>
            </Box>
        </FullScreenModal>
    );
};

export default ViewAdvanceSearch;
