import axios from "axios";

export const postAddSelf = async () => {
  const options = {
    method: "POST", 
    url: `/User/AddSelf`, 
    baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
  };
  const response = await axios.request(options);
  return response.data; 
};
