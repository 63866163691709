import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetIsAdministrator, GetResourcePermissionLevel } from "../services/permissionsService";

const initialState = {
  isAdmin: false,
  resourcePermissionLevels: {},
};

export const fetchIsAdmin = createAsyncThunk("permissions/fetchIsAdmin", async () => {
  try {
    const isAdminResponse = await GetIsAdministrator();
    return isAdminResponse.data;
  } catch (error) {
    console.error("Error fetching administrator status:", error);
    throw error;
  }
});

export const fetchResourcePermissionLevel = createAsyncThunk(
  "permissions/fetchResourcePermissionLevel",
  async (resourceId) => {
    try {
      const permissionResponse = await GetResourcePermissionLevel(resourceId);
      return { [resourceId]: permissionResponse.data };
    } catch (error) {
      console.error("Error fetching resource permission level:", error);
      throw error;
    }
  }
);

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsAdmin.fulfilled, (state, action) => {
        state.isAdmin = action.payload;
      })
      .addCase(fetchResourcePermissionLevel.fulfilled, (state, action) => {
        state.resourcePermissionLevels = { ...state.resourcePermissionLevels, ...action.payload };
      });
  },
});

export const permissionActions = permissionSlice.actions;

export default permissionSlice;
