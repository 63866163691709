import React,{memo} from 'react'
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { Iron,GuardsmanRed} from '../../styles/Colors';

const StyledTextField = styled(TextField)`
background:white;
  & label.Mui-focused {
    color: black;
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
  }
  & .MuiOutlinedInput-root {
    color: black;
    &:hover fieldset {
      border-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
    }
    &.Mui-focused fieldset {
      border-color: ${({ error }) => (error ? GuardsmanRed : Iron)};
    }
  }
`;

const Input = ({ type, label, value="", onChange,error,name="name"}) => {

    return (
        <>
            <StyledTextField
                type={type}
                id="outlined-basic"
                label={label}
                value={value}
                onChange={onChange}
                fullWidth
                sx={{ my: 2 }}
                autoComplete='off'
                error={!!error}
                helperText={error}
                name={name}
            />
        </>
    )
}

export default memo(Input);