import ChecklistCategoriesDropdown from "../../Checklist/ChecklistCategoriesDropdown/ChecklistCategoriesDropdown";
import ChecklistCategory from "../../Checklist/ChecklistCategory/ChecklistCategory";
import { useSelector } from "react-redux";

const ChecklistCategoryColumn = () => {
  const selectedChecklistCategoryId = useSelector(
    (state) => state.checklist.selectedChecklistCategoryId
  );

  return (
    <>
      <ChecklistCategoriesDropdown />
      {selectedChecklistCategoryId && <ChecklistCategory />}
    </>
  );
};

export default ChecklistCategoryColumn;
