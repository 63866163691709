import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addChecklist,
  editChecklist,
  fetchChecklists,
} from "../../../store/checklist-slice";
import ModalWrapper from "../ModalWrapper";
import Input from "../../Inputs/Input";
import { errorToast, successToast } from "../../Toast/Toaster";

const AddChecklistModal = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    onClose,
    isEdit,
    initialData,
    checklistCategoryId,
    updateData,
    label
  } = props;
  const [loading, setLoading] = useState(false);
  const [checklistData, setChecklistData] = useState({ name: "" });
  const [inputErrors, setInputErrors] = useState({ name: "" });
  useEffect(() => {
    if (isEdit && initialData) {
      setChecklistData({ name: initialData.name ?? "" });
    } else {
      setChecklistData({ name: "" });
    }
  }, [isEdit, initialData]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const checklistDataAdd = {
      name: checklistData.name,
      checklistCategoryId,
      title:true
    };
    const checklistData1 = {
      name: checklistData.name,
      checklistCategoryId: initialData?.checklistCategoryId,
    };
    try {
      const action = isEdit ? editChecklist : addChecklist;
      const payload = isEdit
        ? { id: initialData?.id, data: checklistData1 }
        : checklistDataAdd;
      const res = await dispatch(action(payload));
      const successMessage = isEdit
        ? "Title edited successfully!"
        : "New title added successfully!";
      const errorMessage = isEdit
        ? "failed to edit title"
        : "failed to add new title";
      if (
        res.type === "editChecklist/fulfilled" ||
        res.type === "addChecklist/fulfilled"
      ) {
        successToast(successMessage);
        setChecklistData({ name: "" });
        await dispatch(fetchChecklists(res?.payload?.checklistCategoryId));
        onClose();
        if (updateData) {
          await updateData();
        }
      } else {
        errorToast(errorMessage);
      }
    } catch (error) {
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { name: "" };
    if (!checklistData.name?.trim()) {
      errors.name = "This field is required";
      isValid = false;
    } else if (checklistData.name?.length > 260) {
      errors.name = `Checklist Name must be 260 characters or fewer. Currently ${checklistData.name?.length} characters.`;
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };

  const closeModal = () => {
    onClose();
    setChecklistData({ name: "" });
    setInputErrors({ name: "" });
  };
  return (
    <>
      <ModalWrapper
        open={open}
        onClose={closeModal}
        loading={loading}
        heading={label}
        handleSave={handleSubmit}
      >
        <Input
          label={label}
          type="text"
          value={checklistData.name}
          onChange={(e) => {
            setChecklistData({ ...checklistData, name: e.target.value });
            setInputErrors({ name: "" });
          }}
          error={inputErrors.name}
        />
      </ModalWrapper>
    </>
  );
};

export default AddChecklistModal;
