import axios from "axios";

export const getUsersList = async () => {
    const options = {
      method: "GET",
      url: "User/UsersWithGroupMembers",
      baseURL: process.env.REACT_APP_LIS_API_BASE_URL,
    };
    return axios.request(options);
};
