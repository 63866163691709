import React, { memo, useRef, useState, useCallback, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { GuardsmanRed, Iron } from '../../../styles/Colors';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { ReusableTypeReturn, formatDate } from '../../../utils/helperUtils';
import { PutCompliance, PutPolicy } from '../../../services/checklistService';
import { errorToast, successToast } from '../../Toast/Toaster';
import FullScreenModal from '../FullScreenModal';
import { signatureOptions } from '../../../constant/Constant';
import Input from '../../Inputs/Input';
import RadioButtonGroup from '../../Inputs/RadioButtonGroup';
import FileInput from '../../Inputs/FileInput';
import Signature from '../../signature/Signature';
import DatePickerComponent from '../../Inputs/DatePickerComponent';
import TinyMCEEditor from '../../Inputs/TinyMCEEditor';

const BaseButton = styled(Button)`
  color: white;
  margin-right: 16px;
  &:hover {
    color: white;
  }
`;
const StyleButtonClose = styled(BaseButton)`
  background-color: ${GuardsmanRed};
  &:hover {
    background-color: ${GuardsmanRed};
  }
`;
const StyleButtonSubmit = styled(BaseButton)`
  background-color: ${Iron};
  color: black;
  &:hover {
    background-color: ${Iron};
    color: black;
  }
`;
const Flex = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
});

const StyledImage = styled('img')({
    objectFit: "contain",
    backgroundColor: "white",
    width:"520px",
    height:"200px"
});
const ViewSignatureEditModal = ({ open, onClose, policy, type,mainModalClose}) => {
    const sigCanvas = useRef({});
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        submitByName: "",
        docContent: '',
        signatureType: 'sign',
        initialUpdatedDate: undefined,
        initialSubmittedDate: undefined,
        selectedFile: null,
    });
    const [inputErrors, setInputErrors] = useState({
        submitByName: "", docContent: "", sign: "", upload: ""
    });
    const { name, image, id, policyDescription, complianceDescription, submittedDate, updatedDate, submitByName } = policy;
    
    useEffect(() => {
        setFormData({
            submitByName,
            docContent: ReusableTypeReturn(type === "policy",policyDescription,complianceDescription),
            initialUpdatedDate: ReusableTypeReturn(updatedDate,dayjs(formatDate(updatedDate), 'MM/DD/YYYY'),undefined),
            initialSubmittedDate:ReusableTypeReturn(submittedDate,dayjs(formatDate(submittedDate), 'MM/DD/YYYY'),undefined),
            signatureType: 'sign',
            selectedFile: null,
        });
    }, [policy, type, submittedDate, updatedDate]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const canvasRef = sigCanvas.current || {};
        const isEmpty =ReusableTypeReturn(canvasRef.isEmpty,canvasRef.isEmpty(),true);
        if (!validateForm()) return;
        setLoading(true);
        const imageData =ReusableTypeReturn(formData.signatureType === "upload",formData.selectedFile,sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        const payload = {
            ...(ReusableTypeReturn(type === "policy",{ policyDescription: formData.docContent },{ complianceDescription: formData.docContent })),
            image:ReusableTypeReturn(isEmpty,image,imageData),
            updateOn: formData.initialUpdatedDate?.format("MM/DD/YYYY"),
            submitOn: formData.initialSubmittedDate?.format("MM/DD/YYYY"),
            submitByName: formData.submitByName,
            name:name
        };
        try {
            type === "policy"? await PutPolicy(id, payload):await PutCompliance(id, payload);
            successToast("Edited successfully...");
            handleReset();
        } catch (error) {
            errorToast(error.message);
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!formData.submitByName.trim()) {
            errors.submitByName = "This field is required";
            isValid = false;
        }
        if (!formData.docContent.trim()) {
            errors.docContent = "This field is required";
            isValid = false;
        }
        setInputErrors(errors);
        return isValid;
    };

    const handleSignatureChange = useCallback((value) => {
        setFormData((prevData) => ({
            ...prevData,
            signatureType: value,
        }));
    }, []);

    const handleChange = useCallback((fieldName) => (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "",
        }));
    }, []);

    const handleFileChange = useCallback((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFormData((prevData) => ({
                ...prevData,
                selectedFile: reader.result,
            }));
            setInputErrors((prevErrors) => ({
                ...prevErrors,
                upload: "",
            }));
        };
    }, []);

    const handleEditorChange = useCallback((newContent) => {
        setFormData((prevData) => ({
            ...prevData,
            docContent: newContent,
        }));
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            docContent: "",
        }));
    }, []);

    const handleReset = () => {
        mainModalClose()
        onClose()
        setFormData({
            submitByName,
            docContent: type === "policy" ? policyDescription : complianceDescription,
            initialUpdatedDate:ReusableTypeReturn(updatedDate,dayjs(formatDate(updatedDate), 'MM/DD/YYYY'),undefined),
            initialSubmittedDate: ReusableTypeReturn(submittedDate,dayjs(formatDate(submittedDate), 'MM/DD/YYYY'), undefined),
            signatureType: 'sign',
            selectedFile: null,
        });
        setLoading(false)
        setInputErrors({
            submitByName: "", docContent: "", sign: "", upload: ""
        })
    }
    return (
        <>
            <FullScreenModal open={open} onClose={handleReset} heading="Edit">
                <Box sx={{ margin: "10px 40px" }}>
                    <Flex>
                        <Input
                            label="Name"
                            type="text"
                            value={formData.submitByName}
                            onChange={handleChange("submitByName")}
                            error={inputErrors.submitByName}
                        />
                        <DatePickerComponent
                            selectedDate={formData.initialUpdatedDate}
                            setSelectedDate={(date) => setFormData((prev) => ({ ...prev, initialUpdatedDate: date }))}
                            label="Update on"
                        />
                        <DatePickerComponent
                            selectedDate={formData.initialSubmittedDate}
                            setSelectedDate={(date) => setFormData((prev) => ({ ...prev, initialSubmittedDate: date }))}
                            label="Submit on"
                        />
                    </Flex>
                    <TinyMCEEditor
                        DocContent={formData.docContent}
                        onChange={handleEditorChange}
                        error={inputErrors.docContent}
                    />
                    <RadioButtonGroup
                        options={signatureOptions}
                        value={formData.signatureType}
                        onChange={handleSignatureChange}
                    />
                    <Flex>
                        <div>
                            {formData.signatureType === "upload" ? (
                                <FileInput
                                    label="Upload E-Sign"
                                    onChange={handleFileChange}
                                    error={inputErrors.upload}
                                    name="file"
                                />
                            ) : (
                                <Box>
                                    <Signature sigCanvas={sigCanvas} error={inputErrors.sign} />
                                </Box>
                            )}
                        </div>
                        <div>
                            <a href={image} download={name} title="click to download">
                                <StyledImage src={image} alt={name} />
                            </a>
                        </div>

                    </Flex>

                    <Box sx={{ display: 'flex', justifyContent: "flex-end", my: 8 }}>
                        <Box>
                            <StyleButtonClose onClick={handleReset}>
                                Cancel
                            </StyleButtonClose>
                            <StyleButtonSubmit disabled={loading} onClick={handleSubmit}>
                                {ReusableTypeReturn(loading,"Loading...","Submit")}
                            </StyleButtonSubmit>
                        </Box>
                    </Box>
                </Box>
            </FullScreenModal>
        </>
    )
}

export default memo(ViewSignatureEditModal);